import { useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DataGrid,
  EmptyState,
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StatusTag } from "@Components/StatusTag";
import { routes } from "src/routes";

import { EmployeeGroups } from "./components/EmployeeGroups";
import { EmployeeOptions } from "./components/EmployeeOptions";
import {
  DataGridWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";
import { ITableGrid } from "./types";

const pageSizeOptions = [
  {
    label: "5 items",
    value: 5,
  },
  {
    label: "10 items",
    value: 10,
  },
  {
    label: "25 items",
    value: 25,
  },
];

const emptyStateButtonTexts = (groupType: string) =>
  groupType === "STATIC" ? "Adicionar integrantes" : "Definir regras";

const emptyStateButtonDescriptions = (groupType: string) =>
  groupType === "STATIC"
    ? "Você ainda não adicionou ninguém a esse grupo"
    : "Não há colabores aplicáveis às regras do grupo";

export const TableGrid = ({
  data,
  pageSize,
  groupId,
  groupName,
  groupType,
}: ITableGrid) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [searchParams] = useSearchParams();

  const columns = useMemo(() => {
    const values = [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
                source={data?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{data.value}</Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: any }) => <StatusTag status={value} />,
      },
      {
        Header: "CPF",
        accessor: "documentNumber",
      },
      {
        Header: "Telefone",
        accessor: "phoneNumber",
      },
      {
        Header: "Grupo",
        accessor: "groups",
        Cell: ({ value }: { value: any }) =>
          value && <EmployeeGroups groups={value} />,
        disableSortBy: true,
      },
    ];

    if (groupType !== "DYNAMIC") {
      values.push({
        Header: "Ações",
        Cell: (data: any) => (
          <EmployeeOptions
            employeeId={data?.cell?.row?.original?.id}
            groups={data?.cell?.row?.original?.groups}
            groupId={groupId}
            groupName={groupName}
          />
        ),
        sticky: "right",
        disableSortBy: true,
      } as any);
    }
    return values;
  }, [data]);

  return (
    <DataGridWrapper ref={tableRef}>
      {data?.length ? (
        <DataGrid
          hasPagination
          columns={columns}
          data={(data as any) || []}
          initialState={{ pageSize: Number(pageSize) }}
          pageSizeOptions={pageSizeOptions}
        />
      ) : (
        <EmptyState
          buttonText={emptyStateButtonTexts(groupType)}
          description={emptyStateButtonDescriptions(groupType)}
          buttonProps={{
            size: "medium",
            onClick: () => {
              searchParams.set("groupName", groupName);
              navigate({
                pathname:
                  groupType === "DYNAMIC"
                    ? routes.pageDynamicGroup(groupId)
                    : routes.pageManageGroupMembers(groupId),
                search: searchParams.toString(),
              });
            },
          }}
        />
      )}
    </DataGridWrapper>
  );
};

import { trpc } from "@/api/client";
import { FieldComponentTypes } from "@/common/field";
import { dispatchToast } from "@/utils";

export type CustomFieldProps = {
  categoryId?: string;
  companyId?: string;
  employeeId?: string;
  description?: string;
  enumOptions?: (string | null)[];
  name?: string;
  component?: Omit<FieldComponentTypes, "link" | "timePicker">;
};

export const useCreateCustomField = (opts?: {
  onError?: (error: any) => void;
  onSuccess?: () => void;
}) => {
  const utils = trpc.useContext();
  const { isLoading, mutate, data, mutateAsync } =
    trpc.createCustomField.useMutation({
      onError: opts?.onError,
      onSuccess: () => {
        utils.listCustomFields.invalidate();
        opts?.onSuccess?.();
      },
    });

  return {
    createCustomField: mutate,
    isLoading,
    createdCustomField: data,
    mutateAsync,
  };
};

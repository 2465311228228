import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useDeleteCustomField = (opts?: {
  onError?: () => void;
  onSuccess?: () => void;
}) => {
  const utils = trpc.useContext();
  const { isLoading: deleteIsLoading, mutate: deleteCustomField } =
    trpc.deleteCustomField.useMutation({
      onError: () => {
        dispatchToast({
          content: "Por favor, tente novamente mais tarde.",
          title: "Erro ao deletar campo customizado.",
          type: "error",
        });
        opts?.onError?.();
      },
      onSuccess: () => {
        opts?.onSuccess?.();
        utils.listCustomFields.invalidate();
      },
    });

  const { mutate: deleteManyCustomFields, isLoading: deleteManyIsLoading } =
    trpc.deleteManyCustomFields.useMutation({
      onError: () => {
        dispatchToast({
          content: "Por favor, tente novamente mais tarde.",
          title: "Erro ao excluir campo(s) customizado(s).",
          type: "error",
        });
        opts?.onError?.();
      },
      onSuccess: () => {
        dispatchToast({
          content: "",
          title: "Campos customizados excluídos com sucesso!",
          type: "success",
        });
        utils.listCustomFields.invalidate();
        opts?.onSuccess?.();
      },
    });

  return {
    deleteCustomField,
    deleteIsLoading,
    deleteManyCustomFields,
    deleteManyIsLoading,
  };
};

import { useEffect, useState } from "react";
import {
  Button,
  Icons,
  LinkButton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { Option } from "@/common/field";
import { CustomModal } from "@/components/CustomModal";
import { useDeleteCategory } from "@/hooks";

import {
  BorderlessButton,
  ContentContainer,
  FooterContainer,
  StyledDescription,
  StyledTitle,
  TextInputContainer,
} from "./styled";

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  categoryOptions: { label: string; value: any }[];
  saveCategoriesChanges: (categories: Option[]) => void;
  saveButtonLoading?: boolean;
};

export const ManageCategoryModal = ({
  open,
  onClose,
  categoryOptions,
  saveCategoriesChanges,
  saveButtonLoading,
}: ModalProps) => {
  const { selectedCompany } = useSelectedCompany();
  const [categories, setCategories] = useState<ModalProps["categoryOptions"]>(
    [],
  );
  const { deleteCategory, isLoading: deletionIsLoading } = useDeleteCategory();

  const handleInputChange = (value: string, index: number) => {
    const categoriesCopy = [...(categories || [])];
    categoriesCopy[index] = { ...categoriesCopy[index], label: value };
    setCategories(categoriesCopy);
  };

  const addNewCategory = () => {
    const categoriesCopy = [...(categories || [])];
    categoriesCopy.push({ label: "", value: null });
    setCategories(categoriesCopy);
  };

  const handleSaveButtonClick = () => {
    saveCategoriesChanges(categories);
  };

  useEffect(() => {
    setCategories(categoryOptions);
  }, [categoryOptions]);

  return (
    <CustomModal onClose={onClose} isOpen={open} size="xs">
      <CustomModal.Header>
        <StyledTitle variant="headline8">Gerenciar categorias</StyledTitle>
        <StyledDescription variant="body4">
          As categorias são uma forma de identificar e organizar campos de mesmo
          tipo, para facilitar sua gestão.
        </StyledDescription>
      </CustomModal.Header>
      <CustomModal.Content>
        <ContentContainer>
          {categories?.map((c, index) => (
            <TextInputContainer>
              <TextField
                label="Nome da categoria"
                value={c.label}
                style={{ width: "90%" }}
                onChange={(e) => handleInputChange(e.target.value, index)}
              />
              <BorderlessButton
                variant="secondary"
                variantType="neutral"
                onClick={() =>
                  deleteCategory({
                    companyId: selectedCompany.id,
                    categoryId: c.value,
                  })
                }
                loading={deletionIsLoading}
              >
                <Icons name="IconTrash" />
              </BorderlessButton>
            </TextInputContainer>
          ))}
          <Button
            variant="primary"
            size="small"
            style={{ marginBottom: "16px" }}
            onClick={addNewCategory}
          >
            <Icons name="IconPlus" />
            Adicionar categoria
          </Button>
        </ContentContainer>
      </CustomModal.Content>
      <CustomModal.Footer>
        <FooterContainer>
          <LinkButton variant="default" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="medium"
            onClick={handleSaveButtonClick}
            loading={deletionIsLoading || saveButtonLoading}
          >
            Salvar alterações
            <Icons name="IconCheck" />
          </Button>
        </FooterContainer>
      </CustomModal.Footer>
    </CustomModal>
  );
};

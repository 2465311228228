import { useEffect } from "react";
import { FieldComponent } from "@Components/FieldComponent";
import { FieldDisplay } from "@Components/FieldDisplay";

import { getCepFromUtility } from "@/shared/hooks/cep/get-cep-from-utility";
import { useGetCep } from "@/shared/hooks/cep/use-get-cep";

import { model } from "./model";
import { Container } from "./styled";

interface AddressFieldsInterface {
  address: AddressType;
  editing: boolean;
  loading: boolean;
  setAddressValue: (address: AddressType) => void;
  addressValue: AddressType;
  errors?: { [key: string]: boolean };
  helperTexts?: { [key: string]: string };
}

export const AddressFields = ({
  address,
  editing,
  loading,
  setAddressValue,
  addressValue,
  errors,
  helperTexts,
}: AddressFieldsInterface) => {
  const { getCep } = useGetCep();

  useEffect(() => {
    async function fetchCep(zipCode: string) {
      let zipCodeData = await getCep(zipCode);
      if (!zipCodeData?.state) {
        zipCodeData = await getCepFromUtility(zipCode);
      }

      if (zipCodeData) {
        setAddressValue({
          zipCode,
          number: addressValue.number?.toString() || "",
          city: zipCodeData.city,
          state: zipCodeData.state,
          street: zipCodeData.street,
          complement: zipCodeData.complement || "",
          district: zipCodeData.district,
        });
      }
    }

    if (addressValue?.zipCode?.length === 9) {
      const parsedZipCode = addressValue.zipCode.replace(/\D+/g, "");
      fetchCep(parsedZipCode);
    }
  }, [addressValue?.zipCode]);

  return (
    <Container>
      {!editing && (
        <>
          {model(address).map((field) => {
            return (
              <FieldDisplay
                key={field?.key}
                name={field?.name}
                value={field?.value || "-"}
                locked={undefined}
                required={field?.required}
                statusTag={undefined}
                tag={undefined}
                tagVariant={undefined}
                link={undefined}
                component={field?.component}
                options={field?.options}
                loading={loading}
              />
            );
          })}
        </>
      )}
      {editing &&
        model(addressValue).map((field) => {
          const fieldKey = field?.key as keyof AddressType;
          return (
            <FieldComponent
              {...field}
              key={field?.key}
              value={addressValue?.[fieldKey] || ""}
              inputProps={{ ...field?.inputProps }}
              onChange={(e) => {
                setAddressValue({
                  ...addressValue,
                  [field?.key]: e,
                });
              }}
              autoComplete="nope"
              error={errors?.[field?.key]}
              helperText={errors?.[field?.key] ? field?.helperText : undefined}
              {...((fieldKey === "city" || fieldKey === "state") && {
                autoComplete: "nope",
                disabled: true,
              })}
            />
          );
        })}
    </Container>
  );
};

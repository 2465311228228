import { trpc } from "@/api/client";
import { useSession } from "@/common/user";
import { dispatchToast } from "@/utils";

export const useCostCenterById = (
  costCenterId?: string,
  companyId?: string,
) => {
  const { companyId: companyIdSession } = useSession();
  const { data, isLoading, refetch } = trpc.getCostCenterById.useQuery(
    {
      companyId: companyId ?? companyIdSession,
      costCenterId: costCenterId ?? "",
    },
    {
      enabled: Boolean(costCenterId),
      onError: (error) => {
        dispatchToast({
          content: error.message,
          type: "error",
        });
      },
    },
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch,
  };
};

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Icons,
  TransferList,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { ListType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types";
import { Divider } from "@mui/material";
import { debounce } from "lodash-es";

import { FilterStatusEnum } from "@/common/status.enum";
import { Box, FormTemplate, TransferListEmptyState } from "@/components";
import { Footer, Header, HeaderBreadcrumbs } from "@/features/roles/components";
import {
  useDeleteRole,
  useGetRoleWithMembers,
  useLazyGetEmployessForRoles,
  useUpdateRoleEmployees,
} from "@/features/roles/hooks";
import { DataTypeEmployee, RoleFormStep } from "@/features/roles/types";
import { routes } from "@/routes";
import { dispatchToast, usePageTracking } from "@/utils";

import { ROLES_EMPLOYEES_PAGE } from "../../types/events";

import {
  EmailField,
  EmployeeName,
  TransferListCard,
  TransferListWrapper,
} from "./styles";

const PAGE_SIZE_DEFAULT_STEP = 20;

export const RoleEmployeesPage = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [searchParams] = useSearchParams();

  const from = searchParams.get("from");
  const isEdit = from !== "/roles/create";
  usePageTracking(ROLES_EMPLOYEES_PAGE);
  const pageTitle = `${isEdit ? "Editar" : "Criar"} cargo`;
  const breadcrumbs: HeaderBreadcrumbs[] = useMemo(
    () => [
      {
        label: "Gerenciar empresa",
        route: routes.pageInitial,
        options: { state: { tab: 1 } },
      },
      {
        label: "...",
        route: routes.roles,
      },
      { label: pageTitle },
    ],
    [pageTitle],
  );

  const [activeStep, setActiveStep] = useState(RoleFormStep.FIRST);
  const [transferList, setTransferList] = useState<{
    employeesLeft: DataTypeEmployee[];
    membersRight: DataTypeEmployee[];
  }>({
    employeesLeft: [],
    membersRight: [],
  });

  const [searchLeftState, setSearchLeftState] = useState("");
  const [searchRightState, setSearchRightState] = useState("");
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT_STEP,
  });

  const {
    getEmployeesForRoles,
    loading: getEmployeesLeftLoading,
    employees: roleEmployees,
    totalCount: totalEmployees,
  } = useLazyGetEmployessForRoles();
  const {
    getMembers,
    members: roleMembers,
    loading: getMembersRightLoading,
  } = useGetRoleWithMembers();
  const { updateRoleEmployees, loading: updateRoleEmployeeLoading } =
    useUpdateRoleEmployees();
  const { deleteRole } = useDeleteRole(!isEdit);

  useEffect(() => {
    getEmployeesForRoles({
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      status: FilterStatusEnum.REMOVE_INACTIVE,
      search: searchLeftState || undefined,
    });
  }, []);

  useEffect(() => {
    if (!!roleEmployees) {
      setTransferList((prev) => ({ ...prev, employeesLeft: roleEmployees }));
    }
  }, [roleEmployees]);

  useEffect(() => {
    if (roleId) {
      getMembers(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (!!roleMembers) {
      setTransferList((prev) => ({ ...prev, membersRight: roleMembers }));
    }
  }, [roleMembers]);

  const navigateToRolesPage = () => navigate(routes.roles);
  const handleSubmit = () => {
    updateRoleEmployees({
      roleId,
      employeeIds: transferList.membersRight.map((m) => m._id),
    });
  };
  const handleCancel = () => {
    deleteRole({ roleId } as any);
    navigateToRolesPage();
  };
  const handleSkip = () => {
    dispatchToast({
      type: "success",
      content: "Cargo criado com sucesso!",
    });
    navigateToRolesPage();
  };
  const handleBack = () => {
    if (from === "/roles") {
      navigateToRolesPage();
    } else {
      navigate(routes.roleForm(roleId));
    }
  };

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: ({ row }: any) => (
        <Box display="flex">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <EmployeeName variant="body3">{row.original.name}</EmployeeName>
            {row.original.email && (
              <EmailField variant="body3">{row.original.email}</EmailField>
            )}
          </Box>
        </Box>
      ),
    },
  ];

  const onScrollReachEnd = (reachedEnd: boolean) => {
    const scrollReachedEnd =
      employeesLeft?.length + transferList.membersRight.length <
        totalEmployees && reachedEnd;

    const lastCellIsValidEmployee =
      typeof employeesLeft?.[employeesLeft?.length - 1]?.name === "string";

    if (scrollReachedEnd && lastCellIsValidEmployee) {
      setPaginationState((prev) => ({
        ...prev,
        pageNumber: paginationState.pageNumber,
        pageSize: paginationState.pageSize + PAGE_SIZE_DEFAULT_STEP,
      }));

      getEmployeesForRoles({
        page: paginationState.pageNumber,
        limit: paginationState.pageSize,
        status: FilterStatusEnum.REMOVE_INACTIVE,
        search: searchLeftState || undefined,
      });
    }
  };

  const handleCheck = ({
    data,
    allChecked,
    side,
  }: {
    data: DataTypeEmployee[];
    allChecked: boolean;
    side: "left" | "right";
  }) => {
    setTransferList((prev) => {
      if (side === "left") {
        return {
          ...prev,
          employeesLeft: data.map((item) => ({
            ...item,
            checked: allChecked !== undefined ? allChecked : item?.checked,
          })),
        };
      }
      return {
        ...prev,
        membersRight: data.map((item, index) => ({
          ...prev.membersRight[index],
          checked: allChecked !== undefined ? allChecked : item?.checked,
        })),
      };
    });
  };

  const handleLeftSearch = (value: string) => {
    setSearchLeftState(value);
    getEmployeesForRoles({
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      status: FilterStatusEnum.REMOVE_INACTIVE,
      search: value || undefined,
    });
  };

  const debouncedOnLeftSearch = useMemo(() => {
    return debounce(handleLeftSearch, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedOnLeftSearch.cancel();
    };
  }, [debouncedOnLeftSearch]);

  const employeesLeft = transferList.employeesLeft.filter(
    (left) =>
      !transferList.membersRight.some((right) => right._id === left._id),
  );

  const leftList: ListType = {
    data: employeesLeft,
    total: totalEmployees,
    title: `Todas as pessoas (${totalEmployees})`,
    loading: getEmployeesLeftLoading,
    onScroll: (_, reachedEnd) => {
      onScrollReachEnd(reachedEnd);
    },
    onCheck: ({ allChecked, data }) => {
      handleCheck({
        side: "left",
        allChecked,
        data: data as DataTypeEmployee[],
      } as any);
    },
    onSearch: (leftSearch: string) => {
      if (leftSearch == searchLeftState) return;
      debouncedOnLeftSearch(leftSearch);
    },
    emptyStageMessage: (
      <TransferListEmptyState
        empty={transferList.employeesLeft?.length === 0}
        loading={
          !transferList.employeesLeft?.length && !getEmployeesLeftLoading
        }
      />
    ),
  };

  const filteredRightEmployees = !!searchRightState.length
    ? transferList.membersRight.filter((member) =>
        member.name.toLowerCase().includes(searchRightState),
      )
    : transferList.membersRight;

  const debouncedOnRightSearch = useMemo(() => {
    return debounce((value: string) => {
      setSearchRightState(value.toLowerCase());
    }, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedOnRightSearch.cancel();
    };
  }, [debouncedOnRightSearch]);

  const rightList: ListType = {
    data: filteredRightEmployees,
    total: transferList.membersRight.length,
    title: `Selecionados para o cargo (${
      transferList.membersRight.length || 0
    })`,
    loading: getMembersRightLoading,
    onCheck: ({ allChecked, data }) => {
      handleCheck({
        side: "right",
        allChecked,
        data: data as DataTypeEmployee[],
      } as any);
    },
    onSearch: (rightSearch) => {
      debouncedOnRightSearch(rightSearch);
    },
    emptyStageMessage: (
      <TransferListEmptyState
        empty={transferList.membersRight?.length === 0}
        loading={!transferList.membersRight?.length && !getMembersRightLoading}
      />
    ),
  };

  return (
    <FormTemplate
      title={pageTitle}
      subtitle="Atribuir Integrantes"
      subcontent="Escolha quais integrantes você gostaria de atribuir à este cargo."
      header={
        <Header
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          breadcrumbs={breadcrumbs}
        />
      }
      footer={
        <Footer
          loading={!isEdit && updateRoleEmployeeLoading}
          onBack={handleBack}
          onSubmit={handleSubmit}
          onSkip={handleSkip}
          showSkipButton={!isEdit}
          modalSkip={
            !isEdit && transferList.membersRight.length === 0
              ? {
                  title:
                    "Você não adicionou niguém a este cargo. Deseja sair mesmo assim?",
                  description:
                    "Você poderá adicionar pessoas a este cargo em outro momento.",
                  submitButtonContent: <SubmitButton isEditMode={isEdit} />,
                }
              : undefined
          }
          onCancel={handleCancel}
          submitButtonContent={<SubmitButton isEditMode={isEdit} />}
        />
      }
    >
      <TransferListCard>
        <Box>
          <Typography color="neutral.30" variant="headline8">
            Selecione os integrantes
          </Typography>
          <Typography color="neutral.50" variant="body4">
            As pessoas selecionadas irão aparecer como integrantes do cargo.
          </Typography>
        </Box>
        <Divider />
        <TransferListWrapper>
          <TransferList
            columns={columns}
            onTransfer={({ leftList, rightList }) => {
              setTransferList({
                employeesLeft: leftList.data as DataTypeEmployee[],
                membersRight: rightList.data as DataTypeEmployee[],
              });
            }}
            leftList={leftList}
            rightList={rightList}
          />
        </TransferListWrapper>
      </TransferListCard>
    </FormTemplate>
  );
};

const SubmitButton = ({ isEditMode }: { isEditMode: boolean }) => {
  return (
    <>
      {`${isEditMode ? "Editar" : "Criar"} cargo`}
      <Icons name="IconCheck" fill="transparent" />
    </>
  );
};

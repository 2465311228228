import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useUpdateCategory = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate, data, mutateAsync } =
    trpc.updateCategory.useMutation({
      onError: (error) => {
        const errorCode = error.data?.code;
        if (errorCode === "CONFLICT") {
          dispatchToast({
            content:
              "Categoria com mesmo nome já existe. Por favor atribua outro nome.",
            type: "error",
          });
        } else {
          dispatchToast({
            content: "Erro ao atualizar categoria.",
            type: "error",
          });
        }
      },
      onSuccess: () => {
        utils.listCategories.invalidate();
      },
    });
  return {
    updateCategory: mutate,
    isLoading,
    updatedCategory: data,
    mutateAsync,
  };
};

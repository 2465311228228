import { trpc } from "@/api/client";
import { useSession } from "@/common/user";
import { dispatchToast } from "@/utils";

export type EmployeesLinkedCostCenterProps = {
  companyId?: string;
  limit: number;
  page: number;
  search?: string;
  costCenterIds?: string[];
  costCenterActive?: boolean;
};

export const useGetEmployeesLinkedCostCenter = ({
  companyId,
  limit,
  page,
  search,
  costCenterIds,
  costCenterActive,
}: EmployeesLinkedCostCenterProps) => {
  const { companyId: companyIdSession } = useSession();
  const { data, isLoading, refetch } =
    trpc.getEmployeesLinkedCostCenter.useQuery(
      {
        companyId: companyId ?? companyIdSession,
        costCenterIds,
        costCenterActive,
        limit,
        page,
        search,
      },
      {
        onError: (error) => {
          dispatchToast({
            content: error.message,
            type: "error",
          });
        },
      },
    );

  return {
    data: !isLoading ? data?.records ?? [] : [],
    metadata: data?.metadata ?? null,
    total: data?.metadata?.totalCount ?? 0,
    isLoading,
    refetch,
  };
};

import { tableControllers, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { MainRowColumn } from "@/pages/PageCostCenters/components/MainRowColumn";

import { EmployeesToLinkCostCenter } from "../../../../../../../bff/src/routers/costCenters/types";
import { EmployeeStatus } from "../../../../../../../bff/src/services/company-management.service";

import * as SC from "./styled";

export type Columns = Parameters<
  typeof tableControllers.useTableColumns<EmployeesToLinkCostCenter>
>[0]["columns"];

export const TableColumns = (): Columns => {
  const emptyData = "-";

  return [
    {
      header: "Pessoa",
      accessorKey: "name",
      cell: ({ row }) => {
        const cpf = row.original.documentNumber
          ? row.original.documentNumber
              .replace(/\D/g, "")
              .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
          : emptyData;

        return (
          <MainRowColumn
            allowLineWrapping={false}
            text={row.original?.name || emptyData}
            caption={cpf}
            status={row.original?.status as EmployeeStatus}
          />
        );
      },
    },
    {
      header: "Cargo",
      accessorKey: "roles",
      cell: ({ row }) => (
        <MainRowColumn text={row.original.roles?.[0]?.name || emptyData} />
      ),
    },
    {
      header: "Departamento",
      accessorKey: "departments",
      cell: ({ row }) => {
        const data = row.original.departments;

        if (!data || data?.length === 0) return emptyData;

        const departaments = (data || [])
          .slice(0, 1)
          .map((departament) => (
            <SC.DepartamentTag variant="gray">
              {departament.name || emptyData}
            </SC.DepartamentTag>
          ));

        if (data?.length && data?.length > 1)
          departaments.push(
            <Tooltip
              title={data
                .map((d) => d.name)
                .slice(1)
                .join(", ")}
            >
              <SC.DepartamentTag
                sx={{ cursor: "not-allowed", pointerEvents: "none" }}
                variant="gray"
              >
                {`+${data.length - 1}`}
              </SC.DepartamentTag>
            </Tooltip>,
          );

        return (
          <SC.ContainerDepartaments>{departaments}</SC.ContainerDepartaments>
        );
      },
    },
  ];
};

import styled, { ThemeContext } from "styled-components";
import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledTextField = styled(TextField)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CheckboxLabel = styled(Typography)``;

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

import { Radio, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "./styled";

type Props = {
  title: string;
  value?: boolean;
  onChange: (data: boolean) => void;
};

export const StatusFilter = ({ title, value, onChange }: Props) => {
  const getItem = (data: boolean) => (
    <SC.RadioContainer>
      <Radio
        name="radio-status"
        onChange={() => onChange(data)}
        checked={String(value) === String(data)}
      />
      <Typography variant="body3" weight={600}>
        {data ? "Ativo" : "Inativo"}
      </Typography>
    </SC.RadioContainer>
  );

  return (
    <SC.Container>
      <Typography variant="headline8">{title}</Typography>
      <div>
        {getItem(true)}
        {getItem(false)}
      </div>
    </SC.Container>
  );
};

import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

type CreateCategoryInput = {
  companyId: string;
  name: string;
};

export const useCreateCategory = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate, data, mutateAsync } =
    trpc.createCategory.useMutation({
      onError: (error) => {
        const errorCode = error.data?.code;
        if (errorCode === "CONFLICT") {
          dispatchToast({
            content:
              "Categoria com mesmo nome já existe. Por favor atribua outro nome.",
            type: "error",
          });
        } else {
          dispatchToast({
            content: "Erro ao criar categoria.",
            type: "error",
          });
        }
      },
      onSuccess: () => {
        dispatchToast({
          content: "Categoria criada com sucesso",
          type: "success",
        });
        utils.listCategories.invalidate();
      },
    });

  const { isLoading: manyIsLoading, mutate: createMany } =
    trpc.createManyCategory.useMutation({
      onError: (error) => {
        const errorCode = error.data?.code;
        if (errorCode === "CONFLICT") {
          dispatchToast({
            content:
              "Categoria com mesmo nome já existe. Por favor atribua outro nome.",
            type: "error",
          });
        } else {
          dispatchToast({
            content: "Erro ao criar categoria.",
            type: "error",
          });
        }
      },
      onSuccess: () => {
        dispatchToast({
          content: "Categoria criada com sucesso",
          type: "success",
        });
        utils.listCategories.invalidate();
      },
    });

  const createManyCategories = (
    input: CreateCategoryInput[],
    options?: {
      modalController?: {
        closeModal: () => void;
      };
    },
  ) => {
    createMany(input);
    if (options?.modalController) {
      options.modalController.closeModal();
    }
  };

  return {
    createCategory: mutate,
    createManyCategories,
    isLoading,
    createdCategory: data,
    mutateAsync,
  };
};

import { useSession } from "@/common/user";
import TransferListV2 from "@/components/TransferListV2";
import { useFindEmployees } from "@/hooks";
import {
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Employee } from "bff/src/services/company-management.service";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Container,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
  TransferListArea,
} from "./styled";

interface CreateTransferListProps {
  title: string;
  employeesToInsert: Employee[];
  setEmployeesToInsert: Dispatch<SetStateAction<Employee[]>>;
}

const PAGE_SIZE_DEFAULT_STEP = 20;

export const CreateTransferList = ({
  title,
  employeesToInsert,
  setEmployeesToInsert,
}: CreateTransferListProps) => {
  const { companyId } = useSession();
  const [listedEmployees, setListedEmployees] = useState<Employee[]>([]);

  const [searchState, setSearchState] = useState("");
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT_STEP,
  });

  const {
    data: employees,
    isLoading: findEmployeesIsLoading,
    fetch: refetchEmployees,
  } = useFindEmployees();

  useEffect(() => {
    setListedEmployees(employees?.records || ([] as any));
  }, [employees]);

  useEffect(() => {
    refetchEmployees({
      companyId,
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      search: searchState,
    });
  }, [searchState, paginationState]);

  const columns = [
    {
      header: "Nome",
      accessorKey: "name",
      cell: (context: any) => {
        const name = context?.cell?.row?.original?.name;
        const email = context?.cell?.row?.original?.email;
        return (
          <FirstColumnCell>
            <GenericProfilePicture size={40} name={name} />
            <FirstColumnCellData>
              <Typography variant="body4">{name}</Typography>
              {!!email && (
                <EmailWrapper>
                  <EmailIcon name="IconMail" size={16} fill="transparent" />
                  <EmailText variant="caption">{email}</EmailText>
                </EmailWrapper>
              )}
            </FirstColumnCellData>
          </FirstColumnCell>
        );
      },
    },
  ];

  return (
    <div>
      <StyledTitle variant="headline6">{title}</StyledTitle>
      <Container>
        <TextWrapper>
          <StyledSubtitle variant="headline8">
            Selecione pela lista
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Escolha quais pessoas você gostaria de adicionar à esse
            departamento.
          </StyledDescription>
        </TextWrapper>
        <TransferListArea>
          <TransferListV2
            data={listedEmployees || []}
            columns={columns}
            loading={findEmployeesIsLoading}
            tableTitle="Selecione os integrantes do departamento"
            tableTagLabel={`${employees?.metadata.totalCount || 0} itens`}
            onSearchChange={setSearchState}
            pagination={paginationState}
            onPaginationChange={setPaginationState}
            selected={employeesToInsert}
            setSelected={setEmployeesToInsert}
            dataSize={employees?.metadata.totalCount || 0}
          />
        </TransferListArea>
      </Container>
    </div>
  );
};

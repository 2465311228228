import styled from "styled-components";
import { Unstable } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledDrawer = styled(Unstable.Drawer)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const FiltersContainer = styled.div`
  margin-bottom: 75px;
`;

import { useSession } from "@/common/user";
import {
  getAccessTokenPayloadSync,
  getPermissionsFlags,
  IPermissionsFlags,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

export function useCheckUnleashFlags() {
  const { companyId, userId } = useSession();
  const [flags, setFlags] = useState<IPermissionsFlags["flags"]>([]);
  const getUnleashToggle = async () => {
    const { flags: unleashFlags } = await getPermissionsFlags(
      companyId,
      userId,
      getAccessTokenPayloadSync().economicGroupId,
    );
    setFlags(unleashFlags || []);
  };

  function checkUnleashFlag(flagName: string) {
    return flags?.some((flag: any) => flag.name === flagName);
  }

  useEffect(() => {
    companyId && getUnleashToggle();
  }, [companyId]);

  return checkUnleashFlag;
}

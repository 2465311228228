import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacings.xs3};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding-vertical: 12px;
  width: 75%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: 18px;
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-20);
  weight: 700;
  margin-bottom: 4px;
`;

export const Description = styled(Typography)`
  display: inline;
  flex-direction: row;
  color: var(--color-neutral-40);
  weight: 400;
`;

export const StyledButton = styled(Button)`
  border-radius: 12px;
  margin-top: 18px;
`;

import { useContext, useMemo } from "react";
import {
  SelectField,
  Skeleton,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useFormikContext } from "formik";

import { CostCentersContext } from "@/shared/context/CostCentersContext";

import { CreateCostCenterInput } from "../../../../../../bff/src/services/platform-entities.service";

import * as SC from "./styled";

type Props = {
  isLoading?: boolean;
};

export const FormRegister = ({ isLoading = false }: Props) => {
  const { employees } = useContext(CostCentersContext);
  const {
    values,
    errors,
    handleChange,
    setFieldError,
    handleBlur,
    setFieldValue,
  } = useFormikContext<
    Omit<CreateCostCenterInput, "companyId"> & { memberEmployeeIds: string[] }
  >();
  const optionsEmployees = useMemo(
    () =>
      employees?.data?.map((employee) => ({
        label: employee.name,
        value: employee.id,
      })),
    [employees],
  );

  return (
    <SC.Container>
      {isLoading ? (
        new Array(4).fill({}).map((_, i) => (
          <SC.ContainerField key={i}>
            <Skeleton
              variant="rounded"
              height={i === 3 ? 126 : 56}
              width={455}
            />
          </SC.ContainerField>
        ))
      ) : (
        <>
          <SC.ContainerField>
            <TextField
              fullWidth
              name="name"
              label="Nome do centro de custo"
              value={values.name}
              onChange={(e) => {
                errors?.name && errors?.code && setFieldError("name", "");
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors.name}
              helperText={errors.name}
            />
          </SC.ContainerField>
          <SC.ContainerField>
            <TextField
              fullWidth
              name="code"
              label="Código"
              value={values.code}
              onChange={(e) => {
                errors?.code && setFieldError("code", "");
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors.code}
              helperText={
                errors.code ??
                "Insira o código utilizado pela sua empresa para melhor controle."
              }
            />
          </SC.ContainerField>
          <SC.ContainerField>
            <SelectField
              options={optionsEmployees}
              searchable
              fullWidth
              disabled={employees?.isLoading}
              label={employees?.isLoading ? "Carregando..." : "Responsável"}
              name="responsibleEmployeeId"
              value={values.responsibleEmployeeId}
              onSelectChange={(_, data) => {
                errors?.responsibleEmployeeId &&
                  setFieldError("responsibleEmployeeId", "");
                setFieldValue("responsibleEmployeeId", data?.value ?? null);
              }}
              onBlur={handleBlur}
              error={!!errors.responsibleEmployeeId}
              helperText={
                errors.responsibleEmployeeId ??
                "Selecione o responsável pelas despesas atreladas ao centro de custo."
              }
            />
          </SC.ContainerField>
          <SC.ContainerStatus>
            <div>
              <Typography variant="body3" weight={600} color="neutral.30">
                Status do centro de custo
              </Typography>
              <Typography variant="body4" weight={400} color="neutral.40">
                Apenas centros de custos com status ativo serão exibidos para
                uso.
              </Typography>
            </div>
            <SC.ContainerRadio>
              <SC.ContainerRadioItem>
                <SC.RadioItem
                  name="active"
                  onChange={() => setFieldValue("active", true)}
                  checked={values.active}
                  value={true}
                />
                <Typography variant="body3" weight={600} color="neutral.30">
                  Ativo
                </Typography>
              </SC.ContainerRadioItem>
              <SC.ContainerRadioItem>
                <SC.RadioItem
                  name="active"
                  onChange={() => setFieldValue("active", false)}
                  checked={!values.active}
                />
                <Typography variant="body3" weight={600} color="neutral.30">
                  Inativo
                </Typography>
              </SC.ContainerRadioItem>
            </SC.ContainerRadio>
          </SC.ContainerStatus>
        </>
      )}
    </SC.Container>
  );
};

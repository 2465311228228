import {
  MultiSelect,
  PillButton,
  SelectField,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Rule } from "../..";

import {
  ActionSelectContainer,
  ButtonContainer,
  FieldSelectContainer,
  FilterSelectionContainer,
  ValueSelectContainer,
} from "./styled";

type Option = {
  label: string;
  value: string;
};

const renderSimpleSelect = ({
  options,
  value,
  label,
  handleSelect,
  index,
  keyToUpdate,
}: {
  options: { label: string; value: any }[];
  value: { value: string | number | undefined };
  label: string;
  handleSelect: ({
    rule,
    index,
  }: {
    rule: {
      field?: "departments" | "roles";
      operator?: "IN" | "NOTIN";
      value?: string[];
    };
    index: number;
  }) => void;
  index: number;
  keyToUpdate: string;
}) => {
  return (
    <SelectField
      options={options}
      value={value}
      fullWidth
      label={label}
      onSelectChange={(_, { value = "" }) =>
        handleSelect({ rule: { [keyToUpdate]: value }, index })
      }
    />
  );
};

const renderMultipleSelect = ({
  options,
  value,
  handleSelect,
  index,
  disableValueFilter,
  label,
}: {
  options: { label: string; value: any }[];
  value: { value: string | number | undefined; label: string | undefined }[];
  label?: string;
  handleSelect: ({
    rule,
    index,
  }: {
    rule: {
      field?: "departments" | "roles";
      operator?: "IN" | "NOTIN";
      value?: string[];
    };
    index: number;
  }) => void;
  index: number;
  disableValueFilter: boolean;
}) => {
  return (
    <MultiSelect
      onSelectChange={(_, v) => {
        handleSelect({
          rule: {
            value: v?.map((o: { value: string }) => o?.value),
          },
          index,
        });
      }}
      value={value}
      renderInput={() => null}
      fullWidth
      multiple
      label={label || "Atributos"}
      options={options}
      disabled={disableValueFilter}
    />
  );
};

export type FilterProps = {
  index: number;
  fieldOptions: Option[];
  actionOptions: Option[];
  valueOptions: Option[];
  handleSelect: ({
    rule,
    index,
  }: {
    rule: {
      field?: "departments" | "roles";
      operator?: "IN" | "NOTIN";
      value?: string[];
    };
    index: number;
  }) => void;
  onDeleteButtonClick: (index: number) => void;
  renderDeleteButton: boolean;
  loading: boolean;
  disableValueFilter: boolean;
  rule: Rule;
};

export const Filter = ({
  actionOptions,
  disableValueFilter,
  fieldOptions,
  handleSelect,
  index,
  loading,
  onDeleteButtonClick,
  renderDeleteButton,
  valueOptions,
  rule,
}: FilterProps) => (
  <FilterSelectionContainer>
    <ActionSelectContainer renderDeleteButton={renderDeleteButton}>
      {loading ? (
        <Skeleton height="60px" />
      ) : (
        renderSimpleSelect({
          options: actionOptions,
          value: { value: rule?.operator },
          label: "Ação",
          handleSelect,
          index,
          keyToUpdate: "operator",
        })
      )}
    </ActionSelectContainer>
    <FieldSelectContainer renderDeleteButton={renderDeleteButton}>
      {loading ? (
        <Skeleton height="60px" />
      ) : (
        renderSimpleSelect({
          options: fieldOptions,
          value: { value: rule?.field },
          label: "Campo",
          handleSelect,
          index,
          keyToUpdate: "field",
        })
      )}
    </FieldSelectContainer>
    <ValueSelectContainer renderDeleteButton={renderDeleteButton}>
      {loading ? (
        <Skeleton height="60px" />
      ) : (
        renderMultipleSelect({
          options: valueOptions,
          value: rule.value?.map((v) => ({
            value: v,
            label: valueOptions.find((option) => option.value === v)?.label,
          })),
          handleSelect,
          index,
          disableValueFilter,
        })
      )}
    </ValueSelectContainer>
    {renderDeleteButton && (
      <ButtonContainer>
        <PillButton
          variant="default"
          size="medium"
          type="primary"
          icon="IconTrash"
          onClick={() => onDeleteButtonClick(index)}
        />
      </ButtonContainer>
    )}
  </FilterSelectionContainer>
);

import { ReactNode } from "react";
import {
  Button,
  Icons,
  IconTypes,
  LinkButton,
  ShapeIcon,
  Spinner,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Highlight } from "@Components/Highlight";
import { useTheme } from "styled-components";

import * as SC from "./styled";

type Props = {
  isLoader?: boolean;
  header: {
    highlight?: string;
    title?: string;
    custom?: ReactNode;
  };
  button: {
    name?: string;
    onClick?: () => void;
    icon?: IconTypes;
    custom?: ReactNode;
  };
  children?: ReactNode;
  footer: {
    linkFaq: string;
    custom?: ReactNode;
  };
};

export const EmptyView = ({
  isLoader,
  header,
  button,
  children,
  footer,
}: Props) => {
  const { colors } = useTheme();

  if (isLoader)
    return (
      <SC.Container>
        <Spinner size={64} variant="primary" />
      </SC.Container>
    );

  const renderHeader = () => {
    if (header?.custom) return header.custom;
    return (
      <SC.Header>
        <Highlight text={header?.highlight} />
        {header?.title && (
          <Typography variant="headline8" weight={700}>
            {header?.title}
          </Typography>
        )}
      </SC.Header>
    );
  };

  const renderButton = () => {
    if (button?.custom) return button.custom;
    return (
      <SC.ButtonContainer>
        <Button
          size="medium"
          variant="primary"
          variantType="default"
          onClick={button.onClick}
        >
          {button?.name} {button?.icon && <Icons name={button?.icon} />}
        </Button>
      </SC.ButtonContainer>
    );
  };

  const renderFooter = () => {
    if (footer?.custom) return footer.custom;
    return (
      <SC.Footer>
        <ShapeIcon
          name="IconFolder"
          variant="default"
          color={colors.brand[50]}
          strokeWidth={"3px"}
        />
        <Typography variant="body4" weight={700}>
          Ficou com alguma dúvida?
        </Typography>
        <LinkButton
          variant="default"
          size="medium"
          target="_blank"
          href={footer?.linkFaq}
        >
          Acesse nossa FAQ
        </LinkButton>
      </SC.Footer>
    );
  };

  return (
    <SC.Container>
      <SC.Content>
        {renderHeader()}
        {renderButton()}
        {children && <SC.Body>{children}</SC.Body>}
        {renderFooter()}
      </SC.Content>
    </SC.Container>
  );
};

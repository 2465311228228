import { useNavigate } from "react-router-dom";
import { Icons, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";

import {
  ButtonContainer,
  Description,
  HeaderContainer,
  StyledButton,
  Title,
  TitleContainer,
} from "./styled";

const TooltipDescription = (
  <>
    <Typography variant="body4" weight={600}>
      Você pode usar os campos customizados para armazenar qualquer informação
      que seja específica da sua organização.
    </Typography>
    <br />
    <Typography variant="body4" weight={600}>
      Por exemplo:
    </Typography>
    <Typography variant="body4" weight={600}>
      <p>
        • Saber qual tamanho de camiseta sua equipe usa, para o pedido de
        uniformes;
      </p>
      <p>
        • Mapear restrições alimentares e alergias para o almoço de fim de ano;
      </p>
      <p>
        • Saber qual a preferência de sistema operacional do novo colaborador,
        para fazer o envio de equipamentos de onboarding...
      </p>
    </Typography>
    <br />
    <Typography variant="body4" weight={600}>
      Já para coletar dados como RG, CPF, data de admissão, gênero, cor,
      endereço residencial e etc, você pode usar os campos que já vêm por padrão
      no cadastro e perfil de pessoa da plataforma.
    </Typography>
  </>
);

export const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <TitleContainer>
        <Title variant="headline7">Campos padrão e customizados</Title>
        <Description variant="body4">
          Os campos customizados são uma maneira flexível de coletar dados
          adicionais da sua equipe. Após criados, eles ficam visíveis no perfil
          de cadastro das pessoas e também podem ser associados em formulários
          personalizados dos módulos da plataforma.&nbsp;
          <p style={{ display: "inline-block", marginBottom: -4 }}>
            <Tooltip title={TooltipDescription}>
              <Icons name="IconInfoCircle" size={18} />
            </Tooltip>
          </p>
        </Description>
      </TitleContainer>
      <ButtonContainer>
        <StyledButton
          variant="primary"
          size="medium"
          onClick={() => navigate(routes.pageCreateCustomField)}
        >
          Criar campo customizado
          <Icons name="IconPlus" />
        </StyledButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

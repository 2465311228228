import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";

type FooterProps = {
  disableForward: boolean;
  loading: boolean;
  handleForwardButtonClick: () => void;
};

export const Footer = ({
  disableForward,
  handleForwardButtonClick,
  loading,
}: FooterProps) => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      <CancelButton
        variant="neutral"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          disabled={loading}
          onClick={() => navigate(routes.pageEditGroup(groupId!))}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={loading}
          onClick={() => handleForwardButtonClick()}
        >
          Concluir <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};

import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  gap: 4px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 36px 0px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledButton = styled(Button)``;

import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useDeleteCategory = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate, data } = trpc.deleteCategory.useMutation({
    onError: () => {
      dispatchToast({
        content:
          "Erro ao deletar categoria. Por favor entre em contato com o atendimento",
        type: "error",
      });
    },
    onSuccess: () => {
      dispatchToast({
        content: "Categoria deletada com sucesso",
        type: "success",
      });
      utils.listCategories.invalidate();
    },
  });

  return { deleteCategory: mutate, isLoading, deletedCategory: data };
};

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

type Props = {
  step: number;
};

export const StepTitle = ({ step }: Props) => {
  const getTitle = () => {
    switch (step) {
      case 1:
        return "Informações básicas do centro de custo";
      case 2:
        return "Para quais pessoas você quer aplicar este centro de custo?";
      default:
        return "Revise suas escolhas antes de confirmar";
    }
  };
  const getCaption = () => {
    if (step == 1)
      return "Dê um nome para identificar, insira um código para auxiliar no controle e defina um responsável para este centro de custo.";

    return "Selecione abaixo as pessoas que devem ter acesso a este centro de custo.";
  };

  return (
    <div>
      <Typography variant="headline8" weight={700}>
        {getTitle()}
      </Typography>
      {step <= 2 && (
        <Typography variant="body4" weight={400}>
          {getCaption()}
        </Typography>
      )}
    </div>
  );
};

import { useEffect, useState } from "react";
import { Selected } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/controllers/useSelectedRows";
import { Row } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types";

type Props<T extends { id: T["id"] }> = Selected<T> & {
  rows: Row<T>[];
  selectedItems: T["id"][];
  pageNumber?: number;
  pageSize?: number;
  setSelectedTableRows: (_: Row<T>[]) => void;
  resetSelected: () => void;
};

export function useSelectedTable<T extends { id: T["id"] }>({
  selectedItems = [],
  pageNumber = 1,
  pageSize = 10,
  selected = [],
  allSelected = false,
  rows = [],
  setSelectedTableRows,
  resetSelected,
}: Props<T>) {
  const [selectedRows, setSelectedRows] = useState<Row<T>[]>([]);
  const [selectedIds, setSelectedIds] = useState<T["id"][]>(selectedItems);

  useEffect(() => {
    const _selectedRows = !allSelected
      ? selected
      : rows?.filter(
          (row) =>
            !selected?.some(({ original }) => original.id === row.original.id),
        );
    const idsSelectedOtherPages = selectedItems?.filter(
      (id) => !rows?.some((row) => row.original.id === id),
    );
    const _selectedIds = [
      ...new Set(
        idsSelectedOtherPages.concat(
          _selectedRows.map(({ original }) => original.id),
        ),
      ),
    ];

    setSelectedIds(_selectedIds);
    setSelectedRows(_selectedRows);
  }, [selected.length, allSelected]);

  useEffect(() => {
    const _selectedRows = rows.filter((row) =>
      selectedItems.some((id) => id === row.original.id),
    );

    if (_selectedRows.length === rows.length) resetSelected();

    setSelectedTableRows(_selectedRows);
    setSelectedIds(selectedItems);
  }, [selectedItems.length, pageSize, pageNumber, rows.length]);

  return {
    countSelectedRows: selectedIds?.length ?? 0,
    selctedTableRows: selectedRows,
    selectedIds: selectedIds,
  };
}

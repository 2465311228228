import { Radio } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs2,
  maxWidth: "588px",
  marginBottom: theme.spacings.xs2,
}));

export const ContainerField = styled.div(({ theme }) => ({
  width: "100%",
}));

export const ContainerStatus = styled.div(({ theme }) => ({
  padding: `${theme.spacings.xs3} ${theme.spacings.xs2} ${theme.spacings.xs2} ${theme.spacings.xs2}`,
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[70]}`,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs3,
}));

export const ContainerRadio = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: theme.spacings.xs2,
  alignItems: "center",
}));

export const ContainerRadioItem = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const RadioItem = styled(Radio)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    paddingRight: "4px",
  },
}));

import styled from "styled-components";

export const PopoverStyled = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  padding: 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  gap: 8px;
`;

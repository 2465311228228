import { trpc } from "@/api/client";
import { useSession } from "@/common/user";
import { dispatchToast } from "@/utils";
import { UpdateCostCenterInput } from "bff/src/services/platform-entities.service";

import { useCallback } from "react";

export const useUpdateCostCenter = (companyId?: string) => {
  const { companyId: companyIdSession } = useSession();
  const { isLoading, data, mutateAsync, isSuccess, isError, error } =
    trpc.updateCostCenter.useMutation();
  const utils = trpc.useContext();

  const updateCostCenter = useCallback(
    (input: Omit<UpdateCostCenterInput, "companyId">) => {
      mutateAsync(
        { ...input, companyId: companyIdSession },
        {
          onError: (error) => {
            if (error.data?.httpStatus === 500)
              dispatchToast({
                content:
                  error?.message ??
                  "Não foi possível atualizar o centro de custo. ",
                description:
                  "Tente novamente e, se o erro persistir, entre em contato com o nosso atendimento.",
                type: "error",
              });
            else
              dispatchToast({
                content: error?.message,
                type: "error",
              });
          },
          onSuccess: () => {
            dispatchToast({
              content: "Centro de custos atualizado com sucesso",
              type: "success",
            });
            utils.getCostCenters.invalidate();
            utils.getEmployeesLinkedCostCenter.invalidate();
          },
        },
      );
    },
    [mutateAsync],
  );

  return { isLoading, data, isSuccess, updateCostCenter };
};

import { trpc } from "@/api/client";
export const useGetDepartmentEmployees = ({
  departmentId,
  page,
  pageSize,
  searchQuery,
}: {
  departmentId: string;
  page: number;
  pageSize: number;
  searchQuery?: string;
}) => {
  const { data, isLoading, refetch } = trpc.getDepartmentEmployees.useQuery(
    { departmentId, page, pageSize, searchQuery },
    { enabled: !!departmentId },
  );

  return { data, isLoading, refetch };
};

import {
  Button,
  Icons,
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import * as SC from "./styled";

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationModalDeactivation = ({
  open = false,
  isLoading = false,
  onClose,
  onConfirm,
}: Props) => {
  const { colors } = useTheme();
  return (
    <Modal.Root showClose={true} open={open} onClose={onClose} size="xs">
      <>
        <SC.HeaderContainer>
          <ShapeIcon
            variant="error"
            name="IconAlertCircle"
            size={48}
            color={colors.feedback.error[40]}
          />
          <Typography variant="caption" weight={700} color="feedback.error.40">
            Atenção!
          </Typography>
          <Typography variant="headline8">
            Tem certeza que deseja desativar o centro de custo?
          </Typography>
          <Typography variant="body4" color={"neutral.50"}>
            Ao desativá-lo, não será mais possível utilizar o centro de custo
            para realizar ações na plataforma.
          </Typography>
        </SC.HeaderContainer>
        <SC.FooterContainer>
          <LinkButton variant="neutral" size="medium" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            variantType="error"
            size="medium"
            loading={isLoading}
            onClick={onConfirm}
          >
            Confirmar desativação
            <Icons name="IconToggleLeft" />
          </Button>
        </SC.FooterContainer>
      </>
    </Modal.Root>
  );
};

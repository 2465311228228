import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ContainerDepartaments = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  gap: theme.spacings.xs4,
  with: "fit-content",
}));

export const DepartamentTag = styled(Tag)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.colors.neutral[90],
    color: "initial",
    border: "none",
    boxShadow: "none",
    cursor: "default",
  },
}));

export const TextHeader = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[30],
  marginBottom: theme.spacings.xs5,
}));

import { trpc } from "@/api/client";
import { useSession } from "@/common/user";
import { dispatchToast } from "@/utils";
import { useMemo } from "react";

type Props = {
  companyId?: string;
  limit: number;
  page: number;
  search?: string;
  costCenterId?: string;
};

export const useGetEmployeesToLinkCostCenter = ({
  companyId,
  limit,
  page,
  search,
  costCenterId,
}: Props) => {
  const { companyId: companyIdSession } = useSession();
  const { data, isLoading, refetch } =
    trpc.getEmployeesToLinkCostCenter.useQuery(
      {
        companyId: companyId ?? companyIdSession,
        costCenterId: costCenterId ? [costCenterId] : undefined,
      },
      {
        onError: (error) => {
          dispatchToast({
            content: error.message,
            type: "error",
          });
        },
      },
    );

  const filteredOptions = useMemo(() => {
    if (!search) return data?.records || [];
    return (data?.records || []).filter((option) =>
      option.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, data]);

  const response = filteredOptions.slice((page - 1) * limit, page * limit);

  return {
    data: !isLoading ? response ?? [] : [],
    metadata: data?.metadata ?? null,
    total: search ? response.length : data?.metadata.totalCount ?? 0,
    isLoading,
    refetch,
  };
};

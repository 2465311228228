import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

import { StatusEnum } from "@/common/StatusEnum";

export const StatusTag = ({ status }: { status: string }) => {
  const style = { minWidth: "max-content" };
  switch (status) {
    case StatusEnum.ACTIVE:
      return (
        <Tag variant="success" hasLeftDotIcon={true} as={"label"} style={style}>
          Ativo
        </Tag>
      );

    case StatusEnum.INACTIVE:
      return (
        <Tag variant="error" hasLeftDotIcon={true} as={"label"} style={style}>
          Inativo
        </Tag>
      );

    case StatusEnum.INVITATION_SENT:
      return (
        <Tag variant="blue" hasLeftDotIcon={true} as={"label"} style={style}>
          Convite enviado
        </Tag>
      );

    case StatusEnum.INVITATION_SCHEDULED:
      return (
        <Tag variant="blue" hasLeftDotIcon={true} as={"label"} style={style}>
          Convite Agendado
        </Tag>
      );

    case StatusEnum.INVITATION_EXPIRED:
      return (
        <Tag variant="yellow" hasLeftDotIcon={true} as={"label"} style={style}>
          Convite expirado
        </Tag>
      );

    case StatusEnum.INVITATION_PENDING:
      return (
        <Tag variant="blue" hasLeftDotIcon={true} as={"label"} style={style}>
          Aguardando convite
        </Tag>
      );

    default:
      return <></>;
  }
};

import { ReactNode, useMemo } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Icons,
  IconTypes,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "./styled";

export type HeaderPageBreadcrumbs = {
  route?: string;
  label: string;
  options?: NavigateOptions;
};

export type HeaderPageAction = {
  name?: string;
  onClick?: () => void;
  icon?: IconTypes;
  custom?: ReactNode;
  isLoading?: boolean;
};

export type HeaderPageProps = {
  title: string;
  caption?: string;
  routes?: HeaderPageBreadcrumbs[];
  button?: HeaderPageAction;
};

export const HeaderPage = ({
  title,
  caption,
  routes,
  button,
}: HeaderPageProps) => {
  const navigate = useNavigate();

  const breadCrumbs = useMemo(
    () =>
      routes?.map(({ label, route, options }, index) => {
        return (
          <SC.LinkButtonContainer isCurrentPage={index === routes.length - 1}>
            <LinkButton
              underlined={index !== routes.length - 1}
              key={`breadCrumbs-${index}`}
              color="inherit"
              variant="neutral"
              disableRipple
              disableTouchRipple
              onClick={() => route && navigate(route, options)}
            >
              {label}
            </LinkButton>
          </SC.LinkButtonContainer>
        );
      }),
    [routes],
  );

  const renderButton = () => {
    if (!button) return <></>;
    if (button?.custom) return button.custom;
    return (
      <div>
        <Button
          size="medium"
          variant="primary"
          variantType="default"
          onClick={button.onClick}
          loading={button.isLoading}
        >
          {button.name} {button.icon && <Icons name={button.icon} />}
        </Button>
      </div>
    );
  };

  return (
    <SC.Container>
      {breadCrumbs && routes && (
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={breadCrumbs}
        />
      )}

      <SC.Content>
        <SC.TitleContainer>
          <Typography variant="headline7">{title}</Typography>
          <Typography variant="body4" color="neutral.50">
            {caption}
          </Typography>
        </SC.TitleContainer>
        {renderButton()}
      </SC.Content>
    </SC.Container>
  );
};

import { FieldComponentInterface } from "@Components/FieldComponent";

interface ModelInterface extends FieldComponentInterface {
  key: string;
}

export const model: (address: AddressType) => ModelInterface[] = (
  address: AddressType,
) => {
  return [
    {
      key: "zipCode",
      name: "CEP",
      value: address?.zipCode,
      locked: false,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      placeholder: "XXXXX-XXX",
      inputProps: {
        maxLength: 9,
      },
      imaskProps: {
        mask: "00000-000",
      },
      inputMode: "numeric",
      helperText: "Favor digitar o CEP da empresa",
    },
    {
      key: "street",
      name: "Logradouro",
      value: address?.street,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "text",
      helperText: "Favor digitar o logradouro da empresa",
    },
    {
      key: "number",
      name: "Número",
      value: address?.number,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "shortText",
      helperText: "Favor digitar o número da empresa",
    },
    {
      key: "complement",
      name: "Complemento",
      value: address?.complement,
      locked: false,
      pattern: false,
      component: "shortText",
      required: false,
      type: "text",
    },
    {
      key: "district",
      name: "Bairro",
      value: address?.district,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "text",
      helperText: "Favor digitar o bairro da empresa",
    },
    {
      key: "state",
      name: "Estado",
      value: address?.state,
      locked: false,
      pattern: false,
      component: "select",
      required: true,
      type: "array",
      options: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ].map((s) => ({ label: s, value: s })),
      helperText: "Favor digitar o Estado (UF) da empresa",
    },
    {
      key: "city",
      name: "Cidade",
      value: address?.city,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "text",
      helperText: "Favor digitar a cidade da empresa.",
    },
  ];
};

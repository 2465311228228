import { Typography, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  max-height: 400px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 25%;
  align-self: center;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  gap: 4px;
`;

export const OptionBox = styled.div<{ selected?: boolean }>`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.secondary[95]
      : theme.colors.neutral[100]} !important;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 45px;
  justify-content: space-between;
  padding: 12px;
  width: 98%;
`;

export const CustomFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 384px;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const CheckboxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledCheckboxText = styled(Typography)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary[30] : theme.colors.neutral[30]};
`;

export const StyledResultText = styled(Typography)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary[70] : theme.colors.neutral[60]};
`;

export const TitleWrapper = styled.div``;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding-bottom: 4px;
`;

export const StyledFilterTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 200px;
  height: 45px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  padding: 0px 12px;
  :focus-within {
    border: 2px solid ${({ theme }) => theme.colors.secondary[70]};
  }
`;

export const StyledSearchInput = styled(TextField)`
  .MuiFilledInput-root {
    background-color: transparent;
    border: 0px;
    height: 100% !important;
    margin: 1px;
    transition: border-color 0.1s ease-in-out, background-color 0.1s ease-in-out;
    &:hover:not(.MuiInputLabel-shrink):not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) {
      border: 0px;
      margin: unset;
    }
    &.Mui-disabled {
      border: 0px;
    }
    &::before,
    &::after {
      content: none;
    }
    input {
      color: ${({ theme }) => theme.colors.neutral[30]};
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: 600;
    }
  }
  .Mui-focused:not(label) {
    border: 0px;
    margin: unset;
  }

  .Mui-focused {
    ::-webkit-scrollbar-thumb {
      background-color: var(--color-secondary-light5);
      margin: 1px;
    }

    svg {
      stroke: var(--color-secondary-light5);
    }
  }
  .MuiInputLabel-shrink {
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.neutral[50]};
  }

  .Mui-disabled:not(label):not(input) {
    svg {
      stroke: var(--color-neutral-light5);
    }
  }

  .Mui-disabled {
    color: ${({ theme }) => theme.colors.neutral[70]};

    cursor: not-allowed;
  }
`;

import styled from "styled-components";

export const FooterContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs};
  border-top: solid ${({ theme }) => theme.borders.width.xs}
    ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  position: fixed;
  bottom: 0;
  width: fill-available;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  z-index: 99;
`;

import styled from "styled-components";

export const LoadingContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

export const OptionsContainer = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 321px;
  overflow-y: scroll;
`;

export const Footer = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SearchFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const OptionItemLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral30};
`;

export const OptionItemCustomIconWrapper = styled.div`
  svg {
    color: ${({ theme }) => theme.colors.neutral30};
  }
`;

type CustomFooterControlItemContainerProps = {
  $centralize?: boolean;
};

export const CustomFooterControlItemContainer = styled.li<CustomFooterControlItemContainerProps>`
  cursor: pointer;
  gap: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-self: ${({ $centralize }) => ($centralize ? "center" : "stretch")};
  padding: 16px;
  list-style: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral95};
  }
`;

export const CustomExtraLinkControlItemContainer = styled.li<CustomFooterControlItemContainerProps>`
  cursor: pointer;
  gap: 4px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-self: ${({ $centralize }) => ($centralize ? "center" : "stretch")};
  padding: 18px 16px 16px 16px;
  list-style: none;
`;

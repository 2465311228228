import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
import { boolean } from "zod";

export const FilterSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 12px 0px;
`;

export const ActionSelectContainer = styled.div<{
  renderDeleteButton: boolean;
}>`
  width: ${(props) => (props.renderDeleteButton ? "20%" : "23%")};
  min-height: 60px;
  border-radius: 12px;
  overflow: hidden;
`;

export const FieldSelectContainer = styled.div<{
  renderDeleteButton: boolean;
}>`
  width: ${(props) => (props.renderDeleteButton ? "26%" : "30%")};
  min-height: 60px;
  border-radius: 12px;
  overflow: hidden;
`;

export const ValueSelectContainer = styled.div<{
  renderDeleteButton: boolean;
}>`
  width: ${(props) => (props.renderDeleteButton ? "36%" : "40%")};
  min-height: 60px;
  border-radius: 12px;
  overflow: hidden;

  .multi-select-custom-theme div.MuiFilledInput-root {
    padding: 15px 0px 0px 15px !important;
    min-height: 58px !important;
  }

  .multi-select-custom-theme label {
    padding-top: 4px !important;
    padding-left: 2px !important;
  }

  .MuiChip-root.multi-select-tag {
    margin-top: 0px !important;
    transform: translateY(4px) translateX(-7px);
    height: 20px !important;
    padding: 3px 8px !important;
    background-color: #ffe0ef;
  }

  .MuiChip-label {
    padding-left: 0px !important;
  }

  .MuiChip-deleteIcon {
    margin-right: 0px !important;
  }

  .MuiFilledInput-input {
    padding-left: 0px !important;
    margin-top: 0px !important;
  }
`;

export const TableContainer = styled.div`
  margin-top: 12px;
`;

export const ButtonContainer = styled.div`
  width: 48px;
`;

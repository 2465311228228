import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { CustomFieldTypes, Option, typeComponentMapper } from "@/common/field";
import {
  useCreateCategory,
  useGetCustomFieldById,
  useUpdateCategory,
  useUpdateCustomField,
} from "@/hooks";
import { useListCategories } from "@/hooks/Categories/useListCategories";
import { routes } from "@/routes";
import { dispatchToast } from "@/utils";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  UpdateCustomFieldBody,
  UpdateCustomFieldForm,
} from "./components/UpdateCustomFieldForm";
import { Body, FormContainer } from "./styled";

export const PageUpdateCustomField = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const { customFieldId } = useParams();

  const [updateCustomFieldDto, setUpdateCustomFieldDto] =
    useState<UpdateCustomFieldBody>({});
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [showManageCategoriesModal, setShowManageCategoriesModal] =
    useState(false);

  const { data: fetchedCategories } = useListCategories(selectedCompany.id);
  const { updateCategory } = useUpdateCategory();
  const { updateCustomField, isLoading } = useUpdateCustomField({
    onSuccess: () => {
      dispatchToast({
        content: "Campo atualizado com sucesso",
        type: "success",
      });
      navigate(routes.pageCustomFields);
    },
  });
  const { createManyCategories, isLoading: categoryCreationIsLoading } =
    useCreateCategory();
  const { data: customField } = useGetCustomFieldById(customFieldId!);

  useEffect(() => {
    setUpdateCustomFieldDto({
      categoryId: customField?.categoryId,
      description: customField?.description,
      enumOptions: customField?.enumOptions,
      name: customField?.name,
      component: customField?.type
        ? typeComponentMapper(customField.type as CustomFieldTypes)
        : undefined,
    });
  }, [customField]);

  useEffect(() => {
    setCategoryOptions(
      fetchedCategories?.map((c) => ({ label: c.name, value: c.id })) || [],
    );
  }, [fetchedCategories]);

  const saveCategoriesChanges = (categoryOptions: Option[]) => {
    const newCategories: { companyId: string; name: string }[] = [];
    for (let co of categoryOptions) {
      const oldCategory = fetchedCategories?.find((cat) => cat.id === co.value);

      const shouldCreate = !oldCategory && co.label && !co.value;
      if (shouldCreate) {
        newCategories.push({ companyId: selectedCompany.id, name: co.label });
        continue;
      }

      const shouldUpdate = !!oldCategory && oldCategory.name !== co.label;
      if (shouldUpdate) {
        updateCategory({
          categoryId: co.value,
          companyId: selectedCompany.id,
          name: co.label,
        });
      }
    }
    if (newCategories.length) {
      createManyCategories(newCategories, {
        modalController: {
          closeModal: () => setShowManageCategoriesModal(false),
        },
      });
    }
  };

  const onSaveHandler = () => {
    updateCustomField({
      companyId: selectedCompany.id,
      customFieldId: customFieldId!,
      name: updateCustomFieldDto.name,
      description: updateCustomFieldDto.description,
      enumOptions: updateCustomFieldDto.enumOptions,
      categoryId: updateCustomFieldDto.categoryId,
    });
  };

  return (
    <Body>
      <Header />
      <>
        <FormContainer>
          <UpdateCustomFieldForm
            categoryOptions={categoryOptions}
            customFieldProps={updateCustomFieldDto}
            setCustomFieldProps={(customField) =>
              setUpdateCustomFieldDto({
                ...updateCustomFieldDto,
                ...customField,
              })
            }
            saveCategoriesChanges={saveCategoriesChanges}
            saveButtonLoading={categoryCreationIsLoading}
            showManageCategoriesModal={showManageCategoriesModal}
            setShowManageCategoriesModal={setShowManageCategoriesModal}
          />
        </FormContainer>
        <Footer
          onButtonClick={onSaveHandler}
          buttonDisabled={!updateCustomFieldDto?.name}
        />
      </>
    </Body>
  );
};

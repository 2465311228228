import { useState } from "react";
import {
  PageContainer,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  Body,
  Container,
  ContentWrapper,
  FieldDescriptionInput,
  FieldTitleInput,
  FormContainer,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledSubtitleContainer,
  StyledTitle,
  TextWrapper,
  TypeDescriptionContainer,
  TypeSelectionCard,
  TypeSelectionDescription,
  TypeSelectionTitle,
  TypeSelectionWrapper,
} from "./styled";

const MAX_DESCRIPTION_LENGTH = 600;
enum GROUP_TYPES {
  DYNAMIC = "DYNAMIC",
  STATIC = "STATIC",
}

export const PageCreateGroup = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groupType, setGroupType] = useState<
    "DYNAMIC" | "STATIC" | undefined
  >();

  const cardOnClickHandler = (groupType: "DYNAMIC" | "STATIC") => {
    setGroupType(groupType);
  };

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Criar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Informações básicas
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Adicione o nome e descrição do grupo.
              </StyledDescription>
            </TextWrapper>
            <FormContainer>
              <FormWrapper>
                <StyledFormTitle variant="headline8">
                  Nome e descrição
                </StyledFormTitle>
                <StyledFormDescription variant="body4">
                  Dê um nome ao grupo, este será o principal texto exibido nas
                  listas e nos destaques. Na descrição, você pode descrever um
                  resumo e qual o objetivo do grupo.
                </StyledFormDescription>
                <FieldTitleInput
                  label="Nome do grupo"
                  onChange={(e) => setName(e.target.value)}
                />
                <FieldDescriptionInput
                  label="Descrição do grupo"
                  onChange={(e) => setDescription(e.target.value)}
                  inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                  multiline
                  rows={4}
                  inputMode="text"
                />
                <StyledSubtitleContainer>
                  <Typography variant="caption">
                    {`Máximo ${MAX_DESCRIPTION_LENGTH} caracteres`}
                  </Typography>
                  <Typography variant="caption">
                    {`${description.length}/${MAX_DESCRIPTION_LENGTH}`}
                  </Typography>
                </StyledSubtitleContainer>
              </FormWrapper>
              <TypeSelectionWrapper>
                <StyledFormTitle variant="headline8">
                  Escolha a forma de associar pessoas a esse grupo
                </StyledFormTitle>
                <StyledDescription variant="body3">
                  Selecione o tipo do grupo.
                </StyledDescription>
                <TypeSelectionCard>
                  <Radio
                    checked={groupType === GROUP_TYPES.DYNAMIC}
                    onChange={() => cardOnClickHandler(GROUP_TYPES.DYNAMIC)}
                  />
                  <TypeDescriptionContainer>
                    <TypeSelectionTitle variant="body3" weight={600}>
                      Atribuição dinâmica
                    </TypeSelectionTitle>
                    <TypeSelectionDescription variant="body4" weight={400}>
                      Selecione filtros com atributos de cadastro e associaremos
                      todas as pessoas que corresponderem à sua seleção. Sempre
                      que um cadastro for criado ou alterado, ele
                      automaticamente será associado ao grupo correspondente.
                      Melhor opção para quem quer praticidade e uma lista de
                      pessoas sempre atualizada.
                    </TypeSelectionDescription>
                  </TypeDescriptionContainer>
                </TypeSelectionCard>
                <TypeSelectionCard isLastCard={true}>
                  <Radio
                    checked={groupType === GROUP_TYPES.STATIC}
                    onChange={() => cardOnClickHandler(GROUP_TYPES.STATIC)}
                  />
                  <TypeDescriptionContainer>
                    <TypeSelectionTitle variant="body3" weight={600}>
                      Atribuição manual
                    </TypeSelectionTitle>
                    <TypeSelectionDescription variant="body4" weight={400}>
                      Selecione pessoas de uma lista fixa. Melhor opção para
                      quem deseja mais controle: sua lista não sofre alterações
                      a não ser que você a modifique manualmente.
                    </TypeSelectionDescription>
                  </TypeDescriptionContainer>
                </TypeSelectionCard>
              </TypeSelectionWrapper>
            </FormContainer>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={!!!name.length || groupType === undefined}
        name={name}
        description={description}
        isDynamicGroup={groupType === GROUP_TYPES.DYNAMIC}
      />
    </Body>
  );
};

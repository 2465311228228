import styled from "styled-components";

import { HighlightProps } from ".";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div<Omit<HighlightProps, "icon">>(
  ({ theme, orientation }) => {
    const isVertical = orientation === "vertical";

    return {
      width: "fit-content",
      height: "fit-content",
      display: "flex",
      flexDirection: "row",
      gap: theme.spacings.xs5,
      alignItems: "center",
      justifyContent: "center",

      ...(isVertical && {
        flexDirection: "column",
        gap: theme.spacings.xs4,
      }),
    };
  },
);

export const Text = styled(Typography)<{ status: HighlightProps["status"] }>(
  ({ theme, status }) => {
    const getColor = () => {
      switch (status) {
        case "info":
        case "success":
        case "error":
        case "progress":
        case "negative":
          return theme.colors.feedback[status][40];
        case "neutral":
          return theme.colors.neutral[40];
        case "disabled":
          return theme.colors.neutral[70];
        case "transparent":
          return "transparent";
        default:
          return theme.colors.secondary[50];
      }
    };

    return {
      color: getColor(),
    };
  },
);

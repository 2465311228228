import { useNavigate } from "react-router-dom";

import { EmptyCostCenterStep1SVG, EmptyCostCenterStep2SVG } from "@/assets";
import { EmptyView } from "@/components";
import { StepImage } from "@/components/EmptyView/StepImage";
import { routes } from "@/routes";

import * as SC from "./styled";

export const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <SC.Container>
      <EmptyView
        header={{
          title: "Cadastre os centros de custo da sua empresa",
          highlight: "Comece!",
        }}
        button={{
          name: "Crie seus centros de custo",
          icon: "IconPlus",
          onClick: () => navigate(routes.registerCostCenter()),
        }}
        footer={{
          linkFaq: "https://faq.flashapp.com.br/",
        }}
      >
        <StepImage
          image={<EmptyCostCenterStep1SVG />}
          title="1. Realize o cadastro"
          description="Crie o centro de custo preenchendo as informações."
        />
        <StepImage
          image={<EmptyCostCenterStep2SVG />}
          title="2. Selecione os integrantes"
          description="Defina quem serão as pessoas que pertencerão ao centro de custo."
        />
      </EmptyView>
    </SC.Container>
  );
};

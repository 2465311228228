import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { TableWrapper } from "./styled";

export const TableSkeleton = () => {
  return (
    <TableWrapper>
      <Skeleton
        variant="rectangular"
        style={{
          marginBottom: "0px",
          borderRadius: "20px",
          height: "560px",
          display: "flex",
          flexGrow: 1,
        }}
      />
    </TableWrapper>
  );
};

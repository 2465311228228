import { useEffect, useState } from "react";
import { CheckboxFilter, Spinner } from "@flash-tecnologia/hros-web-ui-v2";
import { CheckboxItem } from "@flash-tecnologia/hros-web-ui-v2/dist/components/CheckboxFilter/CheckboxItem/CheckboxItem";
import { CostCenterForSelect } from "bff/src/routers/costCenters/types";

import * as SC from "./styled";

type Props = {
  isLoading: boolean;
  costCenters: CostCenterForSelect[];
  selected?: string[];
  onChange: (data: string[]) => void;
};

type Option = Omit<React.ComponentProps<typeof CheckboxItem>, "key">;

export const CostCentersFilters = ({
  costCenters,
  isLoading,
  selected,
  onChange,
}: Props) => {
  const [options, setOptions] = useState<Option[]>([]);

  function handleSelectAll(checked: boolean) {
    const selectedOptions = [...options].map((option) => ({
      ...option,
      checked,
    }));
    setOptions(selectedOptions);
    onChange(checked ? selectedOptions?.map((employee) => employee.value) : []);
  }

  function handleOnChange(value: string, checked: boolean) {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) =>
        option.value === value ? { ...option, checked } : option,
      );

      onChange(
        updatedOptions
          .filter((option) => option.checked)
          .map((option) => option.value),
      );

      return updatedOptions;
    });
  }

  const getSubtitleEmployeesFilter = () => {
    let subtitle = "";

    if (options.length) {
      subtitle = options
        .map((e) => e.label)
        .slice(0, 3)
        .join(", ");
      options.length > 3 && (subtitle = `${subtitle} +${options.length - 3}`);
    }

    return subtitle;
  };

  useEffect(() => {
    if (!isLoading && costCenters.length)
      setOptions(
        costCenters.map((c) => ({
          label: c.name,
          value: c.id,
          onChange: (value: string, checked: boolean) =>
            handleOnChange(value, checked),
          checked: selected?.length
            ? selected?.some((id) => c.id === id)
            : false,
        })),
      );
  }, [costCenters, isLoading, selected]);

  return (
    <SC.ContainerEmployeesFilter>
      {isLoading ? (
        <Spinner size={24} />
      ) : (
        <CheckboxFilter
          onAllSelection={handleSelectAll}
          options={options}
          title="Centros de custos"
          subtitle={getSubtitleEmployeesFilter()}
        />
      )}
    </SC.ContainerEmployeesFilter>
  );
};

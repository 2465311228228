import { useState } from "react";
import { Dropzone, IconButton, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { uploadHelper } from "@flash-tecnologia/hros-web-utility";
import dispatchToast from "@Utils/dispatchToast";

import { useSession } from "@/common/user";

interface DropzoneFile {
  id: string;
  data?: string | ArrayBuffer | null | undefined;
  progress: number;
  loaded: boolean;
  error: boolean;
  file: File;
  mime?: string;
}

import {
  ContentContainer,
  ContentTextWrapper,
  ContentTitle,
  FooterCancelButton,
  FooterContainer,
  HeaderContainer,
  HeaderDescription,
  HeaderTextWrapper,
  HeaderTitle,
  StyledModal,
  SubmitButton,
} from "./styled";

type ModalEditProfilePictureProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (input: any) => void;
  loading: boolean;
};

export const ModalEditProfilePicture = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
}: ModalEditProfilePictureProps) => {
  const [droppedFile, setDroppedFile] = useState<
    React.ComponentProps<typeof Dropzone>["initialFiles"] | undefined
  >(undefined);
  const { companyId } = useSession();

  const filename = `${companyId}/logo.${new Date().toISOString()}.${
    droppedFile?.[0]?.mime
  }`;

  const uploadProfilePic = async () => {
    try {
      const upload = await uploadHelper({
        key: filename,
        file: droppedFile?.[0]?.file!,
        fileName: filename,
        module: "employee-pii",
      });

      await onSubmit({
        path: upload.path,
        key: upload.key,
        // type: "internal",
      });

      onClose();
    } catch {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro ao atualizar a logo.",
        type: "error",
      });
    }
  };

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      header={
        <HeaderContainer>
          <HeaderTextWrapper>
            <HeaderTitle variant="headline6">Enviar logo</HeaderTitle>
            <HeaderDescription variant="body4">
              Escolha um logo recente e com imagem de boa qualidade. Nós
              aceitamos os arquivos que estão nos formatos PNG, SVG ou JPEG até
              5MB.
            </HeaderDescription>
          </HeaderTextWrapper>
          <IconButton
            variant="line"
            size="small"
            icon="IconX"
            onClick={onClose}
          />
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          <FooterCancelButton onClick={onClose}>
            <Icons name="IconArrowLeft" fill="transparent" size={24} />
            <span>Voltar</span>
          </FooterCancelButton>
          <SubmitButton
            variant={"primary"}
            buttonType={"primary"}
            size={"small"}
            style={{ margin: "auto 0", padding: "19px 52px" }}
            onClick={() => uploadProfilePic()}
            loading={loading}
            disabled={!!!droppedFile?.length}
            disableRipple
            disableTouchRipple
          >
            <span>Salvar nova foto</span>
            <Icons name="IconPencil" fill="transparent" size={32} />
          </SubmitButton>
        </FooterContainer>
      }
    >
      <ContentContainer>
        <ContentTextWrapper>
          <ContentTitle variant="headline8">Adicionar logo</ContentTitle>
        </ContentTextWrapper>
        <Dropzone
          title="Sua foto"
          accept={["png", "jpg", "jpeg"]}
          onChange={(e) => setDroppedFile(e)}
        />
      </ContentContainer>
    </StyledModal>
  );
};

import { trpc } from "@/api/client";
import { useSession } from "@/common/user";

type EmployeeStatus =
  | "INVITATION_SCHEDULED"
  | "INVITATION_PENDING"
  | "INVITATION_SENT"
  | "INVITATION_EXPIRED"
  | "ACTIVE"
  | "INACTIVE"
  | "DELETED";

export const useGetEmployeesForSelect = (
  justStatus?: EmployeeStatus[],
  companyId?: string,
) => {
  const { companyId: companyIdSession } = useSession();
  const { data, isLoading, refetch } = trpc.getEmployeesForSelect.useQuery({
    companyId: companyId ?? companyIdSession,
    justStatus: justStatus ?? [],
  });

  return {
    data: data ?? [],
    isLoading,
    refetch,
  };
};

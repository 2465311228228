import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, tableControllers } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { EmployeesToLinkCostCenter } from "bff/src/routers/costCenters/types";
import { EmployeeForSelect } from "bff/src/routers/employees/types";
import { CreateCostCenterInput } from "bff/src/services/platform-entities.service";
import { useFormikContext } from "formik";
import { useDebounce } from "use-debounce";

import { ListSwitcher } from "@/components/ListSwitcher";
import { useGetEmployeesToLinkCostCenter } from "@/hooks/CostCenters/useGetEmployeesToLinkCostCenter";
import { CostCentersContext } from "@/shared/context/CostCentersContext";
import { useSelectedTable } from "@/shared/hooks/table/use-selected-table";

import { useOptionsSelected } from "./hooks/useOptionsSelected";
import * as SC from "./styled";
import { TableColumns } from "./TableColumnsHelper";

export const LinkPeoplesCostCenters = () => {
  const { costCenterId } = useParams();
  const { employees } = useContext(CostCentersContext);
  const form = useFormikContext<
    Omit<CreateCostCenterInput, "companyId"> & { membersEmployeeIds?: string[] }
  >();
  const [{ pageNumber, pageSize }, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [inputSearch, setInputSearch] = useState<string>("");
  const [search] = useDebounce(inputSearch, 250);
  const {
    data: employeesToLink,
    total,
    isLoading,
  } = useGetEmployeesToLinkCostCenter({
    page: pageNumber,
    limit: pageSize,
    search,
    costCenterId,
  });

  const table = tableControllers.useTableColumns<EmployeesToLinkCostCenter>({
    total: total,
    columns: TableColumns(),
    data: employeesToLink,
    pagination: { pageNumber, pageSize },
    onPaginationChange: setPagination,
    options: {
      selectable: true,
    },
  });

  const { selectedIds } = useSelectedTable<EmployeesToLinkCostCenter>({
    selectedItems: form.values.membersEmployeeIds ?? [],
    pageNumber,
    pageSize,
    rows: table.rows,
    allSelected: table.selected.allSelected,
    selected: table.selected.selected,
    setSelectedTableRows: table.setSelectedRows,
    resetSelected: table.resetSelected,
  });

  const selectedOptions = useOptionsSelected<EmployeeForSelect>({
    selectedIds: selectedIds ?? [],
    items: employees?.data || [],
  });

  const deleteSelectedId = (id: string) => {
    form.setFieldValue(
      "membersEmployeeIds",
      selectedIds.filter((selectedId) => selectedId !== id),
    );
  };

  useEffect(() => {
    form.setFieldValue("membersEmployeeIds", selectedIds);
  }, [selectedIds]);

  return (
    <ListSwitcher
      isLoading={isLoading && employeesToLink.length === 0}
      selectedOptions={selectedOptions}
      onDelete={deleteSelectedId}
    >
      <SC.Container>
        <Table.Root variant="soft">
          <Table.Content>
            <Table.FilterSearch
              onSearch={(data) => {
                setPagination({
                  pageNumber: 1,
                  pageSize,
                });
                setInputSearch(data.target?.value ?? null);
              }}
              labelSearch="Buscar por nome ou CPF"
            ></Table.FilterSearch>

            <Table.Grid.Root loading={isLoading}>
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table.rows.map((row, index) => (
                <Table.Grid.Row key={index + row.id} row={row} />
              ))}
            </Table.Grid.Root>

            <Table.Pagination
              count={total}
              onPaginationChange={({ pageSize, pageNumber }) => {
                const changingPageSize = table.pagination.pageSize !== pageSize;
                setPagination({
                  pageSize,
                  pageNumber: changingPageSize ? 1 : pageNumber,
                });
              }}
              showItemRange
              pagination={{ pageSize, pageNumber }}
            />
          </Table.Content>
        </Table.Root>
      </SC.Container>
    </ListSwitcher>
  );
};

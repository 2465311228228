import styled from "styled-components";

export const OptionItemInput = styled.input`
  all: unset;
  position: absolute;
  inset: 0;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral30};
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

export const OptionItemIconWrapper = styled.div`
  visibility: hidden;
  margin-left: auto;
`;

export const OptionItemCustomIconWrapper = styled.div``;

export const OptionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  gap: 12px;
  svg {
    color: ${({ theme }) => theme.colors.neutral30};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral95};
  }
  &:has(${OptionItemInput}:checked) {
    background-color: ${({ theme }) => theme.colors.secondary95};
  }
  &:has(${OptionItemInput}:checked) ${Label} {
    color: ${({ theme }) => theme.colors.secondary40};
  }
  &:has(${OptionItemInput}:checked) ${OptionItemIconWrapper} {
    visibility: visible;
  }

  &:has(${OptionItemInput}:checked) {
    svg {
      color: ${({ theme }) => theme.colors.secondary40};
    }
  }
  &:has(${OptionItemInput}:focus) {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
    outline-offset: -2px;
  }

  &:has(${OptionItemInput}:disabled) {
    background-color: ${({ theme }) => theme.colors.neutral90};
    cursor: not-allowed;
    svg {
      color: ${({ theme }) => theme.colors.neutral70};
    }
  }
  &:has(${OptionItemInput}:disabled) ${Label} {
    color: ${({ theme }) => theme.colors.neutral70};
  }
`;

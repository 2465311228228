import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Icons,
  LinkButton,
  PDFViewer,
  Stepper,
} from "@flash-tecnologia/hros-web-ui-v2";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import { useMutation } from "@apollo/react-hooks";
import { useGetEconomicGroupCompanies } from "@features/economicGroup/hooks";

import { AddCompanyIntoEconomicGroup } from "@/api/mutations/__generated__/AddCompanyIntoEconomicGroup";
import { ADD_COMPANY_TO_GROUP } from "@/api/mutations/addCompanyToGroup";
import { Typography } from "@/components";
import {
  CheckboxLabel,
  CheckboxWrapper,
} from "@/components/FieldComponent/styled";
import { useCompany } from "@/features/economicGroup/hooks/use-company";
import { routes } from "@/routes";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { updateNewCompanyPermissions } from "@/utils/update-new-company-permissions";

import * as Styled from "./styles";
import { useAddCompanyTerm } from "@/hooks";

export const PageAddCompanyContract = () => {
  const [hasAgreed, setHasAgreed] = useState(false);
  const { company } = useCompany();
  const [pdf, setPdf] = useState("");
  const [termId, setTermId] = useState("");
  const navigate = useNavigate();

  const { addCompanyTerm } = useAddCompanyTerm();
  const { mainCompany, isLoading } = useGetEconomicGroupCompanies();

  const permissions = usePermissions();

  const [addCompanyToGroup, { data, loading }] =
    useMutation<AddCompanyIntoEconomicGroup>(ADD_COMPANY_TO_GROUP, {
      onCompleted: () => {
        dispatchToast({
          type: "success",
          content: "Empresa criada com sucesso!",
        });

        const companies = data?.addCompanyIntoEconomicGroup?.companies;
        updateNewCompanyPermissions(permissions, companies as any);

        navigate(routes.pageInitial);
      },
      onError: (error) => {
        const graphqlErrors: any = error.graphQLErrors?.[0];
        const errorCode = graphqlErrors?.errorCode as string;
        if (
          errorCode === "COMPANY_WITH_REGISTRATION_NUMBER_ALREADY_EXISTS_ERROR"
        ) {
          dispatchToast({
            type: "error",
            content:
              "Já existe uma empresa com esse CNPJ. Por favor, verifique os dados e tente novamente.",
          });
        } else {
          ErrorBoundary.captureException(error);
          dispatchToast({
            type: "error",
            content:
              "Ocorreu um erro ao criar a empresa. Por favor, tente novamente mais tarde.",
          });
        }
      },
    });

  const createCompany = async () => {
    dispatchToast({
      type: "warning",
      content: "Criando empresa...",
    });

    await addCompanyToGroup({
      variables: {
        input: {
          companies: [company],
          termId,
        },
      },
    });

    navigate(routes.economicGroup);
  };

  useEffect(() => {
    if (isLoading || !company || !mainCompany) return;

    const getContract = async () => {
      const { id, url } = await addCompanyTerm({
        mainCompany: {
          id: mainCompany.id,
          economicGroupId: mainCompany.economicGroupId,
          registrationNumber: mainCompany.registrationNumber,
          legalName: mainCompany.legalName,
          address: {
            ...mainCompany.address,
            complement: mainCompany.address.complement || undefined,
            number: mainCompany.address.number.toString(),
          },
        },
        companies: [
          {
            registrationNumber: company.registrationNumber,
            legalName: company.legalName,
            address: {
              ...company.address,
              complement: company.address.complement || undefined,
              number: company.address.number.toString(),
            },
          },
        ],
      });

      setPdf(url);
      setTermId(id);
    };

    getContract();
  }, [isLoading, company, mainCompany]);

  return (
    <Styled.MainContainer>
      <Styled.HeaderRow>
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={[
            <LinkButton
              variant="neutral"
              onClick={() =>
                navigate(routes.economicGroup, { state: { tab: 0 } })
              }
            >
              Configurações
            </LinkButton>,
            <LinkButton variant="neutral">Adicionar via plataforma</LinkButton>,
          ]}
        />
        <Stepper
          steps={["Dados da empresa", "Assinatura de declaração"]}
          activeStep={1}
        />
      </Styled.HeaderRow>
      <Styled.Container>
        <Styled.TitleText
          variant="headline6"
          children="Adicionar via plataforma"
        />
        <Styled.ContentWrapper>
          <Styled.Column>
            <Styled.Spacer height={40} />
            <Styled.BoldTypography
              variant="headline7"
              style={{ color: "#F20D7A" }}
            >
              Assinatura da declaração
            </Styled.BoldTypography>
            <Styled.Spacer height={16} />
            <Typography variant="body3" color="neutral.50">
              Leia e concorde com a declaração para continuar.
            </Typography>
          </Styled.Column>
          <Styled.Spacer width={96} />
          <Styled.ContractContainer>
            <Styled.Spacer height={40} />
            {!pdf && (
              <Styled.LoadingWrapper>
                <CircularProgress variant="indeterminate" size={60} />
              </Styled.LoadingWrapper>
            )}
            {pdf && (
              <PDFViewer
                height={"400px"}
                width="100%"
                src={pdf}
                options={{
                  showToolbar: true,
                  fit: "height",
                  page: 1,
                }}
              />
            )}
            <CheckboxWrapper>
              <Checkbox onChange={(e) => setHasAgreed(e.target.checked)} />
              <CheckboxLabel variant="body3">
                Declaro que li e concordo com o contrato acima.
              </CheckboxLabel>
            </CheckboxWrapper>
          </Styled.ContractContainer>
        </Styled.ContentWrapper>
      </Styled.Container>
      <Styled.Footer>
        <LinkButton variant="neutral" style={{ alignSelf: "center" }}>
          Cancelar
        </LinkButton>
        <Styled.Row>
          <Button
            onClick={() => navigate(routes.pageAddCompany)}
            variant="secondary"
            size="small"
          >
            <Icons name="IconArrowLeft" />
            Voltar
          </Button>
          <Styled.Spacer width={24} />
          <Button
            disabled={!pdf || !hasAgreed || loading}
            onClick={() => createCompany()}
            variant="primary"
            size="small"
          >
            Continuar <Icons name="IconArrowRight" />
          </Button>
        </Styled.Row>
      </Styled.Footer>
    </Styled.MainContainer>
  );
};

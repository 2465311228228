import { useMemo, useState } from "react";
import {
  SearchField,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "./styled";

type Option = {
  value: string;
  label: string;
};

export type SelectedListProps = {
  title?: string;
  options: Option[];
  isLoading: boolean;
  onDelete: (data: Option["value"]) => void;
};

export const SelectedList = ({
  title,
  options = [],
  isLoading,
  onDelete,
}: SelectedListProps) => {
  const [search, setSearch] = useState<string>("");
  const filteredOptions = useMemo(() => {
    if (!search) return options;

    return options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, options]);

  return (
    <SC.Container>
      <SC.Header>
        <SC.ContainerTitle>
          <Typography variant="body4" weight={600}>
            {title ?? options.length !== 1
              ? "Pessoas selecionadas"
              : "Pessoa selecionada"}
          </Typography>
          <SC.QuantityOfItemsTag
            size="extra-small"
            sx={{ cursor: "not-allowed", pointerEvents: "none" }}
            variant="gray"
          >
            {options?.length ?? 0}
          </SC.QuantityOfItemsTag>
        </SC.ContainerTitle>
        <SearchField
          label="Buscar"
          inputSize="sm"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SC.Header>
      <SC.Content>
        {isLoading && filteredOptions.length
          ? new Array(4).fill({}).map((_, i) => (
              <SC.Item key={i}>
                <Skeleton
                  variant="rounded"
                  style={{ maxWidth: "250px", width: "100%" }}
                  height={20}
                  width={455}
                />
              </SC.Item>
            ))
          : filteredOptions.map((option) => (
              <SC.Item key={option.value}>
                <SC.ItemText>{option.label}</SC.ItemText>
                <SC.IconTrash onClick={() => onDelete(option.value)} />
              </SC.Item>
            ))}
      </SC.Content>
    </SC.Container>
  );
};

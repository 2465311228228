import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacings.xs,
  width: "fill-available",
  backgroundColor: theme.colors.neutral[100],
  position: "fixed",
  minHeight: "56px",
  top: "0px",
  zIndex: 998,
  marginLeft: "-24px",
  padding: `${theme.spacings.xs4} ${theme.spacings.l}`,
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));

import { useContext, useEffect, useState } from "react";
import {
  Badge,
  PillButton,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { EmployeeLinkedCostCenter } from "bff/src/routers/costCenters/types";
import { useDebounce } from "use-debounce";

import { CostCentersContext } from "@/shared/context/CostCentersContext";

import { FiltersTables } from "./FiltersTables";
import { TableColumns } from "./TableColumnsHelper";

export const MemberTable = () => {
  const { employeesLinked, employeesLinkedFilters, setEmployeesLinkedFilters } =
    useContext(CostCentersContext);
  const [openFilters, setOpenFilters] = useState(false);
  const table = tableControllers.useTableColumns<EmployeeLinkedCostCenter>({
    total: employeesLinked?.total,
    columns: TableColumns(),
    data: employeesLinked?.data ?? [],
    pagination: {
      pageNumber: employeesLinkedFilters.page,
      pageSize: employeesLinkedFilters.limit,
    },
    onPaginationChange: (data) =>
      setEmployeesLinkedFilters((prev) => ({
        ...prev,
        limit: data.pageSize,
        page: data.pageNumber,
      })),
  });
  const [inputSearch, setInputSearch] = useState<string>("");
  const [search] = useDebounce(inputSearch, 250);

  const getNumberAppliedFilters = () => {
    let amount = 0;
    employeesLinkedFilters.costCenterActive !== undefined && (amount += 1);
    employeesLinkedFilters.costCenterIds?.length && (amount += 1);
    return amount;
  };

  useEffect(() => {
    setEmployeesLinkedFilters((prev) => ({ ...prev, page: 1, search }));
  }, [search]);

  return (
    <>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch
            onSearch={(data) => {
              setInputSearch(data.target?.value ?? "");
            }}
            labelSearch="Buscar por nome"
          >
            <Badge
              content={getNumberAppliedFilters()}
              color="brand"
              variant="primary"
              size="lg"
            >
              <PillButton
                variant={getNumberAppliedFilters() > 0 ? "pink" : "default"}
                icon="IconFilter"
                iconPosition="left"
                label="Filtros"
                onClick={() => setOpenFilters(true)}
                size="small"
                type="primary"
              />
            </Badge>
          </Table.FilterSearch>

          <Table.Grid.Root loading={employeesLinked?.isLoading}>
            <Table.Grid.Header
              getHeaderGroups={table.getHeaderGroups}
              toggleAllRowsExpanded={table.toggleAllRowsExpanded}
            />

            {table.rows.map((row, index) => (
              <Table.Grid.Row key={index + row.id} row={row} />
            ))}
          </Table.Grid.Root>

          <Table.Pagination
            count={employeesLinked?.total ?? 0}
            onPaginationChange={({ pageSize, pageNumber }) => {
              setEmployeesLinkedFilters((prev) => ({
                ...prev,
                limit: pageSize,
                page: pageNumber,
              }));
            }}
            showItemRange
            pagination={{
              pageNumber: employeesLinkedFilters.page,
              pageSize: employeesLinkedFilters.limit,
            }}
          />
        </Table.Content>
      </Table.Root>
      <FiltersTables
        open={openFilters}
        onApply={(data) =>
          setEmployeesLinkedFilters((prev) => ({ ...prev, page: 1, ...data }))
        }
        onClose={() => setOpenFilters(!openFilters)}
      />
    </>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Divider,
  Icons,
  IconsProps,
  LinkButton,
  SearchField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Option, OptionProps } from "../item";

import {
  CustomExtraLinkControlItemContainer,
  CustomFooterControlItemContainer,
  Footer,
  LoadingContainer,
  OptionItemCustomIconWrapper,
  OptionItemLabel,
  OptionsContainer,
  SearchFieldContainer,
} from "./styled";

type SelectVariants = "default" | "error" | "disabled";

type FooterItemProps = {
  label: string;
  icon?: IconsProps["name"];
  iconProps?: IconsProps;
  centralize?: boolean;
  variant: SelectVariants;
  onClick?: () => Promise<void> | void;
};

export type SelectProps = {
  options: Omit<OptionProps, "name">[];
  name: string;
  searchable?: boolean;
  onEndScrollOptions?: () => Promise<void>;
  footerActions?: FooterItemProps[];
  extraLinksActions?: FooterItemProps[];
  onChange?: (e: React.MouseEvent<HTMLInputElement>) => void | Promise<void>;
  onClear?: () => void;
  onSearch?: (value: string) => Promise<Omit<OptionProps, "name">[]>;
  searchNoResultsMessage?: string;
};

export const useDebounce = (value: string, milliSeconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};

export const SingleSelectDropdown = ({
  options,
  name,
  searchable = true,
  onEndScrollOptions,
  footerActions,
  extraLinksActions,
  onChange,
  onClear,
  searchNoResultsMessage,
}: SelectProps) => {
  const [optionsView, setOptionsView] = useState<
    Omit<OptionProps, "name">[] | null
  >(options);
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [scrolling, setScrolling] = useState({
    calledEndScroll: false,
    lastScrollTop: 0,
  });
  const listItemsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (query) setOptionsView(options?.filter((o) => o.label.includes(query)));
  }, [query]);

  useEffect(() => {
    if (options) setOptionsView(options);
  }, [options]);

  const handlePopperScroll = useCallback(async () => {
    if (listItemsRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = listItemsRef.current;
      const isScrollingDown = scrollTop > scrolling.lastScrollTop;
      if (isScrollingDown)
        setScrolling({ ...scrolling, lastScrollTop: scrollTop });
      if (
        scrollTop + clientHeight >= scrollHeight * 0.8 &&
        isScrollingDown &&
        !scrolling.calledEndScroll
      ) {
        setScrolling({ ...scrolling, calledEndScroll: true });
        setLoading(true);
        await onEndScrollOptions?.();
        setLoading(false);
      }
    }
  }, [onEndScrollOptions, scrolling]);

  const handleClearSelectedOption = useCallback(() => {
    if (listItemsRef.current) {
      const selectedOption =
        listItemsRef.current.querySelector("input:checked");
      if (selectedOption) {
        (selectedOption as HTMLInputElement).checked = false;
      }
      listItemsRef.current.scrollTop = 0;
      onClear?.();
    }
    if (query) setQuery("");
    setScrolling({ calledEndScroll: false, lastScrollTop: 0 });
  }, [onClear, query]);

  const handleSelectOption = useCallback(
    async (
      e: React.MouseEvent<HTMLInputElement>,
      onClick?: (e: React.MouseEvent<HTMLInputElement>) => void | Promise<void>,
    ) => {
      await onClick?.(e);
      await onChange?.(e);
      setScrolling({ calledEndScroll: false, lastScrollTop: 0 });
    },
    [onChange],
  );

  return (
    <>
      {searchable && (
        <SearchFieldContainer>
          <SearchField
            label="Buscar"
            onInputChange={(_, value, reason) => {
              if (reason === "clear") {
                setQuery("");
                setLoading(false);
                setOptionsView(options);
              } else {
                value && setQuery(value);
              }
            }}
            clearOnBlur
            clearOnEscape
          />
        </SearchFieldContainer>
      )}
      <OptionsContainer onScroll={handlePopperScroll} ref={listItemsRef}>
        {Array.isArray(optionsView) &&
          optionsView.length === 0 &&
          searchNoResultsMessage && (
            <Option
              label={searchNoResultsMessage}
              value=""
              name={name}
              disabled
            />
          )}
        {Array.isArray(optionsView) &&
          optionsView.map((option) => (
            <Option
              {...option}
              name={name}
              key={`${option.label}-${option.value}`}
              onClick={(e) => handleSelectOption(e, option.onClick)}
            />
          ))}
        {loading && (
          <LoadingContainer>
            <CircularProgress
              size={36}
              variant="indeterminate"
              value={0}
              thickness={4}
            />
          </LoadingContainer>
        )}
      </OptionsContainer>
      {extraLinksActions && (
        <Footer>
          <Divider orientation="horizontal" />
          {extraLinksActions.map((action) => (
            <CustomExtraLinkControlItemContainer
              $centralize={action.centralize}
              onClick={action?.onClick}
              key={action.label}
            >
              <LinkButton variant="default" onClick={handleClearSelectedOption}>
                {action.icon && (
                  <Icons name={action.icon} size={24} strokeWidth="2.5" />
                )}
                {action.label}
              </LinkButton>
            </CustomExtraLinkControlItemContainer>
          ))}
        </Footer>
      )}
      {footerActions && (
        <Footer>
          <Divider orientation="horizontal" />
          {footerActions.map((action) => (
            <CustomFooterControlItemContainer
              key={action.label}
              $centralize={action.centralize}
              onClick={action?.onClick}
            >
              {action.icon && (
                <OptionItemCustomIconWrapper>
                  <Icons name={action.icon} size={24} strokeWidth="2.5" />
                </OptionItemCustomIconWrapper>
              )}
              <OptionItemLabel>{action.label}</OptionItemLabel>
            </CustomFooterControlItemContainer>
          ))}
        </Footer>
      )}
    </>
  );
};

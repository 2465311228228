import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { DEPARTMENT_CREATE_PAGE } from "@departments/events";
import { DepartmentFormStep } from "@departments/types";
import { usePageTracking } from "@Utils/usePageTracking";
import {
  Department,
  Employee,
} from "bff/src/services/company-management.service";
import { routes } from "src/routes";

import { useSession } from "@/common/user";
import { useCreateDepartment, useInsertEmployeeInDepartment } from "@/hooks";

import {
  BasicInformation as FirstStep,
  CreateTransferList as SecondStep,
  Footer,
  Header,
  HeaderBreadcrumbs,
} from "./components";
import { Body, Container, ContentWrapper } from "./styled";

export const DepartmentsManagementPage = () => {
  usePageTracking(DEPARTMENT_CREATE_PAGE);
  const navigate = useNavigate();
  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.departments,
    },
    { label: "Criar departamento" },
  ];
  const pageTitle = "Criar Departamento";

  const { companyId } = useSession();
  const [name, setName] = useState("");
  const [externalId, setExternalId] = useState("");
  const [description, setDescription] = useState("");
  const [department, setDepartment] = useState<Department>();
  const [activeStep, setActiveStep] = useState(DepartmentFormStep.FIRST);
  const [employeesToInsert, setEmployeesToInsert] = useState<Employee[]>([]);

  const { isLoading, mutateAsync: createDepartment } = useCreateDepartment();
  const { insertEmployeeInDepartment } = useInsertEmployeeInDepartment();

  const handleCreateGroup = async () => {
    const createdDepartment = await createDepartment({
      companyId,
      ...(description && { description }),
      ...(externalId && { externalId }),
      name,
    });
    setDepartment(createdDepartment);
  };

  const handleInsertEmployeeInDepartment = () => {
    if (department) {
      employeesToInsert.forEach((e) => {
        insertEmployeeInDepartment({
          departmentId: department.id,
          employeeId: e.id,
        });
      });
      navigate(routes.departments);
    }
  };

  return (
    <Body>
      <Header
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper>
        <PageContainer>
          <Container>
            {activeStep === DepartmentFormStep.FIRST && (
              <FirstStep
                title={pageTitle}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                externalId={externalId}
                setExternalId={setExternalId}
              />
            )}
            {activeStep === DepartmentFormStep.SECOND && !isLoading && (
              <SecondStep
                employeesToInsert={employeesToInsert}
                setEmployeesToInsert={setEmployeesToInsert}
              />
            )}
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        loading={isLoading}
        disableForwardFirstStep={!name}
        disableForwardSecondStep={!employeesToInsert.length}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleCreateGroup={handleCreateGroup}
        handleInsertEmployeeInDepartment={handleInsertEmployeeInDepartment}
      />
    </Body>
  );
};

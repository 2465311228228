import { useNavigate } from "react-router-dom";
import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";

import { FooterContainer } from "./styled";

type FooterProps = {
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
};

const Footer = ({
  onButtonClick,
  buttonDisabled,
  buttonLoading,
}: FooterProps) => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <LinkButton
        onClick={() => navigate(routes.pageCustomFields)}
        variant="neutral"
      >
        Cancelar
      </LinkButton>
      <Button
        onClick={onButtonClick}
        variant="primary"
        variantType="default"
        disabled={buttonDisabled}
        loading={buttonLoading}
      >
        Criar campo <Icons name="IconCheck" />
      </Button>
    </FooterContainer>
  );
};

export { Footer };

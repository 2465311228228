import { useState } from "react";
import {
  PillButton,
  tableControllers,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { EmployeeLinkedCostCenter } from "bff/src/routers/costCenters/types";

import { useUnlinkEmployeeFromCostCenter } from "@/hooks/CostCenters/useUnlinkEmployeeFromCostCenter";
import { TextHeader } from "@/pages/PageCostCenters/styled";

import { MainRowColumn } from "../../MainRowColumn";

import * as SC from "./styled";

export type Columns = Parameters<
  typeof tableControllers.useTableColumns<EmployeeLinkedCostCenter>
>[0]["columns"];

export const TableColumns = (): Columns => {
  const emptyData = "-";
  const { unlinkEmployee } = useUnlinkEmployeeFromCostCenter();

  const [idsUnlinkEmployee, setIdsUnlinkEmployee] = useState<string[]>([]);
  const changeUnlinkEmployee = async (employeeId: string) => {
    setIdsUnlinkEmployee((prev) => [...prev, employeeId]);
    await unlinkEmployee({ employeeId });
    setIdsUnlinkEmployee((prev) => prev.filter((id) => id !== employeeId));
  };

  return [
    {
      header: () => <TextHeader>Pessoa</TextHeader>,
      accessorKey: "name",
      cell: ({ row }) => {
        const cpf = row.original.documentNumber
          ? row.original.documentNumber
              .replace(/\D/g, "")
              .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
          : emptyData;

        return (
          <MainRowColumn
            allowLineWrapping={false}
            text={row.original?.name || emptyData}
            caption={cpf}
            status={row.original?.status}
          />
        );
      },
    },
    {
      header: () => <TextHeader>Cargo</TextHeader>,
      accessorKey: "roles",
      cell: ({ row }) => (
        <MainRowColumn text={row.original.roles?.[0]?.name || emptyData} />
      ),
    },
    {
      header: () => <TextHeader>Departamento</TextHeader>,
      accessorKey: "departments",
      cell: ({ row }) => {
        const data = row.original.departments ?? [];
        const departaments = data
          .slice(0, 2)
          .map((departament) => (
            <SC.DepartamentTag variant="gray">
              {departament.name || emptyData}
            </SC.DepartamentTag>
          ));

        if (data.length > 2)
          departaments.push(
            <Tooltip
              title={data
                .map((d) => d.name)
                .slice(2)
                .join(", ")}
            >
              <SC.DepartamentTag
                sx={{ cursor: "not-allowed", pointerEvents: "none" }}
                variant="gray"
              >
                {`+${data.length - 2}`}
              </SC.DepartamentTag>
            </Tooltip>,
          );

        return (
          <SC.ContainerDepartaments>{departaments}</SC.ContainerDepartaments>
        );
      },
    },
    {
      header: () => <TextHeader>Centro de Custo</TextHeader>,
      accessorKey: "costCenter",
      cell: ({ row }) => (
        <MainRowColumn text={row.original.costCenter?.name || emptyData} />
      ),
    },
    {
      header: () => <TextHeader>Status do centro de custo</TextHeader>,
      accessorKey: "costCenter",
      cell: ({ row }) => (
        <MainRowColumn
          text={row.original.costCenter?.active ? "Ativado" : "Inativo"}
        />
      ),
    },
    {
      header: () => <TextHeader>Ações</TextHeader>,
      sticky: "right",
      accessorKey: "id",
      cell: (context) => {
        const isLoading = idsUnlinkEmployee.some(
          (id) => id === context.row?.original.id,
        );
        return (
          <PillButton
            icon={isLoading ? "IconDots" : "IconTrash"}
            size="small"
            type="secondary"
            loading={isLoading}
            variant="default"
            onClick={() => changeUnlinkEmployee(context.row?.original.id)}
          />
        );
      },
    },
  ];
};

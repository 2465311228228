import styled from "styled-components";
import {
  SelectField,
  TagFilter,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const CaptionContainer = styled.div<{
  textPosition?: "START" | "END";
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ textPosition }) =>
    textPosition === "START" ? "flex-start" : "flex-end"};
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weight: 600;
  width: 100%;
`;

export const AboutContainer = styled.div``;
export const AdditionalInputContainer = styled.div``;
export const ConfigContainer = styled.div``;
export const PreviewContainer = styled.div``;

export const TextInput = styled(TextField)`
  width: 100%;
`;

export const SelectInput = styled(SelectField)`
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600 !important;
`;

export const StyledCaption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-bottom: 20px;
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 10px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-bottom: 4px;
`;

export const PreviewBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 32px;
  margin: 12px 0px 100px 0px;
`;

export const StyledSelectField = styled(SelectField)``;

export const IconContainer = styled.div`
  padding-top: 2px;
`;

import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { FooterContainer } from "./styled";

type FooterProps = {
  onFilterButtonClick?: () => void;
  onClearButtonClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
};

const Footer = ({
  onFilterButtonClick,
  onClearButtonClick,
  buttonDisabled,
  buttonLoading,
}: FooterProps) => {
  return (
    <FooterContainer>
      <LinkButton onClick={onClearButtonClick} variant="neutral">
        Limpar filtros
      </LinkButton>
      <Button
        onClick={onFilterButtonClick}
        variant="primary"
        variantType="default"
        disabled={buttonDisabled}
        loading={buttonLoading}
      >
        Filtrar
      </Button>
    </FooterContainer>
  );
};

export { Footer };

import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  GenericProfilePicture,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import TransferListV2 from "@Components/TransferListV2";

import { useSession } from "@/common/user";
import {
  useFindEmployees,
  useFindEmployeesByGroupId,
  useInsertEmployeeInGroup,
  useRemoveEmployeeFromGroup,
} from "@/hooks";
import { routes } from "@/routes";

import { Footer } from "./Footer";
import { Header } from "./Header";
import {
  Body,
  Container,
  ContentWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
  TransferListArea,
} from "./styled";

export const PageManageGroupMembers = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const { companyId } = useSession();

  const groupName = searchParams.get("groupName");
  const [searchState, setSearchState] = useState("");
  const [listedEmployees, setListedEmployees] = useState<any[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const { insertEmployeeInGroup, isLoading: insertionIsLoading } =
    useInsertEmployeeInGroup();
  const { removeEmployeeFromGroup, isLoading: removalIsLoading } =
    useRemoveEmployeeFromGroup();
  const { data: groupEmployees, isLoading: findGroupEmployeesIsLoading } =
    useFindEmployeesByGroupId({
      groupId: groupId!,
      limit: paginationState.pageSize,
      page: paginationState.pageNumber,
    });

  const {
    data: employees,
    isLoading: findEmployeesIsLoading,
    fetch: refetchEmployees,
  } = useFindEmployees();

  useEffect(() => {
    setListedEmployees(employees?.records || []);
  }, [employees]);

  useEffect(() => {
    setSelectedEmployees(groupEmployees?.records || []);
  }, [groupEmployees]);

  useLayoutEffect(() => {
    const transferListContainer: any = document.getElementsByClassName(
      "transfer-list-filter-search-field-container",
    );
    if (!transferListContainer.length) return;
    Array.from(transferListContainer).forEach((item: any) => {
      if (!item?.childNodes?.length) return;
      item.childNodes[0].style.width = "100%";
    });
  }, []);

  const columns = [
    {
      header: "Nome",
      accessorKey: "name",
      cell: (context: any) => {
        const name = context?.cell?.row?.original?.name;
        const email = context?.cell?.row?.original?.email;
        return (
          <FirstColumnCell>
            <GenericProfilePicture size={40} name={name} />
            <FirstColumnCellData>
              <Typography variant="body4">{name}</Typography>
              {!!email && (
                <EmailWrapper>
                  <EmailIcon name="IconMail" size={16} fill="transparent" />
                  <EmailText variant="caption">{email}</EmailText>
                </EmailWrapper>
              )}
            </FirstColumnCellData>
          </FirstColumnCell>
        );
      },
    },
  ];

  useEffect(() => {
    refetchEmployees({
      companyId,
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      search: searchState,
    });
  }, [searchState, paginationState]);

  const handleUpdateGroupMembers = () => {
    const employeeToInsert =
      selectedEmployees.filter(
        (selectedEmployee) =>
          !groupEmployees?.records.some((e) => e.id === selectedEmployee.id),
      ) || [];

    for (const e of employeeToInsert) {
      insertEmployeeInGroup({ groupId: groupId!, employeeId: e.id });
    }

    const employeesToRemove =
      groupEmployees?.records.filter((e) => {
        return !selectedEmployees.some(
          (selectedEmployee) => selectedEmployee.id === e.id,
        );
      }) || [];
      
    for (const e of employeesToRemove) {
      removeEmployeeFromGroup({ groupId: groupId!, employeeId: e.id! });
    }
    
    navigate(routes.pageGroups());
  };

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Editar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Selecione pela lista
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Escolha quais pessoa você gostaria de adicionar à esse grupo de{" "}
                <strong>{groupName}</strong>
              </StyledDescription>
            </TextWrapper>
            <TransferListArea>
              <TransferListV2
                data={listedEmployees || []}
                columns={columns}
                loading={findEmployeesIsLoading || findGroupEmployeesIsLoading}
                tableTitle="Selecione os integrantes do grupo"
                tableTagLabel={`${employees?.metadata.totalCount || 0} itens`}
                onSearchChange={setSearchState}
                pagination={paginationState}
                onPaginationChange={setPaginationState}
                selected={selectedEmployees}
                setSelected={setSelectedEmployees}
                dataSize={employees?.metadata.totalCount || 0}
              />
            </TransferListArea>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        onConfirm={handleUpdateGroupMembers}
        loading={insertionIsLoading || removalIsLoading}
      />
    </Body>
  );
};

import { Icons, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  height: "100%",
}));

export const Header = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs2,
  padding: theme.spacings.xs2,
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));

export const QuantityOfItemsTag = styled(Tag)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.colors.neutral[90],
    color: "initial",
    border: "none",
    boxShadow: "none",
    cursor: "default",
  },
}));

export const ContainerTitle = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacings.xs5,
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs2,
  padding: theme.spacings.xs2,
  height: "100%",
  overflowX: "auto",
}));

export const Item = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacings.xs3,
  maxWidth: "250px",
  minWidth: "205px",
}));

export const ItemText = styled(Typography).attrs({
  variant: "body4",
  weight: 600,
})(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

export const IconTrash = styled(Icons).attrs({
  name: "IconTrash",
  size: 16,
})(({ theme }) => ({
  transition: "transform 0.1s ease-in-out",
  minWidth: "16px",

  "&:hover": {
    transform: "scale(1.1)",
    cursor: "pointer",
  },
}));

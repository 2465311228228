import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  HeaderPage,
  HeaderPageProps,
} from "@/components/PageLayoutBase/HeaderPage";
import { routes } from "@/routes";

type Props = {
  isEmpty: boolean;
};

export const HeaderCostCenters = ({ isEmpty }: Props) => {
  const navigate = useNavigate();

  const headerData = useMemo(() => {
    let data: HeaderPageProps = {
      title: "Centro de custo",
      routes: [
        {
          label: "Gerenciar empresa",
          route: routes.pageInitial,
          options: { state: { tab: 1 } },
        },
        { label: "Centro de custo" },
      ],
    };

    !isEmpty &&
      (data = {
        ...data,
        caption:
          "Crie os centros de custos da sua empresa e defina quem fará parte deles.",
        button: {
          name: "Criar centro de custo",
          icon: "IconPlus",
          onClick: () => navigate(routes.registerCostCenter()),
        },
      });

    return data;
  }, [isEmpty]);

  return <HeaderPage {...headerData} />;
};

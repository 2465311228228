import { LinkButton, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const MainWrapper = styled.main`
  display: flex;
  justify-content: center;
  padding: 24px;
`;

export const FormCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 758px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const CBOButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const TextFieldInput = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacings.xs3};
  width: 100%;
`;

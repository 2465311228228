import { ReactNode, useContext, useEffect, useState } from "react";
import {
  Badge,
  PillButton,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { CostCenter } from "bff/src/services/platform-entities.service";
import { useDebounce } from "use-debounce";

import { useActivateCostCenter } from "@/hooks/CostCenters/useActivateCostCenter";
import { useInactivateCostCenter } from "@/hooks/CostCenters/useInactivateCostCenter";
import { CostCentersContext } from "@/shared/context/CostCentersContext";

import { ConfirmationModalDeactivation } from "./ConfirmationModalDeactivation";
import { FiltersTables } from "./FiltersTables";
import { TableColumns } from "./TableColumnsHelper";

export const CostCentersTable = () => {
  const { costCenters, constCentersFilters, setConstCentersFilters } =
    useContext(CostCentersContext);
  const [{ pageNumber, pageSize }, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { inactivateCostCenter, isLoading: inactivateCostCenterIsLoading } =
    useInactivateCostCenter();
  const { activateCostCenter } = useActivateCostCenter();
  const [idInactivateChange, setIdInactivateChange] = useState<string>();
  const [idsStatusChange, setIdsStatusChange] = useState<string[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const changeStatus = async (
    costCenterId: string,
    status: "inactivate" | "activate",
  ) => {
    if (status === "inactivate") {
      setIdInactivateChange(costCenterId);
      setOpenConfirmModal(true);
    } else {
      setIdsStatusChange((prev) => [...prev, costCenterId]);
      await activateCostCenter({ costCenterId });
      setIdsStatusChange((prev) => prev.filter((id) => id !== costCenterId));
    }
  };
  const onInactivateCostCenter = async () => {
    await inactivateCostCenter({ costCenterId: idInactivateChange ?? "" });
    setOpenConfirmModal(false);
    setIdInactivateChange("");
  };
  const table = tableControllers.useTableColumns<
    CostCenter & { actions?: ReactNode }
  >({
    total: costCenters?.total,
    columns: TableColumns({
      idsStatusChange,
      changeStatus,
    }),
    data: costCenters?.data ?? [],
    pagination: { pageNumber, pageSize },
    onPaginationChange: setPagination,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [search] = useDebounce(inputSearch, 250);

  const getNumberAppliedFilters = () => {
    let amount = 0;
    constCentersFilters.active !== undefined && (amount += 1);
    constCentersFilters.responsibleEmployeeIds &&
      constCentersFilters.responsibleEmployeeIds.length > 0 &&
      (amount += 1);
    return amount;
  };

  useEffect(() => {
    setConstCentersFilters({
      ...constCentersFilters,
      search,
      skip: pageSize * (pageNumber - 1),
      limit: pageSize,
    });
  }, [search, pageSize, pageNumber]);

  return (
    <>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch
            onSearch={(data) => {
              setInputSearch(data.target?.value ?? "");
              data.target?.value && setPagination({ pageSize, pageNumber: 1 });
            }}
            labelSearch="Buscar centro de custo ou código"
          >
            <Badge
              content={getNumberAppliedFilters()}
              color="brand"
              variant="primary"
              size="lg"
            >
              <PillButton
                icon="IconFilter"
                iconPosition="left"
                label="Filtros"
                onClick={() => setOpenFilters(!openFilters)}
                variant={getNumberAppliedFilters() > 0 ? "pink" : "default"}
                size="small"
                type={"primary"}
              />
            </Badge>
          </Table.FilterSearch>

          <Table.Grid.Root loading={costCenters?.isLoading}>
            <Table.Grid.Header
              getHeaderGroups={table.getHeaderGroups}
              toggleAllRowsExpanded={table.toggleAllRowsExpanded}
            />

            {table.rows.map((row, index) => (
              <Table.Grid.Row key={index + row.id} row={row} />
            ))}
          </Table.Grid.Root>

          <Table.Pagination
            count={costCenters?.total ?? 0}
            onPaginationChange={({ pageSize, pageNumber }) =>
              setPagination({ pageSize, pageNumber })
            }
            showItemRange
            pagination={{ pageSize, pageNumber }}
          />
        </Table.Content>
      </Table.Root>
      <FiltersTables
        open={openFilters}
        onClose={() => setOpenFilters(!openFilters)}
      />
      <ConfirmationModalDeactivation
        open={openConfirmModal}
        isLoading={inactivateCostCenterIsLoading}
        onClose={() => {
          setOpenConfirmModal(false);
          setIdInactivateChange("");
        }}
        onConfirm={() => {
          onInactivateCostCenter();
        }}
      />
    </>
  );
};

import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";
import { CostCentersContext } from "@/shared/context/CostCentersContext";

import * as SC from "./styled";

type Props = {
  step: number;
  isDisabledActions?: boolean;
  onBack: () => void;
  onContinue: () => void;
};

export const FooterRegisterCostCenter = ({
  step,
  isDisabledActions = false,
  onBack,
  onContinue,
}: Props) => {
  const navigate = useNavigate();
  const { costCenterId } = useParams();
  const { employees } = useContext(CostCentersContext);

  return (
    <SC.Container>
      <div>
        <LinkButton
          variant="neutral"
          onClick={() => navigate(routes.pageCostCenters)}
        >
          Cancelar
        </LinkButton>
      </div>
      <SC.ContainerActions>
        {step > 1 && (
          <Button
            variant="secondary"
            variantType="neutral"
            size="medium"
            onClick={onBack}
          >
            <Icons name="IconArrowLeft" size={16} />
            Voltar
          </Button>
        )}
        <Button
          variant="primary"
          size="medium"
          onClick={onContinue}
          disabled={employees?.isLoading || isDisabledActions}
        >
          {step !== 3
            ? "Continuar"
            : costCenterId
            ? "Atualizar centro de custo"
            : "Criar centro de custo"}
          <Icons name="IconArrowRight" size={16} />
        </Button>
      </SC.ContainerActions>
    </SC.Container>
  );
};

import { useContext, useState } from "react";
import { omit } from "lodash-es";

import { DrawerBaseFilters, StatusFilter } from "@/components";
import { EmployeesFilters } from "@/components/EmployeesFilters";
import { CostCentersContext } from "@/shared/context/CostCentersContext";

type Props = {
  open: boolean;
  onApply?: (filters?: CostCentersContext["constCentersFilters"]) => void;
  onClose: () => void;
};

export const FiltersTables = ({ open, onApply, onClose }: Props) => {
  const { employees, setConstCentersFilters, constCentersFilters } =
    useContext(CostCentersContext);
  const [filters, setFilters] = useState(constCentersFilters);

  return (
    <DrawerBaseFilters
      open={open}
      onApply={() => {
        setConstCentersFilters(filters);
        onApply?.(filters);
        onClose();
      }}
      onClear={() => {
        const newFilters = omit({ ...filters }, [
          "active",
          "responsibleEmployeeIds",
        ]);

        setFilters(newFilters);
        setConstCentersFilters(newFilters);
        onClose();
      }}
      onClose={() => {
        setFilters({
          active: constCentersFilters.active,
          responsibleEmployeeIds: constCentersFilters.responsibleEmployeeIds,
        });

        onClose();
      }}
    >
      <StatusFilter
        title="Status"
        onChange={(data) => setFilters({ ...filters, active: data })}
        value={filters?.active}
      />

      <EmployeesFilters
        title="Responsável"
        selected={filters.responsibleEmployeeIds ?? []}
        isLoading={employees?.isLoading ?? false}
        employees={employees?.data ?? []}
        onChange={(data) =>
          setFilters({ ...filters, responsibleEmployeeIds: data })
        }
      />
    </DrawerBaseFilters>
  );
};

import { Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TabTables = styled(Tab)(({ theme }) => ({
  ".MuiTabs-scroller": {
    borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  },
}));

export const TextHeader = styled(Typography).attrs((props) => ({
  variant: "body4",
}))(({ theme }) => ({
  color: theme.colors.neutral[40],
  marginBottom: theme.spacings.xs5,
  fontSize: "14px",
  fontWeight: "700!important",
}));

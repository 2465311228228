import { useState } from "react";
import {
  Button,
  Icons,
  LinkButton,
  ShapeIcon,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { CustomModal } from "@/components/CustomModal";

import {
  ContentContainer,
  FooterContainer,
  HeaderTextWrapper,
  NegativeTypography,
  StyledDescription,
  StyledTitle,
  WarningContainer,
} from "./styled";

export type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onButtonClick?: () => void;
};

const SUBMIT_WORD = "EXCLUIR";

export const DeleteCustomFieldModal = ({
  onClose,
  open,
  onButtonClick,
  isLoading,
}: DeleteModalProps) => {
  const [inputWord, setInputWord] = useState<string>();
  return (
    <CustomModal onClose={onClose} isOpen={open} size="xs">
      <CustomModal.Header>
        <WarningContainer>
          <ShapeIcon
            name="IconAlertCircle"
            variant="negative"
            stroke="negative"
            size={48}
          />
          <NegativeTypography variant="body4" weight={700}>
            Atenção!
          </NegativeTypography>
        </WarningContainer>
        <HeaderTextWrapper>
          <StyledTitle variant="headline8">
            Tem certeza que deseja excluir os campos customizados selecionados?
          </StyledTitle>
          <StyledDescription variant="body4">
            Ao excluir esses campos, as informações coletadas por ele serão
            perdidas permanentemente.
          </StyledDescription>
        </HeaderTextWrapper>
      </CustomModal.Header>
      <CustomModal.Content>
        <ContentContainer>
          <TextField
            label={`Digite ${SUBMIT_WORD} e confirme`}
            onChange={(e) => setInputWord(e.target.value)}
            fullWidth
          />
        </ContentContainer>
      </CustomModal.Content>
      <CustomModal.Footer>
        <FooterContainer>
          <LinkButton variant="default" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="medium"
            disabled={inputWord !== SUBMIT_WORD}
            loading={isLoading}
            onClick={onButtonClick}
          >
            Sim, excluir
            <Icons name="IconTrash" />
          </Button>
        </FooterContainer>
      </CustomModal.Footer>
    </CustomModal>
  );
};

import styled from "styled-components";

export const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  gap: theme.spacings.xs3,
  padding: `0 ${theme.spacings.m} ${theme.spacings.xs} ${theme.spacings.m}`,
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  marginTop: `-${theme.spacings.m}`,
}));

export const FooterContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacings.xs3,
  padding: ` ${theme.spacings.xs} ${theme.spacings.m} 0 ${theme.spacings.m}`,
}));

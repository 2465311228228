import { IMask } from "react-imask";
import {
  Checkbox,
  DatePicker,
  Dropzone,
  Icons,
  LinkButton,
  MultiSelect,
  ObjectLiteral,
  SelectField,
  TimePicker,
} from "@flash-tecnologia/hros-web-ui-v2";
import { InputBaseProps } from "@mui/material";
import { FieldComponentEnum, FieldComponentTypes } from "src/common/field";

import {
  CheckboxLabel,
  CheckboxWrapper,
  LinkButtonContainer,
  StyledTextField,
} from "./styled";

export interface FieldComponentInterface {
  name?: string | null;
  sensitive?: boolean | null;
  order?: number | null;
  mask?: string | null;
  type?: string;
  component?: FieldComponentTypes;
  options?: ObjectLiteral[] | null;
  expires?: boolean | null;
  required?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  value?: any;
  inputProps?: InputBaseProps["inputProps"];
  inputMode?: InputBaseProps["inputMode"];
  imaskProps?: IMask.AnyMaskedOptions;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  style?: ObjectLiteral;
}

export const FieldComponent = ({
  component,
  name,
  type,
  options,
  onChange,
  disabled,
  value,
  inputProps,
  inputMode,
  imaskProps,
  placeholder,
  error,
  helperText,
  style,
}: FieldComponentInterface) => {
  const onChangeHandler = (value: any) =>
    onChange ? onChange(value) : undefined;
  switch (component) {
    case "shortText":
    case "longText":
    case "number":
      return (
        <StyledTextField
          label={name || FieldComponentEnum[component]}
          type={type}
          disabled={disabled}
          onChange={(e) => onChangeHandler(e?.target?.value)}
          value={value}
          inputProps={{ ...inputProps }}
          imaskProps={imaskProps}
          inputMode={inputMode}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      );
    case "datePicker":
      return (
        <DatePicker
          label={name || undefined}
          onDateChange={() => {
            return;
          }}
          disabled={disabled}
          value={value}
        />
      );
    case "timePicker":
      return (
        <TimePicker
          label={name}
          onChange={(value) => onChangeHandler(value)}
          value={value}
          disabled={disabled}
        />
      );
    case "checkbox":
      return (
        <CheckboxWrapper>
          <Checkbox disabled={disabled} value={value} onChange={onChange} />
          <CheckboxLabel variant="body3">{name}</CheckboxLabel>
        </CheckboxWrapper>
      );
    case "select":
      return (
        <SelectField
          fullWidth={true}
          options={options || []}
          label={name}
          onSelectChange={(_, value) => onChangeHandler(value?.value)}
          disabled={disabled}
          value={value}
          error={error}
          helperText={helperText}
        />
      );
    case "multiSelect":
      return (
        <MultiSelect
          value={value}
          label={name}
          options={options || []}
          renderInput={
            (() => {
              return;
            }) as any
          }
          onSelectChange={(_, value) => {
            onChangeHandler(value);
          }}
          disabled={disabled}
          error={error}
          helperText={helperText}
          style={style}
          fullWidth
        />
      );
    case "attachment":
      return (
        <Dropzone
          title={name || undefined}
          accept={["png", "jpg", "jpeg"]}
          onChange={() => {
            return;
          }}
        />
      );
    case "link": {
      const buildUrl = (value: any) => {
        const parsedUrl = value?.split("://") || [""];
        return `https://${parsedUrl?.length > 1 ? parsedUrl[1] : parsedUrl[0]}`;
      };
      return (
        <LinkButtonContainer>
          <Icons name="IconExternalLink" color="#FE2B8F" />
          <LinkButton variant="default" href={buildUrl(value)} target="_blank">
            {name || "Título do campo"}
          </LinkButton>
        </LinkButtonContainer>
      );
    }
    default:
      return null;
  }
};

import styled from "styled-components";

export const Container = styled.div<{ fullWidth: boolean }>(
  ({ theme, fullWidth }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacings.xs1,
    maxWidth: "1260px",
    padding: `0 ${theme.spacings.xs} ${theme.spacings.xs} ${theme.spacings.xs}`,

    ...(fullWidth && {
      width: "100%",
      maxWidth: "100%",
    }),
  }),
);

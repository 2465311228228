import { isValidElement } from "react";
import { Icons, IconsProps } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Label,
  OptionItem,
  OptionItemCustomIconWrapper,
  OptionItemIconWrapper,
  OptionItemInput,
} from "./styled";

export type OptionProps = {
  icon?: IconsProps["name"] | React.FC;
  label: string;
  value: string;
  iconProps?: IconsProps;
  name: string;
  showCheckIcon?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void | Promise<void>;
};

export const Option = ({
  label,
  value,
  icon,
  iconProps,
  name,
  showCheckIcon = true,
  disabled = false,
  onClick,
}: OptionProps) => {
  return (
    <OptionItem>
      {icon && (
        <OptionItemCustomIconWrapper>
          {typeof icon === "string" ? (
            <Icons name={icon} {...iconProps} />
          ) : isValidElement(icon) ? (
            icon
          ) : null}
        </OptionItemCustomIconWrapper>
      )}
      <OptionItemInput
        disabled={disabled}
        type="radio"
        value={value}
        title={label}
        name={name}
        id={`${name}-${value}`}
        onClick={onClick}
      />
      <Label htmlFor={`${name}-${value}`}>{label}</Label>
      {showCheckIcon && (
        <OptionItemIconWrapper>
          <Icons name="IconCheck" />
        </OptionItemIconWrapper>
      )}
    </OptionItem>
  );
};

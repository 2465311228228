import { Pagination, SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  height: 48px;
`;

const pageSizeOptions = [
  {
    key: "table-pagination-option-0",
    label: "10 itens",
    value: 10,
  },
  {
    key: "table-pagination-option-1",
    label: "25 itens",
    value: 25,
  },
  {
    key: "table-pagination-option-2",
    label: "50 itens",
    value: 50,
  },
  {
    key: "table-pagination-option-3",
    label: "100 itens",
    value: 100,
  },
  {
    key: "table-pagination-option-4",
    label: "250 itens",
    value: 250,
  },
];

type TablePaginationProps = {
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  onPageNumberChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
};

export const TablePagination = ({
  totalPages,
  pageNumber,
  pageSize,
  onPageNumberChange,
  onPageSizeChange,
}: TablePaginationProps) => {
  return (
    <PaginationContainer>
      <SelectField
        className="data-grid-custom-select"
        disabled={!totalPages}
        value={pageSize}
        defaultValue={pageSize}
        onSelectChange={(_, { value }) => onPageSizeChange(value)}
        options={pageSizeOptions}
      />
      <Pagination
        count={totalPages}
        defaultPage={pageNumber}
        page={pageNumber}
        onChange={(_, value) => onPageNumberChange(value)}
        siblingCount={1}
      />
    </PaginationContainer>
  );
};

import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useInactivateCostCenter = () => {
  const utils = trpc.useContext();
  const { isLoading, mutateAsync } = trpc.inactivateCostCenter.useMutation({
    onError: (error) => {
      if (error.data?.httpStatus === 500)
        dispatchToast({
          content:
            error?.message ?? "Não foi possível desativar o centro de custo.",
          description:
            "Não é possível desativar centros de custo que possuam uma pessoa atrelada a ele ou que ainda estão em uso em algum módulo.",
          type: "error",
        });
      else
        dispatchToast({
          content: error?.message,
          type: "error",
        });
    },
    onSuccess: () => {
      utils.getCostCenters.invalidate();
      utils.getEmployeesLinkedCostCenter.invalidate();
    },
  });

  return { inactivateCostCenter: mutateAsync, isLoading };
};

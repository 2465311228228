import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ContainerText = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacings.xs4,
  width: "fit-content",
}));

export const Title = styled(Typography)<{ allowLineWrapping: boolean }>(
  ({ theme, allowLineWrapping }) => ({
    whiteSpace: !allowLineWrapping ? "nowrap" : "normal",
    wordWrap: "break-word",
    fontSize: "14px",
    color: theme.colors.neutral[20],
    fontWeight: 600,
  }),
);

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: theme.colors.neutral[40],
  fontWeight: 400,
}));

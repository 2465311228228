import { useContext } from "react";
import {
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CreateCostCenterInput } from "bff/src/services/platform-entities.service";
import { useFormikContext } from "formik";

import { CostCentersContext } from "@/shared/context/CostCentersContext";

import * as SC from "./styled";

type Props = {
  setStep: (step: number) => void;
};

export const DataReview = ({ setStep }: Props) => {
  const { employees } = useContext(CostCentersContext);
  const form = useFormikContext<
    Omit<CreateCostCenterInput, "companyId"> & { memberEmployeeIds?: string[] }
  >();
  const members = form.values?.membersEmployeeIds ?? [];
  const numberOfMembers = members?.length || 0;
  const emptyValue = "-";

  return (
    <SC.Container>
      <SC.Box>
        <SC.Header>
          <Typography variant="headline10" color="neutral.50">
            Informações básicas
          </Typography>
          <LinkButton variant="default" size="small" onClick={() => setStep(1)}>
            Editar
            <Icons size={16} name="IconPencil" />
          </LinkButton>
        </SC.Header>
        <SC.Content>
          <SC.DataContainer>
            <div>
              <Typography variant="body4" color="neutral.50" weight={600}>
                Nome do centro de custo
              </Typography>
              <Typography variant="body3" weight={400}>
                {form.values.name || emptyValue}
              </Typography>
            </div>
            <div>
              <Typography variant="body4" color="neutral.50" weight={600}>
                Código
              </Typography>
              <Typography variant="body3" weight={400}>
                {form.values.code || emptyValue}
              </Typography>
            </div>
          </SC.DataContainer>
          <SC.DataContainer>
            <div>
              <Typography variant="body4" color="neutral.50" weight={600}>
                Responsável pelo centro de custo
              </Typography>
              <Typography variant="body3" weight={400}>
                {employees?.data?.find(
                  (employee) =>
                    employee.id === form.values.responsibleEmployeeId,
                )?.name ?? emptyValue}
              </Typography>
            </div>
            <div>
              <Typography variant="body4" color="neutral.50" weight={600}>
                Status do centro de custo
              </Typography>
              <Typography variant="body3" weight={400}>
                {`${form.values.active ? "Ativo" : "Inativo"}`}
              </Typography>
            </div>
          </SC.DataContainer>
        </SC.Content>
      </SC.Box>
      <SC.Box>
        <SC.Header>
          <Typography variant="headline10" color="neutral.50">
            Membros
          </Typography>
          <LinkButton variant="default" size="small" onClick={() => setStep(2)}>
            Editar
            <Icons size={16} name="IconPencil" />
          </LinkButton>
        </SC.Header>
        <SC.Content>
          <Typography variant="caption" color="neutral.50">
            {`${numberOfMembers} ${
              numberOfMembers !== 1
                ? "pessoas selecionadas"
                : "pessoa selecionada"
            }`}
          </Typography>
          <SC.PeoplesContainer>
            {(employees?.data.length &&
              members?.map((id) => (
                <SC.PeopleTag variant="gray">
                  <Icons size={16} name="IconUser" />
                  {employees.data.find((employee) => employee.id === id)
                    ?.name ?? emptyValue}
                </SC.PeopleTag>
              ))) ||
              []}
          </SC.PeoplesContainer>
        </SC.Content>
      </SC.Box>
    </SC.Container>
  );
};

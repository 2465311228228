import { useContext } from "react";

import { ContainerPage } from "@/components/PageLayoutBase/ContainerPage";
import { CostCentersContext } from "@/shared/context/CostCentersContext";

import { CostCentersTable } from "./components/CostCentersTable";
import { EmptyState } from "./components/EmptyState";
import { HeaderCostCenters } from "./components/HeaderCostCenters";
import { MemberTable } from "./components/MemberTable";
import * as SC from "./styled";

export const CostCenters = () => {
  const { costCenters, isEmptyCostCenters, employeesLinked } =
    useContext(CostCentersContext);

  return (
    <ContainerPage fullWidth>
      <HeaderCostCenters isEmpty={isEmptyCostCenters} />

      {isEmptyCostCenters && !costCenters?.isLoading ? (
        <EmptyState />
      ) : (
        <SC.TabTables
          tabItens={[
            {
              component: <CostCentersTable />,
              label: `Centros de custos (${
                costCenters?.isLoading ? "..." : costCenters?.total
              })`,
            },
            {
              component: <MemberTable />,
              label: `Todos os membros (${
                employeesLinked?.isLoading ? "..." : employeesLinked?.total
              })`,
            },
          ]}
          type="scrollable"
        />
      )}
    </ContainerPage>
  );
};

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { CostCentersQuery } from "bff/src/services/platform-entities.service";

import { useCostCenters } from "@/hooks/CostCenters/useCostCenters";
import {
  EmployeesLinkedCostCenterProps,
  useGetEmployeesLinkedCostCenter,
} from "@/hooks/CostCenters/useGetEmployeesLinkedCostCenter";
import { useGetEmployeesForSelect } from "@/hooks/Employees/useGetEmployeesForSelect";

export type CostCentersContext = {
  costCenters?: ReturnType<typeof useCostCenters>;
  employeesLinked?: ReturnType<typeof useGetEmployeesLinkedCostCenter>;
  employees?: ReturnType<typeof useGetEmployeesForSelect>;
  constCentersFilters: Omit<CostCentersQuery, "companyIds">;
  employeesLinkedFilters: EmployeesLinkedCostCenterProps;
  setEmployeesLinkedFilters: Dispatch<
    SetStateAction<EmployeesLinkedCostCenterProps>
  >;
  setConstCentersFilters: Dispatch<
    SetStateAction<CostCentersContext["constCentersFilters"]>
  >;
  isEmptyCostCenters: boolean;
};

export interface CostCentersContextProviderProps {
  children: ReactNode;
}

export const CostCentersContext = createContext<CostCentersContext>({
  constCentersFilters: {},
  isEmptyCostCenters: true,
  employeesLinkedFilters: { limit: 10, page: 1, costCenterActive: true },
  setEmployeesLinkedFilters: () => {},
  setConstCentersFilters: () => {},
});

export const CostCentersContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [constCentersFilters, setConstCentersFilters] = useState<
    CostCentersContext["constCentersFilters"]
  >({
    skip: 0,
    limit: 10,
    search: "",
  });
  const [employeesLinkedFilters, setEmployeesLinkedFilters] = useState<
    CostCentersContext["employeesLinkedFilters"]
  >({
    limit: 10,
    page: 1,
    costCenterActive: true,
  });
  const employeesLinked = useGetEmployeesLinkedCostCenter({
    ...employeesLinkedFilters,
  });
  const costCenters = useCostCenters(constCentersFilters);
  const employees = useGetEmployeesForSelect();
  const [isEmptyCostCenters, setIsEmptyCostCenters] = useState(true);

  useEffect(() => {
    if (
      !costCenters.isLoading &&
      isEmptyCostCenters &&
      Boolean(costCenters.total)
    )
      setIsEmptyCostCenters(false);
  }, [costCenters]);

  return (
    <CostCentersContext.Provider
      value={{
        isEmptyCostCenters,
        employees,
        costCenters,
        employeesLinked,
        constCentersFilters,
        employeesLinkedFilters,
        setConstCentersFilters,
        setEmployeesLinkedFilters,
      }}
    >
      {children ?? <Outlet />}
    </CostCentersContext.Provider>
  );
};

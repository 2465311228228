import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useUpdateCustomField = (opts?: {
  onError?: () => void;
  onSuccess?: () => void;
}) => {
  const utils = trpc.useContext();
  const { isLoading, mutate, data, mutateAsync } =
    trpc.updateCustomField.useMutation({
      onError: (error) => {
        const errorCode = error.data?.code;
        if (errorCode === "CONFLICT") {
          dispatchToast({
            content:
              "Campo com mesmo nome já existe. Por favor atribua outro nome.",
            type: "error",
          });
        } else {
          dispatchToast({
            content: "Por favor, tente novamente mais tarde.",
            title: "Erro ao criar campo customizado.",
            type: "error",
          });
        }
        opts?.onError?.();
      },
      onSuccess: () => {
        utils.listCustomFields.invalidate();
        opts?.onSuccess?.();
      },
    });

  return {
    updateCustomField: mutate,
    isLoading,
    updatedCustomField: data,
    mutateAsync,
  };
};

import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  width: "fit-content",
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.secondary[95]}`,
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  width: "240px",
  flexDirection: "column",
  gap: theme.spacings.xs4,
  minHeight: theme.spacings.xs5,
  padding: theme.spacings.xs2,
}));

import { useContext, useState } from "react";

import { DrawerBaseFilters, StatusFilter } from "@/components";
import { useCostCentersForSelect } from "@/hooks/CostCenters/useCostCentersForSelect";
import { CostCentersContext } from "@/shared/context/CostCentersContext";

import { CostCentersFilters } from "./CostCentersFilters";

export type Filters = {
  costCenterActive?: boolean;
  costCenterIds?: string[];
};

type Props = {
  open: boolean;
  onApply: (filters: Filters) => void;
  onClose: () => void;
};

export const FiltersTables = ({ open, onApply, onClose }: Props) => {
  const { data: costCenters, isLoading } = useCostCentersForSelect({});
  const [filters, setFilters] = useState<Filters>({ costCenterActive: true });
  const { employeesLinkedFilters } = useContext(CostCentersContext);

  return (
    <DrawerBaseFilters
      open={open}
      onApply={() => {
        onApply(filters);
        onClose();
      }}
      onClear={() => {
        const newFilters = {
          costCenterActive: undefined,
          costCenterIds: undefined,
        };
        setFilters(newFilters);
        onApply(newFilters);
        onClose();
      }}
      onClose={() => {
        setFilters({
          costCenterActive: employeesLinkedFilters.costCenterActive,
          costCenterIds: employeesLinkedFilters.costCenterIds,
        });
        onClose();
      }}
    >
      <StatusFilter
        title="Status"
        onChange={(data) => setFilters({ ...filters, costCenterActive: data })}
        value={filters?.costCenterActive}
      />

      <CostCentersFilters
        selected={filters?.costCenterIds}
        isLoading={isLoading ?? false}
        costCenters={costCenters ?? []}
        onChange={(data) => setFilters({ ...filters, costCenterIds: data })}
      />
    </DrawerBaseFilters>
  );
};

import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacings.xs2,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  borderRadius: theme.borders.radius.m,
  padding: `${theme.spacings.xs} ${theme.spacings.m}`,
  width: "100%",
}));

export const RadioContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacings.xs3,
  alignItems: "center",
}));

import {
  Dot,
  TagProps,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { EmployeeStatus } from "../../../../../../bff/src/services/company-management.service";

import * as SC from "./styled";

type Props = {
  text: string;
  caption?: string;
  status?: EmployeeStatus;
  allowLineWrapping?: boolean;
};

export const MainRowColumn = ({
  text,
  caption,
  status,
  allowLineWrapping = true,
}: Props) => {
  const getStatus = (status: Props["status"]): TagProps["variant"] => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "INACTIVE":
        return "gray";
      default:
        return "yellow";
    }
  };

  const getTooltipTitle = (status: Props["status"]) => {
    switch (status) {
      case "ACTIVE":
        return "Ativo";
      case "INACTIVE":
      case "DELETED":
        return "Inativo";
      case "INVITATION_SENT":
        return "Convite enviado";
      case "INVITATION_SCHEDULED":
        return "Convite Agendado";
      case "INVITATION_EXPIRED":
        return "Convite expirado";
      case "INVITATION_PENDING":
        return "Aguardando convite";
      default:
        return "";
    }
  };

  return (
    <div>
      <SC.ContainerText>
        <SC.Title
          weight={600}
          variant="body3"
          allowLineWrapping={allowLineWrapping}
          color="neutral.20"
        >
          {text}
        </SC.Title>
        {status && (
          <div>
            <Tooltip title={getTooltipTitle(status)} placement="top" arrow>
              <div>
                <Dot hasOutline variant={getStatus(status)} />
              </div>
            </Tooltip>
          </div>
        )}
      </SC.ContainerText>
      <SC.Caption
        weight={400}
        variant="body4"
        color="neutral.40"
        style={{
          fontSize: "14px",
        }}
      >
        {caption}
      </SC.Caption>
    </div>
  );
};

import { useEffect, useRef } from "react";

import { PopoverStyled } from "./styled";

type PopoverProps = {
  children: React.ReactNode;
  selectRef: React.RefObject<HTMLInputElement>;
};

export const Popover = ({ children, selectRef }: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        selectRef.current.checked = false;
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return <PopoverStyled ref={popoverRef}>{children}</PopoverStyled>;
};

import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useAddCompanyTerm = () => {
  const { data, isLoading, mutateAsync } = trpc.addCompanyTerm.useMutation({
    onError: () => {
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao gerar o contrato. Por favor, tente novamente mais tarde.",
      });
    },
  });
  return { data, isLoading, addCompanyTerm: mutateAsync };
};

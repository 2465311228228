import { useState } from "react";
import { ParsedCustomField } from "bff/src/routers";

import { CustomCheckboxFilter } from "./CustomFilter";
import { Footer } from "./Footer";
import { FiltersContainer, StyledDrawer } from "./styled";

type DrawerProps = {
  onClose: () => void;
  open: boolean;
  filters?: CustomFieldFilter[];
  filterValues: FilterValues;
  setFilterValues: (value: FilterValues) => void;
};

export type FilterValues = Partial<{
  [key in keyof Pick<
    ParsedCustomField,
    "categoryId" | "createdBy" | "type"
  >]: string[];
}>;

export type CustomFieldFilterOption = {
  label: string;
  value: any;
  total: number;
};

export type CustomFieldFilter = {
  title: string;
  searchable?: boolean;
  accessorKey: "categoryId" | "createdBy" | "type";
  options: CustomFieldFilterOption[];
};

export const FilterDrawer = ({
  onClose,
  open,
  filters,
  setFilterValues,
}: DrawerProps) => {
  const [selected, setSelected] = useState<FilterValues>({});
  const onClear = () => {
    setSelected({});
    setFilterValues({});
    onClose();
  };
  const filterHandler = () => {
    setFilterValues({ ...selected });
    onClose();
  };
  return (
    <StyledDrawer
      open={open}
      title={"Filtros"}
      onClose={onClose}
      anchor="right"
    >
      <FiltersContainer>
        {filters?.map((f) => (
          <CustomCheckboxFilter
            title={f.title}
            options={f.options}
            searchable={f.searchable}
            accessorKey={f.accessorKey}
            selected={selected?.[f.accessorKey] || []}
            setSelected={({ accessorKey, value }) => {
              setSelected({ ...selected, [accessorKey]: value });
            }}
          />
        ))}
      </FiltersContainer>
      <Footer
        onClearButtonClick={onClear}
        onFilterButtonClick={filterHandler}
      />
    </StyledDrawer>
  );
};

import { ReactNode } from "react";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "./styled";

type Props = {
  title: string;
  description: string;
  image: ReactNode;
};

export const StepImage = ({ title, description, image }: Props) => {
  return (
    <SC.Container>
      {image}
      <SC.Content>
        <Typography variant="headline10" color="brand.50" weight={700}>
          {title}
        </Typography>
        <Typography variant="caption" color="neutral.40">
          {description}
        </Typography>
      </SC.Content>
    </SC.Container>
  );
};

import {
  EmptyState,
  Table,
  TagFilter,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;
export const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledButtonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const TagFilterTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const StyledTagFilter = styled(TagFilter)`
  border: 0px;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  max-height: 41px !important;
`;

export const StyledSearchField = styled(Table.FilterSearch)`
  width: 66% !important;
`;

export const StyledPillButtonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.tertiary[40]};
`;

export const PillButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const MenuOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledEmptyStateMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

import { useMemo, useRef } from "react";
import {
  DataGrid,
  EmptyState,
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StatusTag } from "@Components/StatusTag";

import {
  DataGridWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";
import { ITableGrid } from "./types";

export const TableGrid = ({ data, pageSize, isLoading }: ITableGrid) => {
  const tableRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
                source={data?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{data.value}</Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: any }) => <StatusTag status={value} />,
      },
      {
        Header: "CPF",
        accessor: "documentNumber",
      },
    ],
    [data],
  );

  return (
    <DataGridWrapper ref={tableRef}>
      {data?.length ? (
        <DataGrid
          loading={isLoading}
          hasPagination={true}
          columns={columns}
          data={(data as any) || []}
          initialState={{ pageSize: Number(pageSize) }}
        />
      ) : (
        <EmptyState
          buttonText={"Adicionar integrantes"}
          description={
            "Selecione os filtros no início da página para visualizar o resultado"
          }
          buttonProps={{
            size: "medium",
            onClick: () => {},
          }}
          withoutButton
        />
      )}
    </DataGridWrapper>
  );
};

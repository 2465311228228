import { SelectedListProps } from "@/components/ListSwitcher/SelectedList";
import { useEffect, useState } from "react";

type Props<T extends { id: string; name: string }> = {
  selectedIds: T["id"][];
  items: T[];
};

export const useOptionsSelected = <T extends { id: string; name: string }>({
  selectedIds = [],
  items = [],
}: Props<T>) => {
  const [selectedOptions, setSelectedOptions] = useState<
    SelectedListProps["options"]
  >([]);

  useEffect(() => {
    if (items.length > 0)
      setSelectedOptions(
        items
          .filter((item) => selectedIds.some((id) => id === item.id))
          .map((item) => ({ label: item.name, value: item.id })),
      );
  }, [selectedIds.length, items.length]);

  return selectedOptions;
};

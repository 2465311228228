import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useUnlinkEmployeeFromCostCenter = () => {
  const utils = trpc.useContext();
  const { isLoading, mutateAsync } =
    trpc.unlinkEmployeeFromCostCenter.useMutation({
      onError: (error) => {
        dispatchToast({
          content: error?.message,
          type: "error",
        });
      },
      onSuccess: () => {
        utils.getEmployeesLinkedCostCenter.invalidate();
      },
    });

  return { unlinkEmployee: mutateAsync, isLoading };
};

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import { trpc } from "@/api/client";
import { ContainerPage } from "@/components/PageLayoutBase/ContainerPage";
import { useCostCenterById } from "@/hooks/CostCenters/useCostCenterById";
import { useCreateCostCenter } from "@/hooks/CostCenters/useCreateCostCenter";
import { useUpdateCostCenter } from "@/hooks/CostCenters/useUpdateCostCenter";
import { routes } from "@/routes";

import {
  CreateCostCenterInput,
  UpdateCostCenterInput,
} from "../../../../bff/src/services/platform-entities.service";

import { DataReview } from "./components/DataReview";
import { FooterRegisterCostCenter } from "./components/FooterRegisterCostCenter";
import { FormRegister } from "./components/FormRegister";
import { HeaderRegisterCostCenter } from "./components/HeaderRegisterCostCenter";
import { LinkPeoplesCostCenters } from "./components/LinkPeoplesCostCenters";
import { StepperRegister } from "./components/StepperRegister";
import { StepTitle } from "./components/StepTitle";
import * as SC from "./styled";

export const PageRegisterCostCenter = () => {
  const utils = trpc.useContext();
  const [step, setStep] = useState(1);
  const {
    createCostCenter,
    isSuccess: successfulCreate,
    isLoading: createIsLoading,
  } = useCreateCostCenter();
  const {
    updateCostCenter,
    isSuccess: successfulUpdate,
    isLoading: updateIsLoading,
  } = useUpdateCostCenter();
  const navigate = useNavigate();
  const { costCenterId } = useParams();
  const { data: costCenterById, isLoading } = useCostCenterById(costCenterId);

  const formik = useFormik<
    Omit<CreateCostCenterInput, "companyId"> & { memberEmployeeIds?: string[] }
  >({
    initialValues: {
      name: "",
      code: "",
      active: true,
      responsibleEmployeeId: "",
      membersEmployeeIds: [],
    },
    validationSchema: yup.object({
      name: yup.string().required("Campo obrigatório"),
      code: yup.string().required("Campo obrigatório"),
      active: yup.boolean().required(),
      responsibleEmployeeId: yup.string().required("Campo obrigatório"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (data) => {
      if (costCenterId && costCenterById) {
        let input: UpdateCostCenterInput = {
          costCenterId,
          membersEmployeeIds: data.membersEmployeeIds ?? [],
        };

        data.name !== costCenterById.name && (input.name = data.name);
        data.code !== costCenterById.code && (input.code = data.code);
        data.active !== costCenterById.active && (input.active = data.active);
        data.responsibleEmployeeId !== costCenterById.responsibleEmployeeId &&
          (input.responsibleEmployeeId = data.responsibleEmployeeId);

        await updateCostCenter({ ...input });
      } else {
        await createCostCenter({ ...data });
      }
    },
  });

  const onContinue = async () => {
    formik.validateForm();
    switch (step) {
      case 1:
        const errors = await formik.validateForm();
        Object.keys(errors).length === 0 && setStep(step + 1);
        break;
      case 2:
        setStep(step + 1);
        break;
      default:
        formik.submitForm();
        break;
    }
  };

  useEffect(() => {
    if (!isLoading && costCenterId) {
      formik.setValues({
        name: costCenterById?.name ?? "",
        code: costCenterById?.code ?? "",
        active: costCenterById?.active ?? true,
        responsibleEmployeeId: costCenterById?.responsibleEmployeeId ?? "",
        membersEmployeeIds: costCenterById?.membersEmployeeIds ?? [],
      });
    }
  }, [costCenterById, isLoading]);

  useEffect(() => {
    const updateData = async () => {
      await utils.getCostCenters.refetch();
      navigate(routes.pageCostCenters);
    };

    if (successfulUpdate || successfulCreate) updateData();
  }, [successfulUpdate, successfulCreate]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ContainerPage fullWidth>
          <HeaderRegisterCostCenter />
          <SC.Container>
            <StepperRegister step={step} />

            <SC.Content>
              <StepTitle step={step} />
              {step === 1 && (
                <FormRegister isLoading={Boolean(isLoading && costCenterId)} />
              )}
              {step === 2 && <LinkPeoplesCostCenters />}
              {step === 3 && <DataReview setStep={setStep} />}
            </SC.Content>
          </SC.Container>
          <FooterRegisterCostCenter
            step={step}
            isDisabledActions={
              Boolean(isLoading && costCenterId) ||
              createIsLoading ||
              updateIsLoading
            }
            onBack={() => setStep(step - 1)}
            onContinue={onContinue}
          />
        </ContainerPage>
      </form>
    </FormikProvider>
  );
};

import { ReactNode, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  EmptyState,
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Employee } from "@features/departments/types";
import { routes } from "src/routes";

import { GroupTag, StatusTag } from "@/components";

import { TableSkeleton } from "../table-skeleton";

import { EmployeeOptions } from "./components/EmployeeOptions";
import {
  DataGridWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";

type Props = {
  loading: boolean;
  data: Record<string, ReactNode>[];
  pageSize: number;
  departmentId: string;
};

export const TableGrid = ({ loading, data, pageSize, departmentId }: Props) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (cellProps: any) => {
          const email = cellProps?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={cellProps?.cell?.row?.original?.name}
                source={cellProps?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{cellProps.value}</Typography>
                {email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: any }) => <StatusTag status={value} />,
      },
      {
        Header: "CPF",
        accessor: "cpf",
      },
      {
        Header: "Telefone",
        accessor: "phoneNumber",
      },
      {
        Header: "Grupos",
        accessor: "groups",
        Cell: ({ value }: { value: any }) => <GroupTag groups={value} />,
      },

      {
        Header: "Ações",
        Cell: (cellProps: any) => (
          <EmployeeOptions
            employeeId={cellProps?.cell?.row?.original?.id}
            active={cellProps?.cell?.row?.original?.active}
            departmentId={departmentId}
          />
        ),
        sticky: "right",
        disableSortBy: true,
      },
    ],
    [departmentId],
  );

  return (
    <>
      {loading && <TableSkeleton />}
      {!loading && !data.length && (
        <EmptyState
          buttonText="Atribuir Integrantes"
          description="Você ainda não adicionou ninguém a esse departamento"
          buttonProps={{
            size: "medium",
            type: "button",
            variant: "primary",
            onClick: () => {
              navigate(routes.departmentEmployeesEdit(departmentId));
            },
          }}
        />
      )}
      {!loading && data.length && (
        <DataGridWrapper ref={tableRef}>
          <DataGrid<keyof Employee>
            columns={columns}
            data={data ?? []}
            initialState={{ pageSize: Number(pageSize) }}
          />
        </DataGridWrapper>
      )}
    </>
  );
};

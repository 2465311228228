import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacings.s,
  alignItems: "flex-start",
  padding: `${theme.spacings.m} 0`,
}));

export const ContainerStepper = styled.div(({ theme }) => ({
  padding: `${theme.spacings.xs4} ${theme.spacings.xs2} 0 ${theme.spacings.xs2}`,
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs,
  alignItems: "flex-start",
  width: "100%",
}));

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Icons,
  LinkButton,
  MenuOptionType,
  PillButton,
  RadioVision,
  ShapeIcon,
  Table as SoftTable,
  tableControllers,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { ParsedCustomField } from "bff/src/routers";

import { typeDescriptionMapper, typeIconMapper } from "@/common/field";
import { routes } from "@/routes";

import { ActionsMenu } from "./components/ActionsMenu";
import { DeleteCustomFieldModal } from "./components/DeleteModal";
import {
  CustomFieldFilter,
  CustomFieldFilterOption,
  FilterDrawer,
  FilterValues,
} from "./components/FilterDrawer";
import {
  DescriptionWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  MenuOptionWrapper,
  PillButtonTextWrapper,
  SearchBarContainer,
  StyledButtonText,
  StyledDescription,
  StyledEmptyStateMessageWrapper,
  StyledPillButtonText,
  StyledSearchField,
  StyledTagFilter,
  StyledTitle,
  StyledTypography,
  TagFilterTextWrapper,
} from "./styled";

const pageSizeOptions = [
  {
    label: "5 itens",
    value: 5,
  },
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
];

type TableProps = {
  data?: ParsedCustomField[];
  headers?: any[];
  isLoading?: boolean;
  deleteManyIsLoading?: boolean;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  onPagination: (data: TableProps["pagination"]) => void;
  categories?: {
    id: string;
    name: string;
  }[];
  onBulkActionHandler: () => void;
  setSelectedCustomFields: (value: string[]) => void;
  duplicateCustomField: (id: string) => void;
};

type Column = {
  displayName: string;
  value: any;
};

enum CustomFieldVisionTypes {
  ALL = "ALL",
  CUSTOM = "CUSTOM",
  STANDARD = "STANDARD",
}

const customFieldVisions = [
  { id: CustomFieldVisionTypes.ALL, label: "Todos" },
  { id: CustomFieldVisionTypes.STANDARD, label: "Padrão" },
  { id: CustomFieldVisionTypes.CUSTOM, label: "Customizados" },
];

const columns: Column[] = [
  {
    displayName: "Nome do campo",
    value: "name",
  },
  {
    displayName: "Tipo",
    value: "type",
  },
  {
    displayName: "Categoria",
    value: "category",
  },
  {
    displayName: "Criado por",
    value: "createdBy",
  },
  {
    displayName: "Ações",
    value: "actions",
  },
];

const buildCellContent = (
  context: any,
  column: Column,
  actions: MenuOptionType[],
  categories?: TableProps["categories"],
) => {
  switch (column.value) {
    case "name":
      const name = context?.cell?.row?.original?.name;
      const description = context?.cell?.row?.original?.description;
      const type = context?.cell?.row?.original?.type;
      const iconName = typeIconMapper(type) || "IconPencil";
      return (
        <FirstColumnCell>
          <ShapeIcon size={32} name={iconName} variant="neutral" />
          <FirstColumnCellData>
            <Typography variant="body4" weight={600}>
              {name}
            </Typography>
            {!!description && (
              <DescriptionWrapper>
                <StyledDescription variant="body4" weight={400}>
                  {description}
                </StyledDescription>
              </DescriptionWrapper>
            )}
          </FirstColumnCellData>
        </FirstColumnCell>
      );
    case "type":
      const isCustom = context?.cell?.row?.original?.isCustom;
      return (
        <Tag variant={isCustom ? "pink" : "gray"} as="span">
          {isCustom ? "Customizado" : "Padrão"}
        </Tag>
      );
    case "category":
      const categoryId = context?.cell?.row?.original?.categoryId;
      return (
        <>
          {categoryId && categories?.find((c) => c.id === categoryId)?.name && (
            <Tag variant="pink" as="span">
              {categories?.find((c) => c.id === categoryId)?.name}
            </Tag>
          )}
        </>
      );
    case "createdBy":
      const createdBy =
        context?.cell?.row?.original?.createdBy?.name || "Flash";
      return <StyledTitle variant="body4">{createdBy}</StyledTitle>;
    case "actions":
      return <ActionsMenu options={actions} />;
    default:
      return <></>;
  }
};

const sortCustomFields = (
  a: any,
  b: any,
  orderBy: "name" | "category" | "createdBy" | "type",
  categories: TableProps["categories"],
): number => {
  switch (orderBy) {
    case "name": {
      const [{ name: n1 }, { name: n2 }] = [a, b];
      return n1.localeCompare(n2);
    }
    case "category": {
      const a_categoryId = a.categoryId || "";
      const b_categoryId = b.categoryId || "";
      const [c1, c2] = [a_categoryId, b_categoryId].map((id) =>
        categories?.find((c) => c.id === id),
      );
      if (!c1 && !c2) return 0;
      if (!c1) return 1;
      if (!c2) return -1;
      const result = c1.name.localeCompare(c2.name);
      if (result === 0) {
        if (a.isCustom && b.isCustom)
          return sortCustomFields(a, b, "name", categories);
      }
      return result;
    }
    case "createdBy": {
      const a_createdBy = a.createdBy?.name || "";
      const b_createdBy = b.createdBy?.name || "";
      const result = a_createdBy.localeCompare(b_createdBy);
      if (result === 0) {
        return sortCustomFields(a, b, "category", categories);
      }
      return result;
    }
    case "type": {
      const result = b.isCustom.toString().localeCompare(a.isCustom.toString());
      if (result === 0) {
        const sortByCategory = sortCustomFields(a, b, "category", categories);
        if (sortByCategory === 0)
          return sortCustomFields(a, b, "name", categories);
        return sortByCategory;
      }
      return result;
    }
  }
};

export const Table = ({
  data,
  isLoading,
  deleteManyIsLoading,
  pagination,
  onPagination,
  categories,
  onBulkActionHandler,
  setSelectedCustomFields,
  duplicateCustomField,
}: TableProps) => {
  const navigate = useNavigate();
  const [vision, setVision] = useState(CustomFieldVisionTypes.ALL);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState<
    "name" | "category" | "createdBy" | "type"
  >("category");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  const dataToShow = useMemo(() => {
    return (
      data
        ?.filter((cf) => {
          let show = true;
          const { createdBy, categoryId, type } = filterValues;
          if (
            (createdBy?.length && !createdBy.includes(cf.createdBy.id)) ||
            (categoryId?.length && !categoryId.includes(cf.categoryId || "")) ||
            (type?.length && !type.includes(cf.type))
          ) {
            show = false;
          }
          return show;
        })
        ?.filter((cf) => {
          if (vision === CustomFieldVisionTypes.STANDARD) return !cf.isCustom;
          if (vision === CustomFieldVisionTypes.CUSTOM) return cf.isCustom;
          return true;
        })
        ?.filter((cf) => {
          return new RegExp(searchQuery, "i").test(cf.name);
        })
        ?.sort((a, b) => {
          return sortCustomFields(a, b, selectedOrderBy, categories);
        }) || []
    );
  }, [data, searchQuery, selectedOrderBy, vision, filterValues]);

  const filterOptions: CustomFieldFilter[] = useMemo(() => {
    if (!data?.length) return [];
    const options = data?.reduce(
      (
        { createdByOptions, typeOptions, categoryOptions, uncategorized },
        cf,
      ) => {
        if (cf.createdBy.name) {
          const index = createdByOptions.findIndex(
            (o) => o.value === cf.createdBy.id,
          );
          if (index < 0) {
            createdByOptions.push({
              label: cf.createdBy.name,
              value: cf.createdBy.id,
              total: 1,
            });
          } else {
            createdByOptions[index].total = createdByOptions[index].total + 1;
          }
        }

        const indexOfType = typeOptions.findIndex((o) => o.value === cf.type);
        if (indexOfType < 0) {
          typeOptions.push({
            label: typeDescriptionMapper(cf.type as any) || cf.type,
            value: cf.type,
            total: 1,
          });
        } else {
          typeOptions[indexOfType].total = typeOptions[indexOfType].total + 1;
        }

        const category = categories?.find((c) => cf.categoryId === c.id);
        if (cf.categoryId && !!category) {
          const indexOfCategory = categoryOptions.findIndex(
            (o) => o.value === cf.categoryId,
          );

          if (indexOfCategory < 0) {
            categoryOptions.push({
              label:
                categories?.find((c) => c.id === cf.categoryId)?.name || "",
              value: cf.categoryId || null,
              total: 1,
            });
          } else {
            categoryOptions[indexOfCategory].total =
              categoryOptions[indexOfCategory].total + 1;
          }
        } else {
          uncategorized.total = uncategorized.total + 1;
        }

        return {
          createdByOptions,
          typeOptions,
          categoryOptions,
          uncategorized,
        };
      },
      {
        createdByOptions: new Array<CustomFieldFilterOption>(),
        typeOptions: new Array<CustomFieldFilterOption>(),
        categoryOptions: new Array<CustomFieldFilterOption>(),
        uncategorized: {
          label: "Sem categoria",
          value: "NO_CATEGORY",
          total: 0,
        },
      },
    );

    return [
      {
        title: "Formato do campo",
        accessorKey: "type",
        options: options.typeOptions,
        searchable: true,
      },
      {
        title: "Criado por",
        accessorKey: "createdBy",
        options: options.createdByOptions,
        searchable: true,
      },
      {
        title: "Categoria",
        accessorKey: "categoryId",
        options: [
          ...options.categoryOptions,
          ...(options.uncategorized.total ? [options.uncategorized] : []),
        ],
        searchable: false,
      },
    ];
  }, [data]);

  const menuActions = (customFieldId: string): MenuOptionType[] => [
    {
      label: "Editar campo",
      children: (
        <MenuOptionWrapper>
          <Icons name="IconEdit" />
          <StyledTypography variant="body3" weight={600}>
            Editar campo
          </StyledTypography>
        </MenuOptionWrapper>
      ),
      onClick: () => {
        navigate(routes.pageUpdateCustomFields(customFieldId));
      },
    },
    {
      label: "Duplicar campo",
      children: (
        <MenuOptionWrapper>
          <Icons name="IconCopy" />
          <StyledTypography variant="body3" weight={600}>
            Duplicar campo
          </StyledTypography>
        </MenuOptionWrapper>
      ),
      onClick: () => {
        duplicateCustomField(customFieldId);
      },
    },
    {
      label: "Excluir campo",
      children: (
        <MenuOptionWrapper>
          <Icons name="IconTrash" />
          <StyledTypography variant="body3" weight={600}>
            Excluir campo
          </StyledTypography>
        </MenuOptionWrapper>
      ),
      onClick: () => {
        setSelectedCustomFields([customFieldId]);
        setOpenDeleteModal(!openDeleteModal);
      },
    },
  ];

  const table = tableControllers.useTableColumns<ParsedCustomField>({
    defaultColumn: {
      minSize: 64,
    },
    total: dataToShow?.length || 0,
    columns:
      (columns?.map((c) => ({
        header: c.displayName,
        accessorKey: c.value,
        cell: (context: any) => {
          const id = context?.cell?.row?.original?.id;
          return buildCellContent(context, c, menuActions(id), categories);
        },
        minSize: c.value === "name" ? 500 : 64,
        ...(c.value === "actions" && { sticky: "right" }),
      })) as any) || [],
    data: dataToShow || [],
    pagination: pagination,
    onPaginationChange: onPagination,
    options: {
      selectable: true,
    },
  });

  useEffect(() => {
    const { selected, allSelected } = table.selected;
    if (allSelected) {
      setSelectedCustomFields(dataToShow.map((cf) => cf.id));
    } else {
      setSelectedCustomFields(selected.map((s) => s.original.id) || []);
    }
  }, [JSON.stringify(table.selected)]);

  return (
    <SoftTable.Root variant="soft">
      <SoftTable.Content>
        <StyledSearchField onSearch={(e) => setSearchQuery(e.target.value)}>
          <SearchBarContainer>
            <RadioVision
              views={customFieldVisions}
              value={vision}
              onChangeView={(v) => setVision(v as CustomFieldVisionTypes)}
            />
            <Badge
              variant="secondary"
              color="brand"
              content={Object.values(filterValues).reduce(
                (acc, fv) => (acc = acc + fv?.length),
                0,
              )}
            >
              <PillButton
                icon="IconFilter"
                label="Filtros"
                onClick={() => setOpenDrawer(!openDrawer)}
                variant="default"
                size="small"
                type="primary"
                iconPosition="left"
              />
            </Badge>
            <StyledTagFilter
              variant="tertiary"
              filterLabel={
                <TagFilterTextWrapper>
                  <Icons name="IconArrowsDownUp" size={16} />
                  <StyledButtonText variant="caption" weight={700}>
                    Ordernar por
                  </StyledButtonText>
                </TagFilterTextWrapper>
              }
              hasLeftIcon={false}
              disableOptionsSearch
              disableOptionsFooter
              options={columns
                .filter((c) => c.value !== "actions")
                .map((c) => ({ label: c.displayName, value: c.value }))}
              selectedOptions={[selectedOrderBy]}
              onClick={(e) => {
                setSelectedOrderBy(e as any);
              }}
              optionIconType="radio"
            />
          </SearchBarContainer>
        </StyledSearchField>

        <SoftTable.Grid.Root
          loading={isLoading}
          empty={{
            message: (
              <StyledEmptyStateMessageWrapper>
                <StyledDescription variant="headline8">
                  Você ainda não criou nenhum campo customizado.
                </StyledDescription>
                <StyledDescription variant="body4">
                  Crie um campo customizado para começar a coletar dados
                  adicionais da sua equipe de uma maneira flexível e organizada.
                </StyledDescription>
              </StyledEmptyStateMessageWrapper>
            ) as any,
            children: (
              <LinkButton variant="default">
                Criar campo customizado <Icons name="IconPlus" size={16} />
              </LinkButton>
            ),
          }}
        >
          <SoftTable.Grid.Header
            getHeaderGroups={table.getHeaderGroups}
            toggleAllRowsExpanded={table.toggleAllRowsExpanded}
          />

          {table.rows
            .slice(
              (pagination.pageNumber - 1) * pagination.pageSize,
              pagination.pageNumber * pagination.pageSize,
            )
            .map((row, index) => (
              <SoftTable.Grid.Row key={index + row.id} row={row} />
            ))}
        </SoftTable.Grid.Root>

        <SoftTable.Pagination
          count={dataToShow?.length || 0}
          onPaginationChange={({ pageSize, pageNumber }) => {
            onPagination({ ...pagination, pageSize, pageNumber });
          }}
          showItemRange
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </SoftTable.Content>
      <SoftTable.BulkActions
        open={table.selected.allSelected || table.selected.selected.length > 0}
        total={dataToShow.length}
        totalSelected={
          table.selected.allSelected
            ? dataToShow.length
            : table.selected.selected.length
        }
        onClearSelection={table.resetSelected}
        onSelectAll={() => table.setAllSelected(true)}
      >
        <PillButton
          variant="default"
          size="medium"
          label={
            (
              <PillButtonTextWrapper>
                <Icons name="IconTrash" size={12} color="#C8195A" />
                <StyledPillButtonText variant="body4" weight={700}>
                  Excluir campos customizados
                </StyledPillButtonText>
              </PillButtonTextWrapper>
            ) as any
          }
          onClick={() => {
            setOpenDeleteModal(!openDeleteModal);
          }}
          loading={deleteManyIsLoading}
        />
      </SoftTable.BulkActions>
      <FilterDrawer
        filterValues={filterValues || {}}
        setFilterValues={setFilterValues}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        filters={filterOptions}
      />
      <DeleteCustomFieldModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          if (table.selected.selected.length) {
            setSelectedCustomFields(
              table.selected.selected.map((s) => s.original.id),
            );
          } else {
            setSelectedCustomFields([]);
          }
        }}
        onButtonClick={() => {
          onBulkActionHandler();
          table.resetSelected();
          setOpenDeleteModal(!openDeleteModal);
        }}
        isLoading={isLoading}
      />
    </SoftTable.Root>
  );
};

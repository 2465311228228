import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icons, Tag } from "@flash-tecnologia/hros-web-ui-v2";

import {
  ButtonContainer,
  CheckboxControl,
  ErrorMessage,
  ErrorMessageContainer,
  IconWrapper,
  Label,
  LabelContainer,
  RequiredAsterisk,
  SelectInputWrapper,
  SelectStyled,
  Value,
} from "./styled";

export type SelectProps = {
  id: string;
  placeholder: string;
  errorMessage?: string;
  error?: boolean;
  disabled?: boolean;
  onOpen?: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  Popover: React.FC<{
    children: React.ReactNode;
    selectRef: React.RefObject<HTMLInputElement>;
  }>;
  Dropdown: React.ReactElement;
  inputRef?: React.Ref<any>;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void | Promise<void> | undefined;
  fullWidth?: boolean;
  required?: boolean;
  value?: any;
};

export const SelectInput = ({
  placeholder = "Placeholder",
  errorMessage = "Este campo deve ser preenchido",
  id,
  error = false,
  disabled = false,
  onOpen,
  Popover,
  Dropdown,
  inputRef,
  onChange,
  fullWidth,
  required,
  value,
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const optionsViewRef = useRef<any>(inputRef || null);
  useEffect(() => {
    if (value) setSelectedValue(value);
  }, [value]);
  const getVariant = useCallback(() => {
    if (disabled) {
      return "disabled";
    }
    if (error && !selectedValue) {
      return "error";
    }
    return "default";
  }, [disabled, selectedValue, error]);

  const handleOpen = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      await onOpen?.(e);
    },
    [onOpen],
  );

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      await onChange?.(e);
      setSelectedValue(e.target.title);
      optionsViewRef.current.checked = false;
    },
    [onChange],
  );

  return (
    <SelectStyled $fullWidth={fullWidth}>
      <SelectInputWrapper $variant={getVariant()}>
        <CheckboxControl
          id={id}
          type="checkbox"
          title={selectedValue || placeholder}
          ref={optionsViewRef}
          onChange={handleOpen}
        />
        <ButtonContainer $variant={getVariant()}>
          <LabelContainer>
            <Label
              htmlFor={id}
              $isInputFullfilled={!!selectedValue}
              $variant={getVariant()}
            >
              {placeholder}
              {required && !selectedValue && (
                <RequiredAsterisk>*</RequiredAsterisk>
              )}
            </Label>
            {selectedValue && (
              <Value
                type="text"
                autoComplete="false"
                value={selectedValue}
                readOnly
              />
            )}
          </LabelContainer>
          <IconWrapper>
            <Icons
              name="IconChevronDown"
              fill="transparent"
              size={24}
              aria-hidden="true"
            />
          </IconWrapper>
        </ButtonContainer>
      </SelectInputWrapper>
      <Popover selectRef={optionsViewRef}>
        {React.cloneElement(Dropdown, {
          ...Dropdown.props,
          key: id,
          onChange: async (e: React.ChangeEvent<HTMLInputElement>) => {
            await Dropdown.props?.onChange(e);
            await handleChange(e);
          },
          onClear: () => {
            Dropdown.props?.onClear?.();
            setSelectedValue(undefined);
          },
        })}
      </Popover>
      {errorMessage && error && (
        <ErrorMessageContainer>
          <ErrorMessage>
            <Icons name="IconInfoCircle" size={16} strokeWidth="2.5" />
            {errorMessage}
          </ErrorMessage>
        </ErrorMessageContainer>
      )}
    </SelectStyled>
  );
};

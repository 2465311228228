import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useActivateCostCenter = () => {
  const utils = trpc.useContext();

  const { isLoading, mutateAsync } = trpc.activateCostCenter.useMutation({
    onError: () => {
      dispatchToast({
        content: "Erro ao ativar centro de custos.",
        type: "error",
      });
    },
    onSuccess: () => {
      utils.getCostCenters.invalidate();
      utils.getEmployeesLinkedCostCenter.invalidate();
    },
  });

  return { activateCostCenter: mutateAsync, isLoading };
};

import styled, { css } from "styled-components";

type SelectVariants = "default" | "error" | "disabled";

function getButtonContainerVariant(variant: SelectVariants) {
  switch (variant) {
    case "error":
      return css`
        border-color: transparent;
        background-color: ${({ theme }) => theme.colors.error90};
        outline: 2px solid ${({ theme }) => theme.colors.error50};
        svg {
          color: ${({ theme }) => theme.colors.error50};
        }
      `;
    case "disabled":
      return css`
        border-color: ${({ theme }) => theme.colors.neutral80};
        background-color: ${({ theme }) => theme.colors.neutral95};
        svg {
          color: ${({ theme }) => theme.colors.neutral80};
        }
      `;
    default:
      return css`
        border-color: ${({ theme }) => theme.colors.neutral70};
        background-color: transparent;
      `;
  }
}

export const Value = styled.input`
  all: unset;
  color: ${({ theme }) => theme.colors.neutral30};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ButtonContainerProps = {
  $variant: SelectVariants;
};

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  padding: 18px 16px;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.neutral40};
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  ${({ $variant }) => getButtonContainerVariant($variant)}
  &:has(input) {
    padding: 9px 16px;
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutral50};
`;

export const CheckboxControl = styled.input`
  all: unset;
  position: absolute;
  inset: 0;
  z-index: 3;

  &:is(:checked, :focus) + ${ButtonContainer} {
    outline: 2px solid ${({ theme }) => theme.colors.brand[50]};
    border-color: transparent;
  }
  &:is(:hover):not(:checked, :focus) + ${ButtonContainer} {
    outline: 2px solid ${({ theme }) => theme.colors.neutral[50]};
    border-color: transparent;
  }
  &:is(:checked) + ${ButtonContainer} ${IconWrapper} {
    svg {
      color: ${({ theme }) => theme.colors.brand[50]};
      rotate: 180deg;
      transition: rotate 0.3s ease;
    }
  }
`;

type LabelProps = {
  $isInputFullfilled: boolean;
  $variant: SelectVariants;
};

export const Label = styled.label<LabelProps>`
  color: ${({ theme, $variant }) =>
    $variant === "disabled" ? theme.colors.neutral70 : theme.colors.neutral40};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ $isInputFullfilled }) =>
    $isInputFullfilled &&
    css`
      color: ${({ theme }) => theme.colors.neutral50};
      font-size: 12px;
      line-height: 18px;
    `}
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
  min-height: 18px;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.neutral40};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    color: ${({ theme }) => theme.colors.feedback};
  }
`;

export const RequiredAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

type SelectInputWrapperProps = {
  $variant: SelectVariants;
};
export const SelectInputWrapper = styled.div<SelectInputWrapperProps>`
  position: relative;
  cursor: ${({ $variant }) =>
    $variant === "disabled" ? "not-allowed" : "pointer"};
  pointer-events: ${({ $variant }) =>
    $variant === "disabled" ? "none" : "auto"};
  margin-bottom: 8px;
  &:has(${CheckboxControl}:checked) + div {
    display: flex;
  }
`;

type SelectStyledProps = {
  $fullWidth?: boolean;
};
export const SelectStyled = styled.div<SelectStyledProps>`
  position: relative;
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

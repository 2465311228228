import { getCep } from "@flash-tecnologia/hros-web-utility";

export const getCepFromUtility = async (zipCode: string) => {
  const data = await getCep(zipCode);
  return {
    street: data.logradouro,
    complement: data.complemento,
    district: data.bairro,
    city: data.localidade,
    state: data.uf,
  };
};

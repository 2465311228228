import { useEffect, useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { Option } from "@/common/field";
import { useCreateCategory, useUpdateCategory } from "@/hooks";
import { useListCategories } from "@/hooks/Categories/useListCategories";
import {
  CustomFieldProps,
  useCreateCustomField,
} from "@/hooks/CustomFields/useCreateCustomField";
import { dispatchToast } from "@/utils";

import { CreateCustomFieldForm } from "./components/CreateCustomFieldForm";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SuccessPage } from "./components/SuccessPage";
import { Body, FormContainer } from "./styled";

export const PageCreateCustomField = () => {
  const { selectedCompany } = useSelectedCompany();
  const [customFieldProps, setCustomFieldProps] = useState<CustomFieldProps>({
    companyId: selectedCompany.id,
  });
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [showManageCategoriesModal, setShowManageCategoriesModal] =
    useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [conflictError, setConflictError] = useState(false);

  const createCustomFieldHookOptions = {
    onError: (error: any) => {
      const errorCode = error.data?.code;
      if (errorCode === "CONFLICT") {
        setConflictError(true);
      } else {
        dispatchToast({
          content: "Por favor, tente novamente mais tarde.",
          title: "Erro ao criar campo customizado.",
          type: "error",
        });
      }
    },
    onSuccess: () => {
      setShowSuccessPage(true);
    },
  };

  useEffect(
    () => setConflictError(false),
    [JSON.stringify(customFieldProps.name)],
  );

  const { data: fetchedCategories } = useListCategories(selectedCompany.id);
  const { updateCategory } = useUpdateCategory();
  const { createManyCategories, isLoading: categoryCreationIsLoading } =
    useCreateCategory();

  const { createCustomField, isLoading: customFieldCreationIsLoading } =
    useCreateCustomField(createCustomFieldHookOptions);

  useEffect(() => {
    setCategoryOptions(
      fetchedCategories?.map((c) => ({ label: c.name, value: c.id })) || [],
    );
  }, [fetchedCategories]);

  const createButtonOnClickHandler = () => {
    createCustomField(customFieldProps as any);
  };

  const saveCategoriesChanges = (categoryOptions: Option[]) => {
    const newCategories: { companyId: string; name: string }[] = [];
    for (let co of categoryOptions) {
      const oldCategory = fetchedCategories?.find((cat) => cat.id === co.value);

      const shouldCreate = !oldCategory && co.label && !co.value;
      if (shouldCreate) {
        newCategories.push({ companyId: selectedCompany.id, name: co.label });
        continue;
      }

      const shouldUpdate = !!oldCategory && oldCategory.name !== co.label;
      if (shouldUpdate) {
        updateCategory({
          categoryId: co.value,
          companyId: selectedCompany.id,
          name: co.label,
        });
      }
    }
    if (newCategories.length) {
      createManyCategories(newCategories, {
        modalController: {
          closeModal: () => setShowManageCategoriesModal(false),
        },
      });
    }
  };

  return (
    <Body>
      <Header />
      {showSuccessPage ? (
        <SuccessPage />
      ) : (
        <>
          <FormContainer>
            <CreateCustomFieldForm
              categoryOptions={categoryOptions}
              setCategoryOptions={setCategoryOptions}
              customFieldProps={customFieldProps}
              setCustomFieldProps={(customField) =>
                setCustomFieldProps({ ...customFieldProps, ...customField })
              }
              saveCategoriesChanges={saveCategoriesChanges}
              saveButtonLoading={categoryCreationIsLoading}
              showManageCategoriesModal={showManageCategoriesModal}
              setShowManageCategoriesModal={setShowManageCategoriesModal}
              conflictError={conflictError}
            />
          </FormContainer>
          <Footer
            onButtonClick={createButtonOnClickHandler}
            buttonDisabled={
              !customFieldProps?.name || !customFieldProps?.component
            }
            buttonLoading={customFieldCreationIsLoading}
          />
        </>
      )}
    </Body>
  );
};

import { useMemo } from "react";
import { DataGrid } from "@flash-tecnologia/hros-web-ui-v2";
import { EconomicGroupCompanyCard } from "@features/economicGroup/types";
import { cnpjFormat } from "@utils";

import { CompanyOptions } from "./CompanyOptions";
import { DateColumn } from "./DateColumn";
import { ProfileColumn } from "./ProfileColumn";
import { StyledSkeleton } from "./styled";

interface ITableGrid {
  data: EconomicGroupCompanyCard[];
  loading: boolean;
}

export const TableGrid = ({ data, loading }: ITableGrid) => {
  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (data: any) => {
          const name = data?.cell?.row?.original?.name;
          const legalName = data?.cell?.row?.original?.legalName;
          const imgSource = data?.cell?.row?.original?.profilePicture?.source;
          return (
            <ProfileColumn
              name={name}
              legalName={legalName}
              imgSource={imgSource}
            />
          );
        },
      },
      {
        Header: "CNPJ",
        accessor: "registrationNumber",
        Cell: (data) => {
          const registrationNumber =
            data?.cell?.row?.original?.registrationNumber;
          return <>{cnpjFormat(registrationNumber)}</>;
        },
      },
      {
        Header: "Adicionada em",
        accessor: "createdAt",
        Cell: (data: any) => {
          const createdAt = data?.cell?.row?.original?.createdAt;
          return <DateColumn createdAt={createdAt} />;
        },
      },
      {
        Header: "Ações",
        Cell: (data: any) => (
          <CompanyOptions company={data?.cell?.row?.original} />
        ),
        sticky: "right",
        disableSortBy: true,
      },
    ],
    [data],
  );
  return loading ? (
    <StyledSkeleton variant="rectangular" />
  ) : (
    <DataGrid
      hasPagination={false}
      initialState={{
        pageSize: 1000,
      }}
      columns={columns}
      data={(data as any) || []}
    />
  );
};

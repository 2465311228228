import { useNavigate } from "react-router-dom";
import {
  Icons,
  ShapeIcon,
  SurveyStore,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";

import {
  ButtonWrapper,
  PageContainer,
  StyledButton,
  StyledDescription,
  StyledTitle,
  TextWrapper,
} from "./styled";

export const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <ShapeIcon
        variant="success"
        name="IconCircleCheck"
        size={48}
        stroke="success"
      />
      <TextWrapper>
        <StyledTitle variant="headline8" weight={700}>
          Campo customizado criado com sucesso!
        </StyledTitle>
        <StyledDescription variant="body4" weight={400}>
          O campo criado já está ativo e pronto para uso.
        </StyledDescription>
        <StyledDescription variant="body4" weight={400}>
          Você pode gerenciá-lo na área de Campos Customizados.
        </StyledDescription>
      </TextWrapper>
      <ButtonWrapper>
        <StyledButton
          variant="secondary"
          onClick={() => navigate(routes.pageCustomFields)}
        >
          Voltar para a lista de campos customizados
          <Icons name="IconArrowRight" />
        </StyledButton>
      </ButtonWrapper>
      <SurveyStore
        onSubmit={() => false}
        questions={[
          {
            id: "experience_rating",
            title:
              "Como você avalia a facilidade de uso da plataforma ao criar campos customizados?",
          },
        ]}
      />
    </PageContainer>
  );
};

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, TagStyled } from "./styled";

type Props = {
  totalCount: number;
};

export const TableHeader = ({ totalCount }: Props) => {
  return (
    <Container>
      <Typography variant="headline8">Pessoas neste departamento</Typography>
      {totalCount > 0 && (
        <TagStyled variant="gray">{`${totalCount} pessoas`}</TagStyled>
      )}
    </Container>
  );
};

import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacings.xs2,
  width: "100%",
  gridTemplateColumns: "2fr 1fr",
  gridTemplateRows: "auto",
  alignItems: "stretch",
}));

export const ContainerSelected = styled.div(({ theme }) => ({
  paddingBottom: theme.spacings.m,
  height: "100%",
  overflow: "hidden",
}));

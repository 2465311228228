import { useState } from "react";
import { Checkbox, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  CheckboxTextWrapper,
  CustomFilterContainer,
  OptionBox,
  OptionsContainer,
  SearchBarContainer,
  SearchBarWrapper,
  StyledCheckboxText,
  StyledFilterTitle,
  StyledResultText,
  StyledSearchInput,
  TitleWrapper,
} from "./styled";

type Option = {
  label: string;
  value: string;
  total: number;
};

type CustomCheckboxFilter = {
  options: Option[];
  title: string;
  accessorKey: string;
  onFooterButtonClick?: () => void;
  searchable?: boolean;
  selected: (string | number | Date | null)[];
  setSelected: (input: {
    accessorKey: string;
    value: (string | number | Date | null)[];
  }) => void;
};

export const CustomCheckboxFilter = ({
  accessorKey,
  options,
  searchable,
  title,
  selected,
  setSelected,
}: CustomCheckboxFilter) => {
  const [optionsShown, setOptionsShown] = useState<Option[]>(options);

  const selectAll = () => {
    const all = options.map((o) => o.value);
    setSelected({ accessorKey, value: all });
  };

  const removeAll = () => {
    setSelected({ accessorKey, value: [] });
  };

  const addToSelected = (value: string) => {
    setSelected({ accessorKey, value: [...selected, value] });
  };

  const removeFromSelected = (value: string) => {
    const aux = [...selected];
    aux.splice(aux.indexOf(value), 1);
    setSelected({ accessorKey, value: aux });
  };

  const isSelected = (value: string) => selected.includes(value);
  const search = (value: string) => {
    const filteredOptions = options.filter((o) => {
      return new RegExp(value, "i").test(o.label);
    });
    setOptionsShown(filteredOptions);
  };

  return (
    <CustomFilterContainer>
      <TitleWrapper>
        <StyledFilterTitle variant="headline9">{title}</StyledFilterTitle>
      </TitleWrapper>
      {searchable && (
        <SearchBarContainer>
          <SearchBarWrapper>
            <Icons name="IconSearch" size={20} strokeWidth="2" />
            <StyledSearchInput
              label="Buscar"
              size="small"
              onChange={(e) => search(e.target.value)}
            />
          </SearchBarWrapper>
          <OptionBox
            style={{ marginTop: "4px" }}
            selected={options.every((o) => isSelected(o.value))}
          >
            <CheckboxTextWrapper>
              <Checkbox
                onChange={(_, checked) => {
                  checked ? selectAll() : removeAll();
                }}
                checked={options.every((o) => isSelected(o.value))}
                nestedSelection
              />
              <StyledCheckboxText
                variant="body4"
                weight={600}
                selected={options.every((o) => isSelected(o.value))}
              >
                Selecionar tudo
              </StyledCheckboxText>
            </CheckboxTextWrapper>
          </OptionBox>
        </SearchBarContainer>
      )}
      <OptionsContainer>
        {optionsShown.map((o) => (
          <OptionBox selected={isSelected(o.value)}>
            <CheckboxTextWrapper>
              <Checkbox
                onChange={(_, checked) => {
                  checked
                    ? addToSelected(o.value)
                    : removeFromSelected(o.value);
                }}
                checked={isSelected(o.value)}
              />
              <StyledCheckboxText
                variant="body4"
                weight={600}
                selected={isSelected(o.value)}
              >
                {o.label}
              </StyledCheckboxText>
            </CheckboxTextWrapper>
            <StyledResultText
              variant="body4"
              weight={600}
              selected={isSelected(o.value)}
            >
              {o.total || "Nenhum"} resultado(s)
            </StyledResultText>
          </OptionBox>
        ))}
      </OptionsContainer>
    </CustomFilterContainer>
  );
};

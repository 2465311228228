import { Icons, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Card = styled.button<{ selected: boolean }>`
  padding: 13px 16px;
  width: 312px;
  border-radius: 6px;
  text-align: start;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-light5)" : "var(--color-neutral-90)"};
  background: ${({ selected }) =>
    selected ? "var(--color-secondary-light2)" : "transparent"};
`;

export const Title = styled(Typography)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-dark4)" : "var(--color-neutral-dark3)"};
  font-weight: 600 !important;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const StyledLock = styled(Icons)`
  && {
    color: var(--color-neutral-80);
  }
`;

import { useMemo } from "react";
import {
  Icons,
  IconTypes,
  ShapeIcon,
  ShapeIconOptions,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import * as SC from "./styled";

export type HighlightProps = {
  orientation?: "horizontal" | "vertical";
  status?: ShapeIconOptions;
  icon?: IconTypes;
  text?: string;
};

export const Highlight = ({
  icon,
  orientation,
  status = "default",
  text,
}: HighlightProps) => {
  const { colors } = useTheme();
  const currentIcon: HighlightProps["icon"] = useMemo(() => {
    if (icon) return icon;

    switch (status) {
      case "error":
        return "IconAlertCircle";
      case "success":
        return "IconCircleCheck";
      case "negative":
        return "IconCircleX";
      default:
        return "IconSparkles";
    }
  }, [status, icon]);

  const currentColor = useMemo(() => {
    switch (status) {
      case "info":
      case "success":
      case "error":
      case "progress":
      case "negative":
        return colors.feedback[status][40];
      case "neutral":
        return colors.neutral[40];
      case "disabled":
        return colors.neutral[70];
      case "transparent":
        return "transparent";
      default:
        return colors.secondary[50];
    }
  }, [status, icon]);

  return (
    <SC.Container orientation={orientation} status={status}>
      {orientation === "vertical" ? (
        <ShapeIcon
          name={currentIcon}
          variant={status}
          size={48}
          color={currentColor}
        />
      ) : (
        <Icons
          name={currentIcon}
          fill="transparent"
          size={16}
          color={currentColor}
        />
      )}
      {text && (
        <SC.Text variant="body4" weight={700} status={status}>
          {text}
        </SC.Text>
      )}
    </SC.Container>
  );
};

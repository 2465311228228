import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { FieldComponentTypes } from "@/common/field";

import {
  BorderlessButton,
  ButtonContainer,
  OptionsContainer,
  StyledTextInput,
  StyledTitle,
  TextInputContainer,
  TextInputWrapper,
} from "./styled";

export interface AdditionalInputProps {
  component?: FieldComponentTypes;
  value: any;
  setValue: (
    value: any,
    component: FieldComponentTypes,
    index?: number,
  ) => void;
  options?: {
    addOption: () => void;
    removeOption: (index: number) => void;
  };
}

const renderComponent = ({
  component,
  value,
  setValue,
  options,
}: AdditionalInputProps) => {
  switch (component) {
    case "select":
    case "multiSelect": {
      if (!(value instanceof Array)) return <></>;
      return (
        <OptionsContainer>
          <StyledTitle variant="headline8">Adicione as opções</StyledTitle>
          {value?.map((v, index) => {
            return (
              <TextInputContainer>
                <TextInputWrapper>
                  <StyledTextInput
                    label="Insira uma opção"
                    required={index < 2}
                    value={v}
                    onChange={(e) => setValue(e.target.value, component, index)}
                    error={!v}
                    helperText={!v ? "Valor da opção obrigatório" : undefined}
                  />
                </TextInputWrapper>
                <BorderlessButton
                  variant="secondary"
                  variantType="neutral"
                  onClick={() => {
                    options?.removeOption(index);
                  }}
                >
                  <Icons name="IconTrash" />
                </BorderlessButton>
              </TextInputContainer>
            );
          })}
          <ButtonContainer>
            <Button variant="primary" size="small" onClick={options?.addOption}>
              <Icons name="IconPlus" />
              Adicionar opção
            </Button>
          </ButtonContainer>
        </OptionsContainer>
      );
    }
    case "link": {
      return (
        <StyledTextInput
          label="URL do campo"
          required
          value={value}
          onChange={(e) => setValue(e.target.value, component)}
        />
      );
    }
    default:
      return <></>;
  }
};

export const AdditionalInput = (props: AdditionalInputProps) => {
  return <>{renderComponent(props)}</>;
};

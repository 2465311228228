import { Stepper } from "@/components";

import * as SC from "./styled";

type Props = {
  step: number;
};

export const StepperRegister = ({ step }: Props) => {
  return (
    <SC.Container>
      <Stepper
        currentStep={step}
        steps={[
          {
            title: "Informações básicas",
            caption:
              "Adicione as informações básicas da avaliação, como nome e descrição.",
          },
          {
            title: "Membros do centro de custo",
            caption: "Inclua as pessoas como membros deste centro de custo.",
          },
          {
            title: "Revisão",
            caption:
              "Confira a prévia do centro de custo e revise antes de criá-lo.",
          },
        ]}
      />
    </SC.Container>
  );
};

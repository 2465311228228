import { trpc } from "@/api/client";
import { useSession } from "@/common/user";
import { dispatchToast } from "@/utils";
import { CostCentersQuery } from "bff/src/services/platform-entities.service";

export const useCostCenters = (
  filters: Omit<CostCentersQuery, "companyIds"> & { companyIds?: string[] },
) => {
  const { companyId: companyIdSession } = useSession();
  const { data, isLoading, refetch } = trpc.getCostCenters.useQuery(
    {
      companyIds: filters.companyIds || [companyIdSession],
      ...filters,
    },
    {
      staleTime: 0,
      onError: (error) => {
        dispatchToast({
          content: error.message,
          type: "error",
        });
      },
    },
  );
  return {
    data: data?.records ?? [],
    total: data?.metadata.total ?? 0,
    metadata: data?.metadata ?? null,
    isLoading: isLoading,
    refetch,
  };
};

import { Button, LinkButton, Unstable } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Drawer = styled(Unstable.Drawer)(({ theme }) => ({
  ".MuiPaper-root": {
    maxWidth: "560px",
  },
}));

export const DrawerContent = styled.div(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacings.xs,
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  paddingBottom: theme.spacings.xs,
  overflow: "auto",
}));

export const DrawerFooter = styled.div(({ theme }) => ({
  height: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: theme.spacings.xs,
  justifyContent: "space-between",
  padding: `${theme.spacings.xs3} ${theme.spacings.xs2} ${theme.spacings.xs3} ${theme.spacings.xs2}`,
  marginTop: `-${theme.spacings.xs}`,
}));

export const ClearButton = styled(LinkButton)(({ theme }) => ({
  ".MuiButtonBase-root": {
    lineHeight: "30px",
  },
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  paddingLeft: `${theme.spacings.l}!important`,
  paddingRight: `${theme.spacings.l}!important`,
}));

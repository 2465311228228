import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: `${theme.spacings.xs4} ${theme.spacings.xs3}`,
  minWidth: "100%",
  maxWidth: "300px",
  height: "fit-content",
}));

export const ContainerItem = styled.div<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: "flex",
    gap: theme.spacings.xs4,
    width: "100%",
    alignItems: "flex-start",
    height: isActive ? theme.spacings.xl5 : theme.spacings.s,
  }),
);

export const ContainerText = styled.div<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    padding: ` 0 0 ${isActive ? theme.spacings.xs4 : 0} 0`,
    width: "100%",
  }),
);

export const Line = styled.div(({ theme }) => ({
  width: "1px",
  height: "100%",
  backgroundColor: theme.colors.neutral[90],
}));

export const ContainerIndicator = styled.div(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100%",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: "-2px",
}));

export const IndicatorIcon = styled(Icons)(({ theme }) => ({
  marginTop: theme.colors.feedback.success[40],
}));

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ContentContainer = styled.div`
  height: auto;
  gap: 8px;
  margin-bottom: 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: fill-available;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NegativeTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.negative50};
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-top: 16px;
`;

import { ReactNode } from "react";

import { SelectedList, SelectedListProps } from "./SelectedList";
import * as SC from "./styled";

type Props = {
  title?: string;
  children: ReactNode;
  selectedOptions: SelectedListProps["options"];
  isLoading?: boolean;
  onDelete: (data: string) => void;
};

export const ListSwitcher = ({
  title,
  selectedOptions,
  children,
  isLoading = false,
  onDelete,
}: Props) => {
  return (
    <SC.Container>
      {children}
      <SC.ContainerSelected>
        <SelectedList
          isLoading={isLoading}
          title={title}
          options={selectedOptions}
          onDelete={onDelete}
        />
      </SC.ContainerSelected>
    </SC.Container>
  );
};

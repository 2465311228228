import { useNavigate } from "react-router-dom";
import {
  PillButton,
  tableControllers,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CostCenter } from "bff/src/services/platform-entities.service";
import { format } from "date-fns";

import { TextHeader } from "@/pages/PageCostCenters/styled";
import { routes } from "@/routes";

import { MainRowColumn } from "../../MainRowColumn";

import * as SC from "./styled";

type Columns = Parameters<
  typeof tableControllers.useTableColumns<CostCenter>
>[0]["columns"];

type Props = {
  idsStatusChange: string[];
  changeStatus: (
    costCenterId: string,
    status: "inactivate" | "activate",
  ) => void;
};

export const TableColumns = ({
  idsStatusChange,
  changeStatus,
}: Props): Columns => {
  const navigate = useNavigate();
  const emptyData = "-";
  const emptyDate = "--/--/--";

  return [
    {
      header: () => <TextHeader>Centro de custo</TextHeader>,
      accessorKey: "name",
      minSize: 200,
      cell: ({ row }) => (
        <MainRowColumn text={row.original?.name || emptyData} />
      ),
    },
    {
      header: () => <TextHeader>Código</TextHeader>,
      accessorKey: "code",
      cell: ({ row }) => (
        <MainRowColumn text={row.original?.code || emptyData} />
      ),
    },
    {
      header: () => <TextHeader>Responsável</TextHeader>,
      accessorKey: "responsible",
      minSize: 150,
      cell: ({ row }) => (
        <MainRowColumn text={row.original?.responsible?.name || emptyData} />
      ),
    },
    {
      header: () => <TextHeader>Criado por</TextHeader>,
      accessorKey: "createdBy",
      cell: (contex) => {
        const createdAt = contex.row.original.createdBy;
        return createdAt ? (
          <MainRowColumn
            text={createdAt?.name ?? emptyData}
            caption={
              createdAt.date
                ? `Em ${format(new Date(createdAt.date), "dd/MM/yyyy")}`
                : emptyDate
            }
          />
        ) : (
          "-"
        );
      },
    },
    {
      header: () => <TextHeader>Modificado em</TextHeader>,
      accessorKey: "updatedBy",
      cell: (contex) => {
        const updateBy = contex.row.original.updatedBy;
        return updateBy ? (
          <MainRowColumn
            text={updateBy?.name ?? emptyData}
            caption={
              updateBy.date
                ? `Em ${format(new Date(updateBy.date), "dd/MM/yyyy")}`
                : emptyDate
            }
          />
        ) : (
          "-"
        );
      },
    },
    {
      header: () => (
        <SC.ContainerActions>
          <TextHeader>Status</TextHeader>
          <TextHeader style={{ marginLeft: "12px" }}>Ações</TextHeader>
        </SC.ContainerActions>
      ),
      sticky: "right",
      accessorKey: "active",
      size: 180,
      cell: (context) => {
        const active = context.row?.original?.active ?? false;

        return (
          <SC.ContainerActions>
            <Toggle
              showIcon
              disabled={idsStatusChange.some(
                (id) => id === context.row?.original.id,
              )}
              status={active ? "complete" : "inactive"}
              checked={active}
              onChange={() =>
                changeStatus(
                  context.row?.original.id,
                  active ? "inactivate" : "activate",
                )
              }
            />
            <PillButton
              icon="IconPencil"
              size="small"
              type="secondary"
              variant="default"
              onClick={() =>
                navigate(routes.registerCostCenter(context.row?.original.id))
              }
            />
          </SC.ContainerActions>
        );
      },
    },
  ];
};

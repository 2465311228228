import { Radio, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 312px;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 40px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const FormWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 768px;
`;

export const TypeSelectionWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 768px;
  margin-top: 24px;
`;

export const StyledFormTitle = styled(Typography)`
  margin-bottom: 4px;
  color: var(--color-neutral-30);
`;

export const StyledFormDescription = styled(Typography)`
  margin-bottom: 32px;
  color: var(--color-neutral-50);
`;

export const FieldTitleInput = styled(TextField)`
  width: 100%;
`;

export const FieldDescriptionInput = styled(TextField)`
  width: 100%;
  margin-top: 32px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weight: 600;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TypeSelectionCard = styled.div<{ isLastCard?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid var(--color-neutral-70);
  border-radius: 8px;
  padding: 24px 16px;
  width: 100%;
  margin-bottom: ${(props) => (props.isLastCard ? "0px" : "24px")};
`;

export const TypeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 12px;
`;

export const TypeSelectionTitle = styled(Typography)`
  color: var(--color-neutral-30);
`;

export const TypeSelectionDescription = styled(Typography)`
  color: var(--color-neutral-50);
`;

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.s,
  height: "fit-content",
  width: "600px",
  marginBottom: theme.spacings.xs2,
}));

export const Box = styled.div(({ theme }) => ({
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  height: "100%",
  width: "100%",
}));

export const Header = styled.div(({ theme }) => ({
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  height: "fit-content",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacings.m,
  padding: `${theme.spacings.xs3} ${theme.spacings.xs2}`,
  alignItems: "center",
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs4,
  padding: `${theme.spacings.xs3} ${theme.spacings.xs2}`,
  height: "fit-content",
}));

export const PeoplesContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexFlow: "wrap",
  gap: theme.spacings.xs4,
  height: "fit-content",
}));

export const DataContainer = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacings.xs2,
}));

export const PeopleTag = styled(Tag)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.colors.neutral[90],
    color: "initial",
    border: "none",
    boxShadow: "none",
    cursor: "default",
  },
}));

import {
  Menu,
  MenuOptionType,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

export const ActionsMenu = ({ options }: { options: MenuOptionType[] }) => {
  return (
    <Menu
      type="select"
      options={options}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 50, horizontal: -150 }}
    >
      <PillButton variant="default" size="small" icon="IconDotsVertical" />
    </Menu>
  );
};

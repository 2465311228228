import { useNavigate } from "react-router-dom";
import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";

import {
  HeaderContainer,
  StyledBackButton,
  StyledBackButtonText,
  StyledHelperButtonText,
  StyledLeftArrowIcon,
  StyledTitle,
} from "./styled";

const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <StyledBackButton>
        <StyledLeftArrowIcon name="IconChevronLeft" size={20} />
        <StyledBackButtonText
          onClick={() => navigate(routes.pageCustomFields)}
          variant="body4"
          weight={700}
        >
          Voltar para Campos padrão e customizados
        </StyledBackButtonText>
      </StyledBackButton>
      <StyledTitle variant="headline9">Editar campo customizado</StyledTitle>
      <Button
        variant="secondary"
        variantType="neutral"
        size="small"
        onClick={() =>
          window.open("https://beneficios.flashapp.com.br/faq", "_blank")
        }
      >
        <Icons name="IconHelp" color="#6B5B66" size={16} />
        <StyledHelperButtonText variant="body4" weight={700}>
          Central de ajuda
        </StyledHelperButtonText>
      </Button>
    </HeaderContainer>
  );
};

export { Header };

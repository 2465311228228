import { IconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Icons";

export enum FieldComponentEnum {
  shortText = "Texto curto",
  longText = "Texto longo",
  number = "Número",
  link = "Link",
  attachment = "Anexo",
  datePicker = "Data",
  timePicker = "Horário",
  checkbox = "Checkbox",
  select = "Seleção única em lista",
  multiSelect = "Seleção múltipla em lista",
}

export const FieldComponentIcons: Record<string, IconTypes> = {
  shortText: "IconCursorText",
  longText: "IconCursorText",
  number: "IconSquareNumber1",
  link: "IconLink",
  attachment: "IconPaperclip",
  datePicker: "IconCalendar",
  timePicker: "IconClockHour2",
  checkbox: "IconSquareCheck",
  select: "IconList",
  multiSelect: "IconListCheck",
};

export const FieldComponentNames = Object.values(FieldComponentEnum);

export const fieldComponentKeyByValue = (value: any) =>
  Object.keys(FieldComponentEnum)[
    Object.values(FieldComponentEnum).indexOf(value)
  ];

export type FieldComponentTypes = keyof typeof FieldComponentEnum;

export type FieldTypeTypes =
  | "text"
  | "number"
  | "date"
  | "boolean"
  | "file"
  | "array";

export interface Option {
  label: string;
  value: any;
}

export type CustomFieldTypes =
  | "SHORT_TEXT"
  | "LONG_TEXT"
  | "NUMBER"
  | "DATE"
  | "BOOLEAN"
  | "ENUM"
  | "MULTI_ENUM";

export const typeIconMapper = (type: CustomFieldTypes): IconsProps["name"] => {
  const mapper = {
    SHORT_TEXT: FieldComponentIcons.shortText,
    LONG_TEXT: FieldComponentIcons.longText,
    NUMBER: FieldComponentIcons.number,
    DATE: FieldComponentIcons.datePicker,
    BOOLEAN: FieldComponentIcons.checkbox,
    ENUM: FieldComponentIcons.select,
    MULTI_ENUM: FieldComponentIcons.multiSelect,
  };
  return mapper[type];
};

export const typeDescriptionMapper = (type: CustomFieldTypes): string => {
  const mapper = {
    SHORT_TEXT: "Texto curto",
    LONG_TEXT: "Texto longo",
    NUMBER: "Número",
    DATE: "Data",
    BOOLEAN: "Checkbox",
    ENUM: "Seleção única em lista",
    MULTI_ENUM: "Seleção múltipla em lista",
  };
  return mapper[type];
};

export const typeComponentMapper = (type: CustomFieldTypes): string => {
  const mapper = {
    SHORT_TEXT: "shortText",
    LONG_TEXT: "longText",
    NUMBER: "number",
    DATE: "datePicker",
    BOOLEAN: "checkbox",
    ENUM: "select",
    MULTI_ENUM: "multiSelect",
  };
  return mapper[type];
};

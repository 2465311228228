import { useMemo } from "react";
import {
  Icons,
  IconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import * as SC from "./styled";

type Props = {
  currentStep: number;
  steps?: { title: string; caption?: string }[];
};

export const Stepper = ({ steps = [], currentStep = 1 }: Props) => {
  const { colors } = useTheme();
  const currentIndex = useMemo(() => currentStep - 1, [currentStep]);

  const getIconData = (
    index: number,
  ): { color: string; name: IconsProps["name"] } => {
    if (index < currentIndex)
      return { color: colors.feedback.success[40], name: "IconCircleCheck" };
    if (index == currentIndex)
      return { color: colors.brand[50], name: "IconCircleDot" };

    return { color: colors.neutral[70], name: "IconCircleDotted" };
  };

  return (
    <SC.Container>
      {steps.map((step, index) => {
        const isActive = index === currentIndex;
        const lastItem = index < steps.length - 1;
        const icon = getIconData(index);

        return (
          <SC.ContainerItem isActive={isActive && Boolean(step.caption)}>
            <SC.ContainerIndicator>
              <div>
                <Icons name={icon.name} size={16} color={icon.color} />
              </div>

              {lastItem && <SC.Line />}
            </SC.ContainerIndicator>
            <SC.ContainerText isActive={isActive}>
              <div>
                <SC.Title
                  variant="body4"
                  weight={isActive ? 700 : 400}
                  color={isActive ? "brand.50" : "neutral.50"}
                >
                  {step.title}
                </SC.Title>

                {Boolean(step.caption) && isActive && (
                  <Typography variant="caption" weight={400} color="neutral.30">
                    {step.caption}
                  </Typography>
                )}
              </div>
            </SC.ContainerText>
          </SC.ContainerItem>
        );
      })}
    </SC.Container>
  );
};

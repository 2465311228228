import { OptionProps } from "./components/item";
import { Popover } from "./components/popover";
import { SelectInput } from "./components/select-input";
import {
  SelectProps,
  SingleSelectDropdown,
} from "./components/single-select-dropdown";

export type SelectFieldProps = {
  label: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  options: Omit<OptionProps, "name">[];
  fullWidth?: boolean;
  value?: any;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void | Promise<void> | undefined;
} & Omit<SelectProps, "name">;

export const CustomSelectField = ({
  label,
  id,
  required = false,
  disabled = false,
  options,
  fullWidth = true,
  error,
  helperText,
  onChange,
  value,
  ...rest
}: SelectFieldProps) => {
  return (
    <SelectInput
      {...rest}
      id={id}
      required={required}
      placeholder={label}
      disabled={disabled}
      errorMessage={helperText}
      error={error}
      Popover={Popover}
      fullWidth={fullWidth}
      onChange={onChange}
      value={value}
      Dropdown={
        <SingleSelectDropdown
          {...rest}
          onChange={onChange}
          searchable
          searchNoResultsMessage="Nenhum resultado encontrado"
          options={options}
          name={id}
        />
      }
    />
  );
};

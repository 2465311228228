import { ReactNode } from "react";

import * as SC from "./styled";

type Props = {
  open: boolean;
  children: ReactNode | ReactNode[];
  onClose: () => void;
  onApply: () => void;
  onClear: () => void;
};

export const DrawerBaseFilters = ({
  open = false,
  children,
  onClose,
  onApply,
  onClear,
}: Props) => {
  return (
    <SC.Drawer open={open} title="Filtros" onClose={onClose}>
      <SC.DrawerContent>{children}</SC.DrawerContent>
      <SC.DrawerFooter>
        <SC.ClearButton variant="default" size="large" onClick={onClear}>
          Limpar filtros
        </SC.ClearButton>
        <SC.FilterButton size="large" variant="primary" onClick={onApply}>
          Filtrar
        </SC.FilterButton>
      </SC.DrawerFooter>
    </SC.Drawer>
  );
};

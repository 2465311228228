import { Icons, OptionsTagFilter } from "@flash-tecnologia/hros-web-ui-v2";

import { EmptyPreviewSVG } from "@/assets";
import {
  FieldComponentEnum,
  FieldComponentIcons,
  FieldComponentTypes,
  Option,
} from "@/common/field";
import { CustomSelectField } from "@/components/CustomSelectField";
import { FieldComponent } from "@/components/FieldComponent";
import { CustomFieldProps } from "@/hooks/CustomFields/useCreateCustomField";
import { dispatchToast } from "@/utils";

import { AdditionalInput } from "../../../PageCreateCustomField/components/CreateCustomFieldForm/components/AdditionalInput";
import { ManageCategoryModal } from "../../../PageCreateCustomField/components/CreateCustomFieldForm/components/ManageCategoryModal";

import {
  AboutContainer,
  AdditionalInputContainer,
  CaptionContainer,
  ConfigContainer,
  IconContainer,
  PreviewBox,
  PreviewContainer,
  SelectInput,
  StyledCaption,
  StyledSubtitle,
  StyledTitle,
  TextInput,
} from "./styled";

const MAX_DESCRIPTION_LENGTH = 100;
const MAX_NAME_LENGTH = 50;
const FieldTypeOptions: OptionsTagFilter[] = Object.entries(
  FieldComponentEnum,
).reduce((options, [k, v]) => {
  if (![FieldComponentEnum.link, FieldComponentEnum.timePicker].includes(v)) {
    options.push({
      label: v,
      value: k,
      icon: (
        <IconContainer>
          <Icons
            name={FieldComponentIcons[k.toString()]}
            size={16}
            strokeWidth="1"
          />
        </IconContainer>
      ),
    });
  }
  return options;
}, [] as any);

export type UpdateCustomFieldBody = Omit<CustomFieldProps, "enumOptions"> & {
  enumOptions?: { label: string; id?: string }[];
};

export type CreateCustomFieldFormProps = {
  categoryOptions: { label: string; value: string }[];
  customFieldProps: UpdateCustomFieldBody;
  setCustomFieldProps: (customField: UpdateCustomFieldBody) => void;
  saveCategoriesChanges: (categories: Option[]) => void;
  saveButtonLoading?: boolean;
  showManageCategoriesModal: boolean;
  setShowManageCategoriesModal: (show: boolean) => void;
};

export const UpdateCustomFieldForm = ({
  categoryOptions,
  customFieldProps,
  setCustomFieldProps,
  saveCategoriesChanges,
  saveButtonLoading,
  showManageCategoriesModal,
  setShowManageCategoriesModal,
}: CreateCustomFieldFormProps) => {
  const setValue = (
    value: any,
    component: FieldComponentTypes,
    index?: number,
  ) => {
    const isSelectInput = ["select", "multiSelect"].includes(component);
    if (isSelectInput) {
      const newEnumOptions = [...(customFieldProps?.enumOptions || [])];
      newEnumOptions[index!] = { ...newEnumOptions[index!], label: value };
      setCustomFieldProps({ ...customFieldProps, enumOptions: newEnumOptions });
    }
  };

  const handleFormatSelect = (opt: {
    label: string;
    value: FieldComponentTypes;
  }) => {
    const customField: UpdateCustomFieldBody = {
      name: undefined,
      component: opt.value,
      enumOptions: undefined,
    };
    if (["select", "multiSelect"].includes(opt.value)) {
      customField.enumOptions = [{ label: "" }, { label: "" }];
    }
    setCustomFieldProps(customField);
  };

  const addEnumOption = () => {
    const options = [...(customFieldProps?.enumOptions || [])];
    options.push({ label: "" });
    setCustomFieldProps({ enumOptions: options });
  };

  const removeEnumOption = (index: number) => {
    const options = [...(customFieldProps?.enumOptions || [])];
    options.splice(index, 1);

    if (options.length < 2) {
      dispatchToast({
        type: "error",
        content: "Quantidade de opções não pode ser inferior a 2.",
      });
      return;
    }
    setCustomFieldProps({ enumOptions: options });
  };

  return (
    <>
      <AboutContainer>
        <StyledTitle variant="headline8">Sobre o campo</StyledTitle>
        <StyledSubtitle variant="body4">
          Identifique internamente a finalidade do campo para garantir seu uso
          correto e facilitar a gestão.
        </StyledSubtitle>
        <TextInput
          label="Descrição interna"
          onChange={(e) => setCustomFieldProps({ description: e.target.value })}
          inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
          value={customFieldProps?.description || ""}
        />
        <CaptionContainer textPosition="END">
          <StyledCaption variant="caption">
            {`${
              customFieldProps?.description?.length || 0
            }/${MAX_DESCRIPTION_LENGTH}`}
          </StyledCaption>
        </CaptionContainer>
        <CustomSelectField
          label="Categoria"
          id={`$category_`}
          options={categoryOptions}
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            setCustomFieldProps({ categoryId: target.value });
          }}
          value={
            categoryOptions.find(
              (co) => customFieldProps?.categoryId === co.value,
            )?.label
          }
          extraLinksActions={[
            {
              label: "Gerenciar categorias",
              centralize: true,
              variant: "default",
              onClick: () =>
                setShowManageCategoriesModal(!showManageCategoriesModal),
            },
          ]}
        />
        <CaptionContainer textPosition="START">
          <StyledCaption variant="caption">
            Selecione uma categoria para identificar e organizar campos de mesmo
            tipo.
          </StyledCaption>
        </CaptionContainer>
      </AboutContainer>
      <ConfigContainer>
        <StyledTitle variant="headline8">Configurações do campo</StyledTitle>
        <StyledSubtitle variant="body4">
          Defina como o campo customizado irá aparecer nos formulários de
          cadastro.
        </StyledSubtitle>
        <SelectInput
          options={FieldTypeOptions}
          searchable
          fullWidth
          label="Formato do campo"
          onSelectChange={(_, opt) => handleFormatSelect(opt)}
          required
          disabled
          value={
            FieldTypeOptions.find(
              (o) => customFieldProps.component === o.value,
            ) || ""
          }
        />
        <TextInput
          label="Título do campo"
          required
          onChange={(e) => setCustomFieldProps({ name: e.target.value })}
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
          value={customFieldProps?.name || ""}
        />
        <CaptionContainer textPosition="END">
          <StyledCaption variant="caption">
            {`${customFieldProps?.name?.length || 0}/${MAX_NAME_LENGTH}`}
          </StyledCaption>
        </CaptionContainer>
      </ConfigContainer>
      <AdditionalInputContainer>
        <AdditionalInput
          component={customFieldProps?.component as any}
          value={customFieldProps?.enumOptions?.map((o) => o.label)}
          setValue={setValue}
          options={{ addOption: addEnumOption, removeOption: removeEnumOption }}
        />
      </AdditionalInputContainer>
      <PreviewContainer>
        <StyledTitle variant="headline8">Pré-visualização</StyledTitle>
        <PreviewBox>
          {customFieldProps?.component ? (
            <FieldComponent
              options={
                customFieldProps?.enumOptions?.map((o) => ({
                  label: o,
                  value: o,
                })) || []
              }
              name={customFieldProps?.name || ""}
              component={customFieldProps?.component as any}
            />
          ) : (
            <>
              <EmptyPreviewSVG />
              <StyledCaption variant="body4">
                Selecione um formato de campo para visualizar como ele irá
                ficar.
              </StyledCaption>
            </>
          )}
        </PreviewBox>
      </PreviewContainer>
      <ManageCategoryModal
        open={showManageCategoriesModal}
        onClose={() => setShowManageCategoriesModal(false)}
        categoryOptions={categoryOptions}
        saveCategoriesChanges={saveCategoriesChanges}
        saveButtonLoading={saveButtonLoading}
      />
    </>
  );
};

import { useEffect, useState } from "react";

import { useGetDepartmentEmployees } from "@/hooks";
import { useDebouncedValue } from "@/utils/useDebouncedValue";

import { Container } from "./styled";
import { DepartmentsTableProvider } from "./table-context";
import { TableFilters } from "./table-filters";
import { TableGrid } from "./table-grid";
import { TableHeader } from "./table-header";
import { TablePagination } from "./table-pagination";
import { TableSearch } from "./table-search";

type Props = {
  pagination: boolean;
  departmentId: string;
  departmentName: string;
};

const SEARCH_INPUT_DELAY = 300;

export const Table = ({ departmentId, departmentName, pagination }: Props) => {
  const [search, setSearch] = useState<string>("");
  const searchQuery = useDebouncedValue(search, SEARCH_INPUT_DELAY);
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const {
    data: { employees = [], metadata } = {},
    refetch,
    isLoading,
  } = useGetDepartmentEmployees({
    departmentId,
    page: paginationState.pageNumber,
    pageSize: paginationState.pageSize,
    searchQuery: searchQuery,
  });

  useEffect(() => {
    handlePageChange({ pageNumber: 1 });
  }, [searchQuery]);

  useEffect(() => {
    if (departmentId) {
      refetch();
    }
  }, [
    searchQuery,
    paginationState.pageNumber,
    paginationState.pageSize,
    departmentId,
  ]);

  const handlePageChange = ({
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }) => {
    setPaginationState((prevState) => ({
      pageNumber: pageNumber || prevState.pageNumber,
      pageSize: pageSize || prevState.pageSize,
    }));
  };

  return (
    <Container>
      <DepartmentsTableProvider>
        <TableHeader
          totalCount={metadata?.totalCount ? metadata.totalCount : 0}
        />
        <TableSearch
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <TableFilters
          departmentId={departmentId}
          departmentName={departmentName}
          disabled={!departmentId}
        />
        <TableGrid
          loading={isLoading}
          data={employees as any}
          pageSize={paginationState.pageSize}
          departmentId={departmentId}
        />

        {!!pagination && (
          <TablePagination
            totalPages={metadata?.totalPages || 0}
            pageNumber={paginationState.pageNumber}
            pageSize={paginationState.pageSize}
            onPageNumberChange={(pageNumber) =>
              handlePageChange({ pageNumber })
            }
            onPageSizeChange={(pageSize) => handlePageChange({ pageSize })}
          />
        )}
      </DepartmentsTableProvider>
    </Container>
  );
};

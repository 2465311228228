import { PropsWithChildren, ReactNode } from "react";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

import { Box } from "@/components";

export const MainWrapper = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 24px;
  overflow: hidden scroll;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 24px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

type FormTemplateProps = {
  title: string;
  subtitle: string;
  subcontent: string;
  header?: ReactNode;
  footer?: ReactNode;
};

export const FormTemplate = ({
  title,
  subtitle,
  subcontent,
  children,
  header,
  footer,
}: PropsWithChildren<FormTemplateProps>) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {header}
      <MainWrapper>
        <Box my="40px" display="flex" flexDirection="column">
          <Typography color="neutral.20" variant="headline6">
            {title}
          </Typography>
          <ContentWrapper>
            <Box minWidth={312} width={312}>
              <Typography color="secondary.50" variant="headline7">
                {subtitle}
              </Typography>

              <Typography color="neutral.50" variant="body3">
                {subcontent}
              </Typography>
            </Box>
            {children}
          </ContentWrapper>
        </Box>
      </MainWrapper>

      {footer}
    </Box>
  );
};

import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.xs,
  maxWidth: "560px",
  height: "100%",
  minHeight: theme.spacings.xs5,
  padding: `${theme.spacings.s} ${theme.spacings.xs} ${theme.spacings.xs} ${theme.spacings.xs}`,
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));

export const Header = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacings.xs4,
  with: "100%",
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacings.xs2,
  with: "100%",
}));

export const Body = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacings.xs3,
  with: "100%",
}));

export const Footer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  gap: theme.spacings.xs4,
  with: "100%",
  borderRadius: theme.borders.radius.m,
  padding: `${theme.spacings.xs3} ${theme.spacings.xs2}`,
  backgroundColor: `${theme.colors.secondary[99]}`,
}));

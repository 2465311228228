import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { routes } from "src/routes";

import { typeComponentMapper } from "@/common/field";
import {
  useCreateCustomField,
  useDeleteCustomField,
  useListCategories,
  useListCustomFields,
} from "@/hooks";
import { dispatchToast } from "@/utils";

import { Header } from "./components/Header";
import { Table } from "./components/SoftTable";
import { StyledBreadcrumbs } from "./styled";

export const PageCustomFields = () => {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const { data: categories, isLoading: listCategoriesIsLoading } =
    useListCategories(selectedCompany.id);
  const { data: customFields, isLoading: listCustomFieldsIsLoading } =
    useListCustomFields(selectedCompany.id);
  const { deleteManyCustomFields, deleteManyIsLoading } =
    useDeleteCustomField();
  const { createCustomField } = useCreateCustomField({
    onError: () => {
      dispatchToast({
        description: "Por favor, tente novamente mais tarde.",
        content: "Erro ao duplicar campo customizado",
        type: "error",
      });
    },
    onSuccess: () => {
      dispatchToast({
        content: "Campo customizado duplicado com sucesso!",
        type: "success",
      });
    },
  });
  const [selectedCustomFields, setSelectedCustomFields] = useState<string[]>(
    [],
  );
  useEffect(() => console.log(selectedCustomFields), [selectedCustomFields]);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10 });

  const onBulkActionHandler = () => {
    deleteManyCustomFields(selectedCustomFields);
  };

  const duplicateCustomField = (id: string) => {
    const original = customFields?.find((cf) => cf.id === id);
    const copyPayload = {
      categoryId: original?.categoryId,
      companyId: selectedCompany.id,
      description: original?.description,
      enumOptions: original?.enumOptions?.map((o) => o.label),
      name: `${original?.name!} - Cópia`,
      component: typeComponentMapper(original?.type as any) as any,
    };
    createCustomField(copyPayload);
  };

  return (
    <PageContainer>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="neutral">
            Campos padrão e customizados
          </LinkButton>,
        ]}
      />
      <Header />
      <Table
        data={customFields as any}
        pagination={pagination}
        onPagination={(e) => setPagination(e)}
        categories={categories}
        setSelectedCustomFields={(value: string[]) =>
          setSelectedCustomFields(value)
        }
        deleteManyIsLoading={deleteManyIsLoading}
        onBulkActionHandler={onBulkActionHandler}
        isLoading={
          listCategoriesIsLoading ||
          listCustomFieldsIsLoading ||
          deleteManyIsLoading
        }
        duplicateCustomField={duplicateCustomField}
      />
    </PageContainer>
  );
};

import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: theme.spacings.xs5,
  gap: theme.spacings.xs2,
}));

export const TitleContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  width: "fit-content",
  height: "fit-content",
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacings.xs,
  justifyContent: "space-between",
  width: "100%",
  padding: `${theme.spacings.xs3} 0`,
}));

export const LinkButtonContainer = styled.div<{ isCurrentPage: boolean }>(
  ({ isCurrentPage }) => ({
    ".MuiButtonBase-root": {
      lineHeight: "26px",
    },

    ...(isCurrentPage && {
      pointerEvents: "none",
    }),
  }),
);

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  gap: 24px;
  height: 56px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 40px;
  position: relative;
`;

export const StyledLeftArrowIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledBackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 0px;
`;

export const StyledBackButtonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledHelperButtonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

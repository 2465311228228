import {
  Button,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTextInput = styled(TextField)`
  width: 95%;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-bottom: 16px;
`;

export const BorderlessButton = styled(Button)`
  border-width: 0px !important;
  padding: 0px !important;
  margin-top: 15px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[100]} !important;
  }
`;

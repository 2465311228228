import { useNavigate } from "react-router-dom";
import {
  Button,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@/routes";

import * as SC from "./styled";

export const HeaderRegisterCostCenter = () => {
  const navigate = useNavigate();

  return (
    <SC.Container>
      <div>
        <LinkButton
          variant="neutral"
          onClick={() => navigate(routes.pageCostCenters)}
        >
          <Icons name="IconArrowLeft" size={16} />
          Voltar para Centro de custo
        </LinkButton>
      </div>
      <Typography variant="headline9">
        Centro de custo via plataforma
      </Typography>
      <div>
        <Button
          variant="secondary"
          variantType="neutral"
          size="medium"
          onClick={() => window.open("https://faq.flashapp.com.br/", "_blank")}
        >
          <Icons name="IconHelp" size={16} />
          Central de ajuda
        </Button>
      </div>
    </SC.Container>
  );
};

import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const BorderlessButton = styled(Button)`
  border-width: 0px !important;
  padding: 0px !important;
  margin-top: 15px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[100]} !important;
  }
`;

export const ContentContainer = styled.div`
  height: auto;
  margin: 0px;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: fill-available;
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-top: 16px;
`;

import { ReactNode } from "react";

import * as SC from "./styled";

type Props = {
  children: ReactNode;
  fullWidth?: boolean;
};

export const ContainerPage = ({ children }: Props) => {
  return <SC.Container fullWidth>{children}</SC.Container>;
};
